/*__________________________________________________________________;
[Colors]
Project:	protype
___________________________________________________________________*/;
$red_light_5:#fff1f1;
$red_light_4:#fdc5c3;
$red_light_3:#fb9f9b;
$red_light_2:#fa7d77;
$red_light_1:#f95851;
$red:#f83f37;
$red_dark_1:#ff2f26;
$red_dark_2:#ed1622;
$red_dark_3:#cf020d;
$red_dark_4:#b10911;
$red_dark_5:#8b0c12;
$green_light_5:#eff9f2;
$green_light_4:#bce7c7;
$green_light_3:#90d7a3;
$green_light_2:#69c982;
$green_light_1:#3fb95f;
$green:#22af47;
$green_dark_1:#1aa23e;
$green_dark_2:#179237;
$green_dark_3:#138431;
$green_dark_4:#056b33;
$green_dark_5:#05592b;
$pink_light_5:#feeff4;
$pink_light_4:#fabacf;
$pink_light_3:#f68daf;
$pink_light_2:#f36493;
$pink_light_1:#ef3975;
$pink:#ed1b60;
$pink_dark_1:#df195f;
$pink_dark_2:#be1b54;
$pink_dark_3:#a7194b;
$pink_dark_4:#90143f;
$pink_dark_5:#6f1333	;
$purple_light_5:#f9f0f9;
$purple_light_4:#e6bde5;
$purple_light_3:#d592d4;
$purple_light_2:#c66cc5;
$purple_light_1:#b642b5;
$purple:#ab26aa;
$purple_dark_1:#9a3092;
$purple_dark_2:#882787;
$purple_dark_3:#7c2181;
$purple_dark_4:#701b76;
$purple_dark_5:#5d1864;
$violet_light_5:#f4f2fa;
$violet_light_4:#d1c5e8;
$violet_light_3:#b29fd8;
$violet_light_2:#977dcb;
$violet_light_1:#7a59bc;
$violet:#6640b2;
$violet_dark_1:#603bab;
$violet_dark_2:#5935a3;
$violet_dark_3:#482e7e;
$violet_dark_4:#3a196c;
$violet_dark_5:#2d1453;
$indigo_light_5:#f1f3f9;
$indigo_light_4:#c4cce7;
$indigo_light_3:#9caad8;
$indigo_light_2:#798cca;
$indigo_light_1:#536bbb;
$indigo:#3a55b1;
$indigo_dark_1:#3650ab;
$indigo_dark_2:#2f4595;
$indigo_dark_3:#273c87;
$indigo_dark_4:#002663;
$indigo_dark_5:#0a2042;
$blue_light_5:#edf7fe;
$blue_light_4:#b2defa;
$blue_light_3:#7fc8f6;
$blue_light_2:#52b5f3;
$blue_light_1:#21a0f0;
$blue:#0092ee;
$blue_dark_1:#0089e0;
$blue_dark_2:#007fd0;
$blue_dark_3:#0768a5;
$blue_dark_4:#004f82;
$blue_dark_5:#044067;
$sky_light_5:#edf9fe;
$sky_light_4:#b2e6fa;
$sky_light_3:#7fd5f7;
$sky_light_2:#52c7f5;
$sky_light_1:#21b7f2;
$sky:#00acf0;
$sky_dark_1:#00a0df;
$sky_dark_2:#0293c9;
$sky_dark_3:#0285b4;
$sky_dark_4:#02729a;
$sky_dark_5:#075875;
$cyan_light_5:#effafb;
$cyan_light_4:#bbebf0;
$cyan_light_3:#8edde6;
$cyan_light_2:#66d2dd;
$cyan_light_1:#3bc5d3;
$cyan:#1ebccd;
$cyan_dark_1:#16a9b9;
$cyan_dark_2:#0e949f;
$cyan_dark_3:#0b858f;
$cyan_dark_4:#046b73;
$cyan_dark_5:#065459;
$teal_light_5:#edf8f6;
$teal_light_4:#b2e1da;
$teal_light_3:#7fcdc1;
$teal_light_2:#52bbac;
$teal_light_1:#21a894;
$teal:#009b84;
$teal_dark_1:#018975;
$teal_dark_2:#028068;
$teal_dark_3:#026f58;
$teal_dark_4:#02624f;
$teal_dark_5:#044b3b;
$green_light_5:#eff9f2;
$green_light_4:#bce7c7;
$green_light_3:#90d7a3;
$green_light_2:#69c982;
$green_light_1:#3fb95f;
$green:#22af47;
$green_dark_1:#1aa23e;
$green_dark_2:#179237;
$green_dark_3:#138431;
$green_dark_4:#056b33;
$green_dark_5:#05592b;
$neon_light_5:#f3f9ec;
$neon_light_4:#dbedc6;
$neon_light_3:#c3e0a0;
$neon_light_2:#aed67e;
$neon_light_1:#97ca5a;
$neon:#88c241;
$neon_dark_1:#79b62f;
$neon_dark_2:#67a41c;
$neon_dark_3:#59970e;
$neon_dark_4:#4c7f16;
$neon_dark_5:#3d680f;
$lime_light_5:#f9faeb;
$lime_light_4:#f1f4d0;
$lime_light_3:#e7ecb0;
$lime_light_2:#dfe594;
$lime_light_1:#d6de76;
$lime:#d0d962;
$lime_dark_1:#c3cc34;
$lime_dark_2:#b7c11a;
$lime_dark_3:#9ea809;
$lime_dark_4:#878b09;
$lime_dark_5:#6f7208;
$sun_light_5:#fffbe5;
$sun_light_4:#fef7c2;
$sun_light_3:#fef19a;
$sun_light_2:#feec76;
$sun_light_1:#fde74f;
$sun:#fde335;
$sun_dark_1:#ecd11e;
$sun_dark_2:#dfc414;
$sun_dark_3:#cdb308;
$sun_dark_4:#bca300;
$sun_dark_5:#a48f03;
$yellow_light_5:#fff8eb;
$yellow_light_4:#ffecc2;
$yellow_light_3:#ffdf9a;
$yellow_light_2:#ffd477;
$yellow_light_1:#ffc750;
$yellow:#ffbf36;
$yellow_dark_1:#ffb71d;
$yellow_dark_2:#ffac19;
$yellow_dark_3:#faa000;
$yellow_dark_4:#eb9000;
$yellow_dark_5:#cb7e03;
$orange_light_5:#fff4e9;
$orange_light_4:#ffdfbe;
$orange_light_3:#ffca93;
$orange_light_2:#ffb76d;
$orange_light_1:#ffa344;
$orange:#ff9528;
$orange_dark_1:#ff880e;
$orange_dark_2:#ff7800;
$orange_dark_3:#f16611;
$orange_dark_4:#e05602;
$orange_dark_5:#c74c00;
$pumpkin_light_5:#fff4f0;
$pumpkin_light_4:#ffcfbe;
$pumpkin_light_3:#ffaf93;
$pumpkin_light_2:#ff936d;
$pumpkin_light_1:#ff7544;
$pumpkin:#ff6028;
$pumpkin_dark_1:#fb551c;
$pumpkin_dark_2:#e8470f;
$pumpkin_dark_3:#dd3f08;
$pumpkin_dark_4:#c63300;
$pumpkin_dark_5:#ac2c00;
$brown_light_5:#f6f3f2;
$brown_light_4:#d7cbc8;
$brown_light_3:#bca9a4;
$brown_light_2:#a58b84;
$brown_light_1:#8b6a61;
$brown:#7a5449;
$brown_dark_1:#6f483d;
$brown_dark_2:#633d32;
$brown_dark_3:#502f25;
$brown_dark_4:#44241b;
$brown_dark_5:#301811;
$gold_light_5:#fbf8f1;
$gold_light_4:#ece0c5;
$gold_light_3:#e0cc9f;
$gold_light_2:#d5ba7d;
$gold_light_1:#c9a658;
$gold:#c1993f;
$gold_dark_1:#b48c32;
$gold_dark_2:#a27b25;
$gold_dark_3:#98731e;
$gold_dark_4:#85600d;
$gold_dark_5:#6d4d05;
$grey_light_5:#f5f5f5;
$grey_light_4:#e2e2e2;
$grey_light_3:#cecece;
$grey_light_2:#bdbdbd;
$grey_light_1:#ababab;
$grey:#9e9e9e;
$grey_dark_1:#919191;
$grey_dark_2:#858484;
$grey_dark_3:#777474;
$grey_dark_4:#666161;
$grey_dark_5:#524c4c;
$smoke_light_5:#f4f6f7;
$smoke_light_4:#ced8dc;
$smoke_light_3:#aebec4;
$smoke_light_2:#92a7b0;
$smoke_light_1:#738e99;
$smoke:#5e7d8a;
$smoke_dark_1:#4a6b78;
$smoke_dark_2:#40606d;
$smoke_dark_3:#304e5a;
$smoke_dark_4:#233c46;
$smoke_dark_5:#172a32;
$light:#f5f5f6;
$light_10:#eaecec;
$light_15:#e0e3e4;
$light_20:#d6d9da;
$light_30:#c1c6c8;
$light_40:#adb3b6;
$light_50:#98a0a3;
$light_60:#848d91;
$light_70:#6f7a7f;
$light_80:#5b676d;
$light_90:#46545a;
$dark:#324148;
$dark_20:#2b383e;
$dark_40:#273238;
$dark_60:#212a2f;
$dark_80:#1a2225;
$dark_100:#0d1113;
$white:#fff;
$twitter_blue:#00aced;
