/*------------------------------------------------------------------
[Navbar]
Project:	protype
-------------------------------------------------------------------*/

/*Nav*/
.nav {
  .nav-link {
    &.link-icon-left {
      i,
      span {
        padding-right: 10px;
        font-size: 18px;
      }
    }
    &.link-icon-top {
      i,
      span {
        display: block;
        text-align: center;
        font-size: 21px;
      }
    }
  }
  &.nav-tabs {
    border-bottom: none;
    .nav-link {
      border-top: none;
      border-left: none;
      border-right: none;
      border-radius: 0;
      border-bottom: 3px solid transparent;
      @include transition(0.2s ease-in-out);
      &.active {
        color: $dark;
        background: transparent;
        border-color: $sky;
        &:hover,
        &:focus {
          border-color: $sky;
        }
      }
      &:hover,
      &:focus {
        color: $dark;
        border-color: transparent;
      }
      &.disabled {
        color: $light_30;
      }
    }
    .nav-item.show .nav-link {
      color: $dark;
      border-color: $sky;
      background: transparent;
    }
    .dropdown-menu {
      margin-top: 1px;
    }
    &.nav-tabs-sky,
    &.nav-tabs-primary {
      .nav-link {
        &.active {
          border-color: $sky;
          &:hover,
          &:focus {
            border-color: $sky;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $sky;
      }
    }
    &.nav-tabs-cyan,
    &.nav-tabs-info {
      .nav-link {
        &.active {
          border-color: $cyan;
          &:hover,
          &:focus {
            border-color: $cyan;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $cyan;
      }
    }
    &.nav-tabs-green,
    &.nav-tabs-success {
      .nav-link {
        &.active {
          border-color: $green;
          &:hover,
          &:focus {
            border-color: $green;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $green;
      }
    }
    &.nav-tabs-yellow,
    &.nav-tabs-warning {
      .nav-link {
        &.active {
          border-color: $yellow;
          &:hover,
          &:focus {
            border-color: $yellow;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $yellow;
      }
    }
    &.nav-tabs-red,
    &.nav-tabs-danger {
      .nav-link {
        &.active {
          border-color: $red;
          &:hover,
          &:focus {
            border-color: $red;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $red;
      }
    }
    &.nav-tabs-blue {
      .nav-link {
        &.active {
          border-color: $blue;
          &:hover,
          &:focus {
            border-color: $blue;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $blue;
      }
    }
    &.nav-tabs-pink {
      .nav-link {
        &.active {
          border-color: $pink;
          &:hover,
          &:focus {
            border-color: $pink;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $pink;
      }
    }
    &.nav-tabs-purple {
      .nav-link {
        &.active {
          border-color: $purple;
          &:hover,
          &:focus {
            border-color: $purple;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $purple;
      }
    }
    &.nav-tabs-violet {
      .nav-link {
        &.active {
          border-color: $violet;
          &:hover,
          &:focus {
            border-color: $violet;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $violet;
      }
    }
    &.nav-tabs-indigo {
      .nav-link {
        &.active {
          border-color: $indigo;
          &:hover,
          &:focus {
            border-color: $indigo;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $indigo;
      }
    }
    &.nav-tabs-blue {
      .nav-link {
        &.active {
          border-color: $blue;
          &:hover,
          &:focus {
            border-color: $blue;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $blue;
      }
    }
    &.nav-tabs-teal {
      .nav-link {
        &.active {
          border-color: $teal;
          &:hover,
          &:focus {
            border-color: $teal;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $teal;
      }
    }
    &.nav-tabs-neon {
      .nav-link {
        &.active {
          border-color: $neon;
          &:hover,
          &:focus {
            border-color: $neon;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $neon;
      }
    }
    &.nav-tabs-lime {
      .nav-link {
        &.active {
          border-color: $lime;
          &:hover,
          &:focus {
            border-color: $lime;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $lime;
      }
    }
    &.nav-tabs-sun {
      .nav-link {
        &.active {
          border-color: $sun;
          &:hover,
          &:focus {
            border-color: $sun;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $sun;
      }
    }
    &.nav-tabs-orange {
      .nav-link {
        &.active {
          border-color: $orange;
          &:hover,
          &:focus {
            border-color: $orange;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $orange;
      }
    }
    &.nav-tabs-pumpkin {
      .nav-link {
        &.active {
          border-color: $pumpkin;
          &:hover,
          &:focus {
            border-color: $pumpkin;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $pumpkin;
      }
    }
    &.nav-tabs-brown {
      .nav-link {
        &.active {
          border-color: $brown;
          &:hover,
          &:focus {
            border-color: $brown;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $brown;
      }
    }
    &.nav-tabs-gold {
      .nav-link {
        &.active {
          border-color: $gold;
          &:hover,
          &:focus {
            border-color: $gold;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $gold;
      }
    }
    &.nav-tabs-smoke {
      .nav-link {
        &.active {
          border-color: $smoke;
          &:hover,
          &:focus {
            border-color: $smoke;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $smoke;
      }
    }
    &.nav-tabs-grey,
    &.nav-tabs-secondary {
      .nav-link {
        &.active {
          border-color: $grey;
          &:hover,
          &:focus {
            border-color: $grey;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $grey;
      }
    }
    &.nav-tabs-dark {
      .nav-link {
        &.active {
          border-color: $dark;
          &:hover,
          &:focus {
            border-color: $dark;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $dark;
      }
    }
    &.nav-tabs-light {
      .nav-link {
        &.active {
          border-color: $light_10;
          &:hover,
          &:focus {
            border-color: $light_10;
          }
        }
      }
      .nav-item.show .nav-link {
        border-color: $light_10;
      }
    }
  }
  &.nav-pills {
    .nav-link {
      @include transition(0.2s ease-in-out);
      &.active {
        color: $white;
        background-color: $sky;
        &:hover,
        &:focus {
          color: $white;
        }
      }
      &:hover,
      &:focus {
        color: $dark;
      }
      &.disabled {
        color: $light_30;
      }
    }
    .nav-item.show .nav-link {
      color: $white;
      background-color: $sky;
    }
    &.nav-pills-square {
      .nav-link {
        border-radius: 0;
      }
    }
    &.nav-pills-rounded {
      .nav-link {
        border-radius: 50px;
      }
    }
    &.nav-pills-sky,
    &.nav-pills-primary {
      .nav-link {
        &.active {
          background-color: $sky;
        }
      }
      .nav-item.show .nav-link {
        background-color: $sky;
      }
    }
    &.nav-pills-cyan,
    &.nav-pills-info {
      .nav-link {
        &.active {
          background-color: $cyan;
        }
      }
      .nav-item.show .nav-link {
        background-color: $cyan;
      }
    }
    &.nav-pills-green,
    &.nav-pills-success {
      .nav-link {
        &.active {
          background-color: $green;
        }
      }
      .nav-item.show .nav-link {
        background-color: $green;
      }
    }
    &.nav-pills-yellow,
    &.nav-pills-warning {
      .nav-link {
        &.active {
          background-color: $yellow;
        }
      }
      .nav-item.show .nav-link {
        background-color: $yellow;
      }
    }
    &.nav-pills-red,
    &.nav-pills-danger {
      .nav-link {
        &.active {
          background-color: $red;
        }
      }
      .nav-item.show .nav-link {
        background-color: $red;
      }
    }
    &.nav-pills-blue {
      .nav-link {
        &.active {
          background-color: $blue;
        }
      }
      .nav-item.show .nav-link {
        background-color: $blue;
      }
    }
    &.nav-pills-pink {
      .nav-link {
        &.active {
          background-color: $pink;
        }
      }
      .nav-item.show .nav-link {
        background-color: $pink;
      }
    }
    &.nav-pills-purple {
      .nav-link {
        &.active {
          background-color: $purple;
        }
      }
      .nav-item.show .nav-link {
        background-color: $purple;
      }
    }
    &.nav-pills-violet {
      .nav-link {
        &.active {
          background-color: $violet;
        }
      }
      .nav-item.show .nav-link {
        background-color: $violet;
      }
    }
    &.nav-pills-indigo {
      .nav-link {
        &.active {
          background-color: $indigo;
        }
      }
      .nav-item.show .nav-link {
        background-color: $indigo;
      }
    }
    &.nav-pills-blue {
      .nav-link {
        &.active {
          background-color: $blue;
        }
      }
      .nav-item.show .nav-link {
        background-color: $blue;
      }
    }
    &.nav-pills-teal {
      .nav-link {
        &.active {
          background-color: $teal;
        }
      }
      .nav-item.show .nav-link {
        background-color: $teal;
      }
    }
    &.nav-pills-neon {
      .nav-link {
        &.active {
          background-color: $neon;
        }
      }
      .nav-item.show .nav-link {
        background-color: $neon;
      }
    }
    &.nav-pills-lime {
      .nav-link {
        &.active {
          background-color: $lime;
        }
      }
      .nav-item.show .nav-link {
        background-color: $lime;
      }
    }
    &.nav-pills-sun {
      .nav-link {
        &.active {
          color: $dark;
          background-color: $sun;
        }
      }
      .nav-item.show .nav-link {
        color: $dark;
        background-color: $sun;
      }
    }
    &.nav-pills-orange {
      .nav-link {
        &.active {
          background-color: $orange;
        }
      }
      .nav-item.show .nav-link {
        background-color: $orange;
      }
    }
    &.nav-pills-pumpkin {
      .nav-link {
        &.active {
          background-color: $pumpkin;
        }
      }
      .nav-item.show .nav-link {
        background-color: $pumpkin;
      }
    }
    &.nav-pills-brown {
      .nav-link {
        &.active {
          background-color: $brown;
        }
      }
      .nav-item.show .nav-link {
        background-color: $brown;
      }
    }
    &.nav-pills-gold {
      .nav-link {
        &.active {
          background-color: $gold;
        }
      }
      .nav-item.show .nav-link {
        background-color: $gold;
      }
    }
    &.nav-pills-smoke {
      .nav-link {
        &.active {
          background-color: $smoke;
        }
      }
      .nav-item.show .nav-link {
        background-color: $smoke;
      }
    }
    &.nav-pills-grey,
    &.nav-pills-secondary {
      .nav-link {
        &.active {
          background-color: $grey;
        }
      }
      .nav-item.show .nav-link {
        background-color: $grey;
      }
    }
    &.nav-pills-dark {
      .nav-link {
        &.active {
          background-color: $dark;
        }
      }
      .nav-item.show .nav-link {
        background-color: $dark;
      }
    }
    &.nav-pills-light {
      .nav-link {
        &.active {
          color: $dark;
          background-color: $light_10;
        }
      }
      .nav-item.show .nav-link {
        color: $dark;
        background-color: $light_10;
      }
    }
  }
  &.nav-dark {
    .nav-link {
      @include color-opacity($white, 0.5);
      &.active {
        color: $white;
        &:hover,
        &:focus {
          color: $white;
        }
      }
      &:hover,
      &:focus {
        @include color-opacity($white, 0.7);
      }
      &.disabled {
        @include color-opacity($white, 0.3);
      }
    }
    .nav-item.show .nav-link {
      color: $white;
    }
    &.nav-tabs {
      .nav-link {
        &.active {
          color: $white;
          &:hover,
          &:focus {
            color: $white;
          }
        }
        &:hover,
        &:focus {
          @include color-opacity($white, 0.7);
        }
        &.disabled {
          @include color-opacity($white, 0.3);
        }
      }
      .nav-item.show .nav-link {
        color: $white;
      }
    }
    &.nav-pills {
      .nav-link {
        &.active {
          color: $white;
        }
        &.disabled {
          @include color-opacity($white, 0.3);
        }
      }
      .nav-item.show .nav-link {
        color: $white;
      }
    }
  }
  &.nav-light {
    .nav-link {
      @include color-opacity($dark, 0.5);
      &.active {
        color: $dark;
        &:hover,
        &:focus {
          color: $dark;
        }
      }
      &:hover,
      &:focus {
        @include color-opacity($dark, 0.7);
      }
      &.disabled {
        @include color-opacity($dark, 0.3);
      }
    }
    .nav-item.show .nav-link {
      color: $dark;
    }
    &.nav-tabs {
      .nav-link {
        &.active {
          color: $dark;
          &:hover,
          &:focus {
            color: $dark;
          }
        }
        &:hover,
        &:focus {
          @include color-opacity($dark, 0.7);
        }
        &.disabled {
          @include color-opacity($dark, 0.3);
        }
      }
      .nav-item.show .nav-link {
        color: $dark;
      }
    }
    &.nav-pills {
      .nav-link {
        &.active {
          color: $white;
        }
        &.disabled {
          @include color-opacity($dark, 0.3);
        }
      }
      .nav-item.show .nav-link {
        color: $white;
      }
    }
  }
  &.nav-dark,
  &.nav-light {
    &.nav-pills {
      &.nav-pills-sun,
      &.nav-pills-light {
        .nav-link {
          &.active {
            color: $dark;
          }
        }
        .nav-item.show .nav-link {
          color: $dark;
        }
      }
    }
  }
  &.nav-sm {
    .nav-link {
      padding: 0.25rem 0.75rem;
      font-size: 14px;
    }
  }
}
/*Navbar*/
.navbar-light {
  .navbar-nav {
    .nav-link {
      @include color-opacity($dark, 0.5);
      &:hover,
      &:focus {
        @include color-opacity($dark, 0.7);
      }
      &.active,
      &.show {
        color: $dark;
      }
      &.disabled {
        @include color-opacity($dark, 0.3);
      }
    }
    .active,
    .show {
      > .nav-link {
        color: $dark;
      }
    }
  }
}
@media (max-width: 1199px) {
  .navbar-demo {
    .input-group {
      width: auto;
      margin-bottom: 0.5rem;
    }
    .navbar-nav {
      .nav-link {
        padding-right: 1rem;
      }
      .dropdown-menu {
        position: absolute;
      }
    }
  }
}
.navbar-dark {
  .navbar-nav {
    .nav-link {
      @include color-opacity($white, 0.5);
      &:hover,
      &:focus {
        @include color-opacity($white, 0.7);
      }
      &.active,
      &.show {
        color: $white;
      }
      &.disabled {
        @include color-opacity($white, 0.3);
      }
    }
    .active,
    .show {
      > .nav-link {
        color: $white;
      }
    }
  }
}

/*Top Nav*/
.hk-wrapper {
  .hk-navbar {
    padding-top: 0;
    padding-bottom: 0;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.07);
    .feather-icon {
      display: block;
      > svg {
        width: 20px;
        height: 20px;
      }
    }
    i.ion {
      font-size: 22px;
      position: relative;
      top: 3px;
    }
    a {
      &.navbar-toggle-btn {
        margin-right: 18px;
      }
      &.navbar-brand {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 22px;
        font-weight: 500;
        text-transform: capitalize;
      }
      &.nav-link-hover {
        position: relative;
        text-align: center;
        &:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: -10px;
          right: -10px;
          top: 0;
          margin: auto;
          z-index: -1;
          height: 37px;
          width: 37px;
          opacity: 0;
          border-radius: 50%;
          @include transform(scale(1.3));
          @include transition(0.3s ease);
        }
        &:hover {
          &:after {
            opacity: 1;
            @include transform(scale(1));
          }
        }
      }
    }
    .navbar-nav {
      -ms-flex-direction: row;
      flex-direction: row;
      margin-left: auto;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      .nav-item {
        .nav-link {
          line-height: 57px;
          padding: 0 12px;
        }
        &:last-child {
          .nav-link {
            padding-right: 0;
          }
        }
        &.dropdown {
          &.dropdown-authentication {
            .nav-link {
              .media {
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                .media-img-wrap {
                  position: relative;
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  -webkit-box-align: center;
                  -ms-flex-align: center;
                  align-items: center;
                  margin-right: 10px;
                  .avatar {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    height: 35px;
                    width: 35px;
                  }
                  .badge-indicator {
                    bottom: 2px;
                    height: 7px;
                    right: 0;
                    position: absolute;
                    width: 7px;
                  }
                }
                .media-body {
                  display: -webkit-box;
                  display: -ms-flexbox;
                  display: flex;
                  > span {
                    font-size: 14px;
                    text-transform: capitalize;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    > i {
                      padding-left: 10px;
                    }
                  }
                }
              }
            }
          }
          &.dropdown-notifications {
            .nav-link {
              position: relative;
              .badge-wrap {
                position: absolute;
                top: 14px;
                right: 10px;
                line-height: 0;
                .badge {
                  position: relative;
                }
                .pulse {
                  animation: pulse 2s infinite;
                }
                @-webkit-keyframes pulse {
                  0% {
                    box-shadow: 0 0 0 0 rgba(0, 172, 240, 0.8);
                  }
                  70% {
                    box-shadow: 0 0 0 10px rgba(0, 172, 240, 0);
                  }
                  100% {
                    box-shadow: 0 0 0 0 rgba(0, 172, 240, 0);
                  }
                }
                @keyframes pulse {
                  0% {
                    box-shadow: 0 0 0 0 rgba(0, 172, 240, 0.8);
                  }
                  70% {
                    box-shadow: 0 0 0 10px rgba(0, 172, 240, 0);
                  }
                  100% {
                    box-shadow: 0 0 0 0 rgba(0, 172, 240, 0);
                  }
                }
              }
            }
            .dropdown-menu {
              min-width: 350px;
              padding-bottom: 0;
              .dropdown-divider {
                margin: 0;
              }
              .dropdown-header {
                border-bottom: 1px solid $light_10;
                padding-bottom: 1rem;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                > a {
                  margin-left: auto;
                  font-weight: normal;
                }
              }
              .dropdown-item {
                white-space: inherit;
                padding: 0.75rem 1.25rem;
                .media {
                  .media-img-wrap {
                    margin-right: 15px;
                  }
                  .media-body {
                    > div {
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      justify-content: space-between;
                      -webkit-justify-content: space-between;
                      -ms-flex-pack: space-between;
                      .notifications-text {
                        color: $smoke;
                        font-size: 14px;
                        max-width: 75%;
                        -ms-flex: 0 0 75%;
                        flex: 0 0 75%;
                      }
                      .notifications-time {
                        color: $smoke;
                        font-size: 12px;
                        margin-top: 3px;
                      }
                    }
                  }
                }
                &.read-notifications {
                  background: $light;
                }
              }
            }
          }
        }
      }
    }
    &.navbar-light {
      background: $white;
      .navbar-brand {
        color: $dark;
      }
      a {
        &.navbar-toggle-btn {
          color: $dark;
        }
        &.nav-link-hover {
          &:after {
            @include background-opacity($dark, 0.1);
          }
        }
      }
      .navbar-nav {
        &.hk-navbar-content {
          .nav-item {
            .nav-link {
              @include color-opacity($dark, 0.7);
              &:hover,
              &:focus {
                color: $dark;
              }
            }
            &.dropdown {
              &.dropdown-authentication {
                .nav-link .media .media-body > span {
                  > i {
                    @include color-opacity($dark, 0.4);
                  }
                }
              }
            }
          }
        }
      }
    }
    &.navbar-dark {
      background: $indigo_dark_5;
      a {
        &.navbar-toggle-btn {
          color: $white;
        }
        &.nav-link-hover {
          &:after {
            @include background-opacity($white, 0.1);
          }
        }
      }
      .navbar-nav {
        &.hk-navbar-content {
          .nav-item {
            .nav-link {
              @include color-opacity($white, 0.7);
              &:hover,
              &:focus {
                color: $white;
              }
            }
            &.dropdown {
              &.dropdown-authentication {
                .nav-link .media .media-body > span {
                  > i {
                    @include color-opacity($white, 0.4);
                  }
                }
              }
            }
          }
        }
      }
    }
    &.hk-navbar-alt {
      .navbar-nav {
        margin-left: 0;
      }
      .navbar-search-alt {
        margin-left: auto;
        .input-group {
          .input-group-prepend {
            border: none;
            position: absolute;
            left: 0;
            z-index: 1;
            height: 100%;
            pointer-events: none;
            .input-group-text {
              background: transparent;
              border: none;
              color: $white;
              i.ion {
                top: 1px;
              }
            }
          }
          > input {
            border-radius: 50px;
            border: none;
            padding-left: 30px;
            padding-left: 40px;
          }
        }
      }
      .navbar-toggler {
        padding: 0;
        font-size: inherit;
        border: none;
      }
      &.navbar-light {
        .navbar-search-alt {
          .input-group {
            .input-group-prepend {
              .input-group-text {
                @include color-opacity($dark, 0.7);
              }
            }
            > input {
              @include background-opacity($dark, 0.05);
              color: $dark;
              &::-webkit-input-placeholder {
                @include color-opacity($dark, 0.4);
              }
              &:-moz-placeholder {
                @include color-opacity($dark, 0.4);
              }
              &::-moz-placeholder {
                @include color-opacity($dark, 0.4);
              }
              &:-ms-input-placeholder {
                @include color-opacity($dark, 0.4);
              }
            }
          }
        }
      }
      &.navbar-dark {
        .navbar-search-alt {
          .input-group {
            .input-group-prepend {
              .input-group-text {
                @include color-opacity($white, 0.7);
              }
            }
            > input {
              @include background-opacity($white, 0.1);
              color: $white;
              &::-webkit-input-placeholder {
                @include color-opacity($white, 0.4);
              }
              &:-moz-placeholder {
                @include color-opacity($white, 0.4);
              }
              &::-moz-placeholder {
                @include color-opacity($white, 0.4);
              }
              &:-ms-input-placeholder {
                @include color-opacity($white, 0.4);
              }
            }
          }
        }
      }
    }
  }
  .navbar-search {
    position: absolute;
    top: 57px;
    left: 240px;
    right: 0px;
    z-index: 1;
    @include transform(translateY(-100%));
    @include transition(all 0.4s ease);
    input {
      height: 50px;
      padding-left: 44px;
      background: $sun_light_4;
      border: none;
      border-radius: 0;
      &:focus {
        background: $sun_light_4;
        border: none;
        box-shadow: none;
      }
      &:disabled {
        background-color: $sun_light_1;
      }
      &[readonly] {
        background-color: $sun_dark_1;
      }
    }
    .feather-icon {
      display: block;
      > svg {
        height: 18px;
        width: 18px;
      }
    }
    .navbar-search-icon {
      position: absolute;
      left: 15px;
      top: 50%;
      color: $light_50;
      font-size: 21px;
      @include transform(translateY(-50%));
    }
    .navbar-search-close {
      position: absolute;
      right: 15px;
      font-size: 26px;
      top: 50%;
      color: $dark;
      @include transform(translateY(-50%));
    }
  }
  &.hk-nav-toggle {
    .navbar-search {
      left: 51px;
    }
  }
  &.navbar-search-toggle {
    .navbar-search {
      @include transform(translateY(0));
    }
    .hk-pg-wrapper {
      padding-top: 107px;
    }
  }
  &.scrollable-nav {
    .hk-navbar {
      position: absolute;
    }
  }
}
@media (max-width: 1199px) {
  .hk-wrapper {
    a {
      &.nav-link-hover {
        position: relative;
        text-align: center;
        &:after {
          display: none;
        }
      }
    }
    .hk-navbar {
      a.navbar-toggle-btn {
        margin-right: 12px;
      }
      .navbar-brand {
        margin-right: 0;
      }
      .navbar-nav {
        .dropdown-menu {
          position: absolute;
        }
        .nav-item {
          .nav-link {
            padding-left: 7px;
            padding-right: 7px;
          }
          &.dropdown.dropdown-authentication {
            .nav-link .media {
              .media-img-wrap {
                margin-right: 0;
              }
              .media-body {
                display: none;
              }
            }
          }
        }
      }
      &.hk-navbar-alt {
        justify-content: flex-start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
        > .navbar-nav {
          margin-left: auto;
        }
        .navbar-collapse {
          position: absolute;
          width: 100%;
          top: 57px;
          left: 0;
          > .navbar-nav {
            flex-direction: column;
            align-items: flex-start;
            padding: 0 1rem;
            .nav-item {
              width: 100%;
              .nav-link {
                line-height: 46px;
                border-bottom: 1px solid;
              }
              .dropdown-menu {
                position: static;
                border: none;
                background: transparent;
                a.dropdown-item:not(.active) {
                  &:hover,
                  &:focus {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
        .navbar-search-alt {
          margin: 1.6rem 1rem;
        }
        &.navbar-light {
          .navbar-collapse {
            background: $light_10;
            > .navbar-nav {
              .nav-item {
                .nav-link {
                  @include border-opacity($dark, 0.1);
                }
                .dropdown-menu {
                  a.dropdown-item {
                    @include color-opacity($dark, 0.5);
                    @include border-opacity($dark, 0.3);
                    &:hover,
                    &:focus {
                      @include color-opacity($dark, 0.7);
                    }
                    &.active,
                    &.show {
                      color: $dark;
                    }
                  }
                }
              }
            }
          }
        }
        &.navbar-dark {
          .navbar-collapse {
            background: $dark_20;
            > .navbar-nav {
              .nav-item {
                .nav-link {
                  @include border-opacity($white, 0.1);
                }
                .dropdown-menu {
                  a.dropdown-item {
                    @include color-opacity($white, 0.5);
                    &:hover,
                    &:focus {
                      @include color-opacity($white, 0.7);
                    }
                    &.active,
                    &.show {
                      color: $white;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .navbar-search {
      left: 0px;
    }
    &.hk-nav-toggle {
      .navbar-search {
        left: 0;
      }
    }
  }
}
@media (max-width: 414px) {
  .hk-wrapper .hk-navbar .navbar-nav .nav-item {
    &.dropdown.dropdown-notifications {
      position: static;
      .dropdown-menu {
        left: 0;
        right: 0;
        min-width: auto;
      }
    }
    .nav-link {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
}

/*Vertical Navigation*/
.hk-wrapper.hk-vertical-nav {
  .hk-pg-wrapper {
    margin-left: 240px;
    left: 0;
    @include transition(all 0.4s ease);
  }
  .hk-nav {
    position: fixed;
    top: 57px;
    left: 0;
    width: 240px;
    margin-left: 0;
    bottom: 0;
    z-index: 100;
    border: none;
    box-shadow: 0 0 28px rgba(0, 0, 0, 0.07);
    @include transition(all 0.4s ease);
    .nav-header {
      padding: 0.5rem 1.15rem;
      width: 240px;
      font-size: 11px;
      text-transform: uppercase;
      font-weight: 700;
      > span:last-child {
        display: none;
      }
    }
    .nav-separator {
      margin: 15px 0;
    }
    .navbar-nav-wrap {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
    }
    .hk-nav-close {
      padding: 1rem;
      display: none;
      position: absolute;
      width: 100%;
      z-index: 1;
      background: inherit;
      .feather-icon {
        display: block;
        > svg {
          height: 20px;
          width: 20px;
        }
      }
    }
    .navbar-nav {
      li {
        width: 240px;
      }
      .nav-item {
        .nav-link-text {
          display: inline-block;
        }
        .nav-link {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 0.5rem 1rem;
          > i {
            font-size: 20px;
            margin-right: 7px;
            line-height: 25px;
            min-width: 25px;
          }
          > .feather-icon {
            min-width: 32px;
            line-height: 25px;
            > svg {
              height: 18px;
              width: 18px;
            }
          }
          &[data-toggle="collapse"] {
            &::after {
              content: "\f2f9";
              font: normal normal normal 14px/1 "Material-Design-Iconic-Font";
              font-size: 16px;
              text-rendering: auto;
              margin-left: auto;
              @include transform(rotate(0deg));
              @include transition(all 0.4s ease);
            }
            &[aria-expanded="true"] {
              &::after {
                @include transform(rotate(-180deg));
              }
            }
          }
          &.link-with-badge {
            &:after {
              display: none;
            }
            .badge {
              margin-left: auto;
            }
          }
        }
        ul.collapse-level-1 {
          .nav-item {
            .nav-link {
              padding: 0.35rem 1rem 0.35rem 3rem;
              font-size: 14px;
              &[data-toggle="collapse"] {
                &::after {
                  font-size: 14px;
                }
              }
            }
            ul.collapse-level-2 {
              .nav-item {
                .nav-link {
                  padding: 0.35rem 1rem 0.35rem 4rem;
                  &[data-toggle="collapse"] {
                    &::after {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    &.hk-nav-light {
      background: $white;
      .nav-header {
        @include color-opacity($dark, 0.4);
      }
      .nav-separator {
        @include border-opacity($dark, 0.05);
      }
      .navbar-nav {
        .nav-item {
          .nav-link {
            @include color-opacity($dark, 0.6);
            &:hover,
            &:focus {
              @include color-opacity($dark, 0.8);
            }
          }
          &.active {
            > .nav-link {
              color: $dark;
            }
          }
        }
      }
      .hk-nav-close {
        color: $dark;
      }
    }
    &.hk-nav-dark {
      background: $dark_20;
      .nav-header {
        @include color-opacity($white, 0.4);
      }
      .nav-separator {
        @include border-opacity($white, 0.05);
      }
      .navbar-nav {
        .nav-item {
          .nav-link {
            @include color-opacity($white, 0.6);
            &:hover,
            &:focus {
              @include color-opacity($white, 0.8);
            }
          }
          &.active {
            > .nav-link {
              color: $white;
            }
          }
        }
      }
      .hk-nav-close {
        color: $white;
      }
    }
  }
  &.hk-nav-toggle {
    .hk-pg-wrapper {
      margin-left: 51px;
    }
    .hk-nav {
      width: 51px;
      .nav-header {
        > span:first-child {
          display: none;
        }
        > span:last-child {
          display: inline;
        }
      }
      .navbar-nav {
        .nav-item {
          .nav-link-text {
            display: none;
          }
        }
        .collapse.show {
          display: none;
        }
      }
      &:hover {
        width: 240px;
        .nav-header {
          > span:first-child {
            display: inline;
          }
          > span:last-child {
            display: none;
          }
        }
        .navbar-nav {
          .nav-item {
            .nav-link-text {
              display: inline-block;
            }
          }
          .collapse.show {
            display: block;
          }
        }
      }
    }
  }
  .hk-nav-backdrop {
    @include background-opacity($dark, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1030;
    @include transition(opacity 0.4s ease);
    opacity: 0;
    height: 0;
    width: 0;
    visibility: hidden;
  }
  &.scrollable-nav {
    .hk-nav {
      position: absolute;
    }
  }
}
@media (max-width: 1199px) {
  .hk-wrapper.hk-vertical-nav {
    .hk-pg-wrapper {
      margin-left: 0px;
    }
    .hk-nav {
      left: -240px;
      top: 0;
      z-index: 1040;
      .navbar-nav-wrap {
        padding-top: 3.3rem;
      }
      .nav-header {
        > span:first-child {
          display: inline;
        }
        > span:last-child {
          display: none;
        }
      }
      .navbar-nav {
        .nav-item {
          .nav-link-text {
            display: inline-block;
          }
        }
        .collapse.show {
          display: block;
        }
      }
    }
    &.hk-nav-toggle {
      .hk-pg-wrapper {
        left: 0;
        margin-left: 0;
      }
      .hk-nav {
        width: 240px;
        left: 0;
        .nav-header {
          > span:first-child {
            display: inline;
          }
          > span:last-child {
            display: none;
          }
        }
        .navbar-nav {
          .nav-item {
            .nav-link-text {
              display: inline-block;
            }
          }
          .collapse.show {
            display: block;
          }
        }
        .hk-nav-close {
          display: block;
        }
      }
      .hk-nav-backdrop {
        opacity: 1;
        height: auto;
        width: auto;
        visibility: visible;
      }
    }
  }
}

/*Horizontal Navigation*/
.hk-wrapper.hk-horizontal-nav {
  .hk-pg-wrapper {
    position: relative;
    left: 0;
    @include transition(all 0.4s ease);
  }
  .hk-nav {
    position: fixed;
    top: 0;
    left: -240px;
    width: 240px;
    margin-left: 0;
    bottom: 0;
    z-index: 1040;
    border: none;
    box-shadow: 0 28px 28px rgba(0, 0, 0, 0.07);
    @include transition(all 0.4s ease);
    .navbar-nav-wrap {
      padding-top: 3.3rem;
      padding-bottom: 0.75rem;
    }
    .hk-nav-close {
      padding: 1rem;
      display: block;
      position: absolute;
      width: 100%;
      z-index: 1;
      background: inherit;
      .feather-icon {
        display: block;
        > svg {
          height: 20px;
          width: 20px;
        }
      }
    }
    .navbar-nav {
      &.flex-row {
        -ms-flex-direction: column !important;
        flex-direction: column !important;
      }
      li {
        width: 240px;
      }
      .nav-item {
        .nav-link-text {
          display: inline-block;
        }
        .nav-link {
          position: relative;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 0.5rem 1rem;
          > i {
            font-size: 20px;
            line-height: 25px;
            min-width: 25px;
          }
          > .feather-icon {
            min-width: 32px;
            line-height: 25px;
            > svg {
              height: 18px;
              width: 18px;
            }
          }
          &[data-toggle="collapse"] {
            &::after {
              content: "\f2f9";
              font: normal normal normal 14px/1 "Material-Design-Iconic-Font";
              font-size: 16px;
              text-rendering: auto;
              margin-left: auto;
              @include transform(rotate(0deg));
              @include transition(all 0.4s ease);
            }
            &[aria-expanded="true"] {
              &::after {
                @include transform(rotate(-180deg));
              }
            }
          }
          &.link-with-badge {
            &:after {
              display: none;
            }
            .badge {
              margin-left: auto;
            }
          }
          &.link-with-indicator {
            > .feather-icon {
              position: relative;
              .badge {
                position: absolute;
                left: 13px;
                top: 0px;
              }
            }
          }
        }
        ul.collapse-level-1 {
          .nav-item {
            .nav-link {
              padding: 0.35rem 1rem 0.35rem 3rem;
              font-size: 14px;
              &[data-toggle="collapse"] {
                &::after {
                  font-size: 14px;
                }
              }
            }
            ul.collapse-level-2 {
              .nav-item {
                .nav-link {
                  padding: 0.35rem 1rem 0.35rem 4rem;
                  &[data-toggle="collapse"] {
                    &::after {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }
        &.horizontal-nav-separator {
          &:after {
            content: "";
            height: 100%;
            width: 1px;
            position: absolute;
            background: $light_10;
            position: absolute;
            top: 0;
            right: 0;
          }
        }
      }
    }
    &.hk-nav-light {
      background: $white;
      .nav-header {
        @include color-opacity($dark, 0.4);
      }
      .nav-separator {
        @include border-opacity($dark, 0.05);
      }
      .navbar-nav {
        .nav-item {
          .nav-link {
            @include color-opacity($dark, 0.6);
            &:hover,
            &:focus {
              @include color-opacity($dark, 0.8);
            }
          }
          &.active {
            > .nav-link {
              color: $dark;
            }
          }
        }
      }
      .hk-nav-close {
        color: $dark;
      }
    }
    &.hk-nav-dark {
      background: $dark_20;
      .nav-header {
        @include color-opacity($white, 0.4);
      }
      .nav-separator {
        @include border-opacity($white, 0.05);
      }
      .navbar-nav {
        .nav-item {
          .nav-link {
            @include color-opacity($white, 0.6);
            &:hover,
            &:focus {
              @include color-opacity($white, 0.8);
            }
          }
          &.active {
            > .nav-link {
              color: $white;
            }
          }
        }
      }
      .hk-nav-close {
        color: $white;
      }
    }
  }
  .hk-nav-backdrop {
    @include background-opacity($dark, 0.5);
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1030;
    @include transition(opacity 0.4s ease);
    opacity: 0;
    height: 0;
    width: 0;
    visibility: hidden;
  }
  &.hk-nav-toggle {
    .hk-pg-wrapper {
      left: 0;
    }
    .hk-nav {
      left: 0;
    }
    .hk-nav-backdrop {
      opacity: 1;
      height: auto;
      width: auto;
      visibility: visible;
    }
  }
  &.scrollable-nav {
    .hk-nav {
      position: absolute;
    }
  }
}
@media (min-width: 1200px) {
  .hk-wrapper.hk-horizontal-nav {
    .navbar-search {
      top: 97px;
      left: 0;
    }
    &.navbar-search-toggle {
      .navbar-search {
        top: 106px;
      }
      .hk-pg-wrapper {
        padding-top: 156px;
        left: 0;
      }
    }
    .hk-pg-wrapper {
      margin-left: 0;
      padding-top: 106px;
      left: 0;
      @include transition(all 0.4s ease);
    }
    .hk-nav {
      left: 0;
      top: 57px;
      bottom: auto;
      width: 100%;
      z-index: 100;
      @include transform(translateY(0));
      box-shadow: 0 0 28px rgba(0, 0, 0, 0.07);
      > .slimScrollDiv {
        overflow: visible !important;
        > .nicescroll-bar {
          overflow: visible !important;
        }
        .slimScrollBar {
          display: none !important;
        }
      }
      .navbar-nav-wrap {
        padding-top: 0;
        padding-bottom: 0;
      }
      .hk-nav-close {
        display: none;
      }
      .navbar-nav {
        > .nav-item > .nav-link {
          min-height: 50px;
        }
        li {
          width: auto;
        }
        &.flex-row {
          -ms-flex-direction: row !important;
          flex-direction: row !important;
        }
        .nav-item {
          position: relative;
          .nav-link {
            padding: 0.75rem 1rem;
            > .feather-icon {
              min-width: 26px;
            }
            &[data-toggle="collapse"] {
              &::after {
                @include transform(rotate(-90deg));
              }
              &[aria-expanded="true"] {
                &::after {
                  @include transform(rotate(-90deg));
                }
              }
            }
            &.link-with-badge {
              .badge {
                margin-left: 15px;
              }
            }
          }
          ul {
            &.collapse-level-1,
            &.collapse-level-2 {
              height: auto !important;
              min-width: 10rem;
              position: absolute !important;
              z-index: 1;
              border-radius: 0.25rem;
              border: 1px solid $light_10;
              &.collapse.show,
              &.collapsing {
                display: none;
              }
            }
            &.collapse-level-1 {
              left: 0;
              padding: 1rem 0;
              .nav-item {
                .nav-link {
                  padding: 0.25rem 1.5rem;
                }
                ul.collapse-level-2 {
                  padding: 0.5rem 0;
                  left: 100%;
                  top: 0;
                  .nav-item {
                    .nav-link {
                      padding: 0.25rem 1rem;
                    }
                  }
                }
              }
            }
          }
          &:hover {
            > ul {
              &.collapse,
              &.collapse.show,
              &.collapsing {
                display: block;
              }
            }
          }
        }
        > .nav-item {
          > .nav-link {
            &[data-toggle="collapse"] {
              &::after {
                margin-left: 10px;
                @include transform(rotate(0deg));
              }
              &[aria-expanded="true"] {
                &::after {
                  @include transform(rotate(0deg));
                }
              }
            }
          }
        }
      }
      &.hk-nav-light {
        .navbar-nav {
          .nav-item {
            ul {
              &.collapse-level-1,
              &.collapse-level-2 {
                background: $white;
              }
            }
          }
        }
      }
      &.hk-nav-dark {
        .navbar-nav {
          .nav-item {
            ul {
              &.collapse-level-1,
              &.collapse-level-2 {
                background: $dark_20;
              }
            }
          }
        }
      }
    }
    &.hk-nav-toggle {
      &.hk-wrapper.hk-horizontal-nav {
        .navbar-search {
          top: 57px;
          left: 0;
        }
        &.navbar-search-toggle .hk-pg-wrapper {
          padding-top: 107px;
          left: 0;
        }
        .hk-pg-wrapper {
          padding-top: 57px;
          margin-left: 0;
          left: 0;
        }
      }
      .hk-nav {
        @include transform(translateY(-100%));
        .navbar-nav .nav-item .nav-link-text {
          display: inline-block;
        }
      }
      .hk-nav-backdrop {
        opacity: 0;
        height: 0;
        width: 0;
        visibility: hidden;
      }
    }
  }
}

.hk-wrapper {
  .hk-settings-panel {
    position: fixed;
    top: 0;
    right: -310px;
    width: 310px;
    bottom: 0;
    z-index: 1040;
    border: none;
    background: $white;
    box-shadow: 0 28px 28px rgba(0, 0, 0, 0.07);
    @include transition(all 0.4s ease);
    .settings-panel-wrap {
      padding: 20px;
      .settings-panel-head {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-flex-pack: space-between;
        a {
          color: $light_40;
          position: absolute;
          right: 10px;
          top: 10px;
          .feather-icon {
            display: block;
            > svg {
              height: 18px;
              width: 18px;
            }
          }
        }
      }
      .layout-img-wrap {
        margin-top: 15px;
        a {
          display: inline-block;
          font-size: 0;
          position: relative;
          img {
            width: 100%;
          }
          i {
            font-size: 15px;
            position: absolute;
            right: 25px;
            top: 10px;
            opacity: 0;
            height: 20px;
            width: 20px;
            background: $sky;
            border-radius: 50%;
            text-align: center;
            padding-top: 2px;
            color: $white;
          }
          &.active {
            i {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &.hk-settings-toggle {
    .hk-settings-panel {
      right: 0;
    }
  }
}
