@import "../../../node_modules/animate.css/animate.css";

@import "../../../node_modules/bourbon/app/assets/stylesheets/bourbon";

@import "mixins.scss";

@import "colors.scss";

@import "owl.carousel";

@import "./parts/bootstrap.min.css";

@import "./style_deepor";

@import "./navbar";

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.custom-select {
  background-image: linear-gradient(45deg, transparent 50%, black 60%),
    linear-gradient(135deg, black 40%, transparent 50%) !important;
  background-position: calc(100% - 20px) 17px, calc(100% - 14px) 17px, 100% 0;
  background-size: 5px 10px, 6px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}
