	@charset "UTF-8"; 
/*------------------------------------------------------------------
[Master Stylesheet]
Project:	protype
++++ Imports ++++
++++ Reset ++++
++++ Basic ++++

	++CSS Basic++
	++Padding++
	++Margin++
	++Background Colors++
	++Text Colors++
	++Border++
	++Colors++
	++Shadow++
	++Font Size++	
	++Font Weight++
	++Text Case++
	++Letter Spacing++
	++Line Height++
	++Width++
	++Max Width++
	++Min Width++
	++Height++
	++Max Height++
	++Min Height++
	++Hr++
	++Opacity++
	++Div Sizes++
	++Z-Index++
	++Loader++
	++Scrollbar++
	++Typography++
	++Buttons++
	++Wrapper++
	++Grid++
	++Section++
	++Footer++
	++Form++
	++Inut group++
	++Form validation++
	++Select2++
	++Tinymce++
	++File Upload++
	++Jquery Toggle++
	++Form-Wizard++
	++Popover++
	++Accordion++
	++Table++
	++Data Table++
	++Responsive Table++
	++Morris Chart++
	++Sparkline++
	++Flot Chart++
	++Peity chart++	
	++Echats++
	++Easy pie chart++
	++Nestable++
	++Modals++
	++Sweet-alert++
	++Notification++
	++Tooltop++
	++Images++  
	++Icons++
	++Checkbox++
	++Radio++
	++Carousel++
	++Twitter slider++
	++Color Pickr++
	++Range Slider++
	++Daterangepicker++
	++Invoice++
	++Faq++
	++Profile++
	++Activity++
	++Widgets++
	++Dropdown++
	++Avatar++
	++Pagination++
	++Badge++
	++List Group++
	++Google Map++
	++Vecotor Map++
	++Progressbar++
	++Chat UI++
	++Email UI++
	++Caledar++
	++Auth pages++
	++Gallery++
	++Cropperjs++
-------------------------------------------------------------------*/

/****************************/
/** Imports **/
/****************************/
@import url(https://fonts.googleapis.com/icon?family=Material+Icons|Roboto:400,500,700);

/****************************/
/** Reset **/
/****************************/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, pre, a, abbr, acronym,address, big, cite, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
em,cite {
	font-style: italic;
}	

strong{
	font-weight:bold;
}
 
body {
	color: $smoke;
	font-size: 1rem;
	font-style: normal;
	font-weight:400;
	font-family:"Roboto", sans-serif;
	line-height:1.5;
	background:#acb0b1;
	overflow-x:hidden;
}
.main-font {
	font-family: "Roboto", sans-serif;
}
a:hover,a:active {
    outline: 0;
}

ul {
    list-style: none;
}

a {
   text-decoration: none;
   color:$sky;
   @include transition(color .2s ease);
	&:hover {
		text-decoration: none;
		 color:$sky;
	}
	&:focus {
		text-decoration: none;
		outline: none;
		color:$sky;
	}
}

h1,h2,h3,h4,h5,h6,.h1, .h2, .h3, .h4, .h5, .h6 {
	color:$dark;
	font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
}

.h1, h1 {
    font-size: 2.5rem;
}

.h2, h2 {
    font-size: 2rem;
}

.h3, h3 {
    font-size: 1.75rem;
}

.h4, h4 {
    font-size: 1.5rem;
}

.h5, h5 {
    font-size: 1.25rem;
}

.h6, h6 {
    font-size: 1rem;
}
@media (max-width: 576px) {
	.h1, h1 {
		font-size: 2.2rem;
	}
}
p{
	&.lead {
		font-size:18px;
		font-weight:400;
		color:$sky;
		line-height:27px;
		margin-bottom:0;
	}
}
.force-UTF-8{
    content: "¡";
}
dt {
    font-weight: 600;
}
::-webkit-file-upload-button { cursor:pointer; }
/****************************/
/** Basic **/
/****************************/

/*CSS Basic*/
.overflow-hide {
	overflow:hidden !important;
}
.overflow-show {
	overflow:visible !important;
}
.clearfix{
	@include clear;
}
.word-break {
	word-break:break-all;
}
.seprator-block {
    margin-bottom: 80px;
}
.twa-2x {
    background-size: 1.3em 1.3em;
    height: 1.3em;
    width: 1.3em;
}
/*Absolute positions*/
.t-0 {
	top:0!important;
}
.b-0 {
	bottom:0!important;
}
.l-0 {
	left:0!important;
}
.r-0 {
	right:0!important;
}
/*Padding*/
$num: 0;
@while $num < 101 {
    .pa-#{$num} {
        padding: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .pt-#{$num} {
        padding-top: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .pb-#{$num} {
        padding-bottom: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .pl-#{$num} {
        padding-left: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .pr-#{$num} {
        padding-right: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .px-#{$num} {
        padding-left: $num +0px !important;
        padding-right: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .py-#{$num} {
        padding-top: $num +0px !important;
        padding-bottom: $num +0px !important;
    }
    $num: $num +5;
}
@media (min-width: 576px) { 
	$num: 0;
	@while $num < 101 {
		.pa-sm-#{$num} {
			padding: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pt-sm-#{$num} {
			padding-top: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pb-sm-#{$num} {
			padding-bottom: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pl-sm-#{$num} {
			padding-left: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pr-sm-#{$num} {
			padding-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.px-sm-#{$num} {
			padding-left: $num +0px !important;
			padding-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.py-sm-#{$num} {
			padding-top: $num +0px !important;
			padding-bottom: $num +0px !important;
		}
		$num: $num +5;
	}
}
@media (min-width: 768px) { 
	$num: 0;
	@while $num < 101 {
		.pa-md-#{$num} {
			padding: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pt-md-#{$num} {
			padding-top: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pb-md-#{$num} {
			padding-bottom: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pl-md-#{$num} {
			padding-left: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pr-md-#{$num} {
			padding-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.px-md-#{$num} {
			padding-left: $num +0px !important;
			padding-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.py-md-#{$num} {
			padding-top: $num +0px !important;
			padding-bottom: $num +0px !important;
		}
		$num: $num +5;
	}
}
@media (min-width: 992px) { 
	$num: 0;
	@while $num < 101 {
		.pa-lg-#{$num} {
			padding: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pt-lg-#{$num} {
			padding-top: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pb-lg-#{$num} {
			padding-bottom: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pl-lg-#{$num} {
			padding-left: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pr-lg-#{$num} {
			padding-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.px-lg-#{$num} {
			padding-left: $num +0px !important;
			padding-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.py-lg-#{$num} {
			padding-top: $num +0px !important;
			padding-bottom: $num +0px !important;
		}
		$num: $num +5;
	}
}
@media (min-width: 1200px) { 
	$num: 0;
	@while $num < 101 {
		.pa-xl-#{$num} {
			padding: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pt-xl-#{$num} {
			padding-top: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pb-xl-#{$num} {
			padding-bottom: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pl-xl-#{$num} {
			padding-left: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.pr-xl-#{$num} {
			padding-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.px-xl-#{$num} {
			padding-left: $num +0px !important;
			padding-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.py-xl-#{$num} {
			padding-top: $num +0px !important;
			padding-bottom: $num +0px !important;
		}
		$num: $num +5;
	}
}
/*Margin*/
$num: 0;
@while $num < 101 {
    .ma-#{$num} {
        margin: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .mt-#{$num} {
        margin-top: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .mb-#{$num} {
        margin-bottom: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .ml-#{$num} {
        margin-left: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .mr-#{$num} {
        margin-right: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .mx-#{$num} {
        margin-left: $num +0px !important;
        margin-right: $num +0px !important;
    }
    $num: $num +5;
}

$num: 0;
@while $num < 101 {
    .my-#{$num} {
        margin-top: $num +0px !important;
        margin-bottom: $num +0px !important;
    }
    $num: $num +5;
}
@media (min-width: 576px) { 
	$num: 0;
	@while $num < 101 {
		.ma-sm-#{$num} {
			margin: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mt-sm-#{$num} {
			margin-top: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mb-sm-#{$num} {
			margin-bottom: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.ml-sm-#{$num} {
			margin-left: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mr-sm-#{$num} {
			margin-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mx-sm-#{$num} {
			margin-left: $num +0px !important;
			margin-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.my-sm-#{$num} {
			margin-top: $num +0px !important;
			margin-bottom: $num +0px !important;
		}
		$num: $num +5;
	}
}
@media (min-width: 768px) { 
	$num: 0;
	@while $num < 101 {
		.ma-md-#{$num} {
			margin: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mt-md-#{$num} {
			margin-top: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mb-md-#{$num} {
			margin-bottom: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.ml-md-#{$num} {
			margin-left: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mr-md-#{$num} {
			margin-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mx-md-#{$num} {
			margin-left: $num +0px !important;
			margin-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.my-md-#{$num} {
			margin-top: $num +0px !important;
			margin-bottom: $num +0px !important;
		}
		$num: $num +5;
	}
}
@media (min-width: 992px) { 
	$num: 0;
	@while $num < 101 {
		.ma-lg-#{$num} {
			margin: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mt-lg-#{$num} {
			margin-top: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mb-lg-#{$num} {
			margin-bottom: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.ml-lg-#{$num} {
			margin-left: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mr-lg-#{$num} {
			margin-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mx-lg-#{$num} {
			margin-left: $num +0px !important;
			margin-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.my-lg-#{$num} {
			margin-top: $num +0px !important;
			margin-bottom: $num +0px !important;
		}
		$num: $num +5;
	}
}
@media (min-width: 1200px) { 
	$num: 0;
	@while $num < 101 {
		.ma-xl-#{$num} {
			margin: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mt-xl-#{$num} {
			margin-top: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mb-xl-#{$num} {
			margin-bottom: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.ml-xl-#{$num} {
			margin-left: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mr-xl-#{$num} {
			margin-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.mx-xl-#{$num} {
			margin-left: $num +0px !important;
			margin-right: $num +0px !important;
		}
		$num: $num +5;
	}

	$num: 0;
	@while $num < 101 {
		.my-xl-#{$num} {
			margin-top: $num +0px !important;
			margin-bottom: $num +0px !important;
		}
		$num: $num +5;
	}
}
/*Background Colors*/
.bg-sky-light-5 {
	background-color:$sky_light_5 !important;
}
.bg-sky-light-4 {
	background-color:$sky_light_4 !important;
}
.bg-sky-light-3 {
	background-color:$sky_light_3 !important;
}
.bg-sky-light-2 {
	background-color:$sky_light_2 !important;
}
.bg-sky-light-1 {
	background-color:$sky_light_1 !important;
}
.bg-sky,
.bg-primary {
	background-color:$sky !important;
}
.bg-sky-dark-5 {
	background-color:$sky_dark_5 !important;
}
.bg-sky-dark-4 {
	background-color:$sky_dark_4 !important;
}
.bg-sky-dark-3 {
	background-color:$sky_dark_3 !important;
}
.bg-sky-dark-2 {
	background-color:$sky_dark_2 !important;
}
.bg-sky-dark-1 {
	background-color:$sky_dark_1 !important;
}
.bg-green-light-5 {
	background-color:$green_light_5 !important;
}
.bg-green-light-4 {
	background-color:$green_light_4 !important;
}
.bg-green-light-3 {
	background-color:$green_light_3 !important;
}
.bg-green-light-2 {
	background-color:$green_light_2 !important;
}
.bg-green-light-1 {
	background-color:$green_light_1 !important;
}
.bg-green ,
.bg-success{
	background-color:$green !important;
}
.bg-green-dark-5 {
	background-color:$green_dark_5 !important;
}
.bg-green-dark-4 {
	background-color:$green_dark_4 !important;
}
.bg-green-dark-3 {
	background-color:$green_dark_3 !important;
}
.bg-green-dark-2 {
	background-color:$green_dark_2 !important;
}
.bg-green-dark-1 {
	background-color:$green_dark_1 !important;
}
.bg-yellow-light-5 {
	background-color:$yellow_light_5 !important;
}
.bg-yellow-light-4 {
	background-color:$yellow_light_4 !important;
}
.bg-yellow-light-3 {
	background-color:$yellow_light_3 !important;
}
.bg-yellow-light-2 {
	background-color:$yellow_light_2 !important;
}
.bg-yellow-light-1 {
	background-color:$yellow_light_1 !important;
}
.bg-yellow,
.bg-warning {
	background-color:$yellow !important;
}
.bg-yellow-dark-5 {
	background-color:$yellow_dark_5 !important;
}
.bg-yellow-dark-4 {
	background-color:$yellow_dark_4 !important;
}
.bg-yellow-dark-3 {
	background-color:$yellow_dark_3 !important;
}
.bg-yellow-dark-2 {
	background-color:$yellow_dark_2 !important;
}
.bg-yellow-dark-1 {
	background-color:$yellow_dark_1 !important;
}
.bg-red-light-5 {
	background-color:$red_light_5 !important;
}
.bg-red-light-4 {
	background-color:$red_light_4 !important;
}
.bg-red-light-3 {
	background-color:$red_light_3 !important;
}
.bg-red-light-2 {
	background-color:$red_light_2 !important;
}
.bg-red-light-1 {
	background-color:$red_light_1 !important;
}
.bg-red,
.bg-danger {
	background-color:$red !important;
}
.bg-red-dark-5 {
	background-color:$red_dark_5 !important;
}
.bg-red-dark-4 {
	background-color:$red_dark_4 !important;
}
.bg-red-dark-3 {
	background-color:$red_dark_3 !important;
}
.bg-red-dark-2 {
	background-color:$red_dark_2 !important;
}
.bg-red-dark-1 {
	background-color:$red_dark_1 !important;
}
.bg-blue-light-5 {
	background-color:$blue_light_5 !important;
}
.bg-blue-light-4 {
	background-color:$blue_light_4 !important;
}
.bg-blue-light-3 {
	background-color:$blue_light_3 !important;
}
.bg-blue-light-2 {
	background-color:$blue_light_2 !important;
}
.bg-blue-light-1 {
	background-color:$blue_light_1 !important;
}
.bg-blue {
	background-color:$blue !important;
}
.bg-blue-dark-5 {
	background-color:$blue_dark_5 !important;
}
.bg-blue-dark-4 {
	background-color:$blue_dark_4 !important;
}
.bg-blue-dark-3 {
	background-color:$blue_dark_3 !important;
}
.bg-blue-dark-2 {
	background-color:$blue_dark_2 !important;
}
.bg-blue-dark-1 {
	background-color:$blue_dark_1 !important;
}
.bg-cyan-light-5 {
	background-color:$cyan_light_5 !important;
}
.bg-cyan-light-4 {
	background-color:$cyan_light_4 !important;
}
.bg-cyan-light-3 {
	background-color:$cyan_light_3 !important;
}
.bg-cyan-light-2 {
	background-color:$cyan_light_2 !important;
}
.bg-cyan-light-1 {
	background-color:$cyan_light_1 !important;
}
.bg-cyan,
.bg-info {
	background-color:$cyan !important;
}
.bg-cyan-dark-5 {
	background-color:$cyan_dark_5 !important;
}
.bg-cyan-dark-4 {
	background-color:$cyan_dark_4 !important;
}
.bg-cyan-dark-3 {
	background-color:$cyan_dark_3 !important;
}
.bg-cyan-dark-2 {
	background-color:$cyan_dark_2 !important;
}
.bg-cyan-dark-1 {
	background-color:$cyan_dark_1 !important;
}
.bg-grey-light-5,
.bg-secondary-light-5 {
	background-color:$grey_light_5 !important;
}
.bg-grey-light-4,
.bg-secondary-light-4 {
	background-color:$grey_light_4 !important;
}
.bg-grey-light-3,
.bg-secondary-light-3 {
	background-color:$grey_light_3 !important;
}
.bg-grey-light-2,
.bg-secondary-light-2 {
	background-color:$grey_light_2 !important;
}
.bg-grey-light-1,
.bg-secondary-light-1 {
	background-color:$grey_light_1 !important;
}
.bg-secondary,
.bg-grey {
	background-color:$grey !important;
}
.bg-grey-dark-1,
.bg-secondary-dark-1 {
	background-color:$grey_dark_1 !important;
}
.bg-grey-dark-2,
.bg-secondary-dark-2 {
	background-color:$grey_dark_2 !important;
}
.bg-grey-dark-3,
.bg-secondary-dark-3 {
	background-color:$grey_dark_3 !important;
}
.bg-grey-dark-4,
.bg-secondary-dark-4 {
	background-color:$grey_dark_4 !important;
}
.bg-grey-dark-5,
.bg-secondary-dark-5 {
	background-color:$grey_dark_5 !important;
}
.bg-pink-light-5 {
	background-color:$pink_light_5 !important;
}
.bg-pink-light-4 {
	background-color:$pink_light_4 !important;
}
.bg-pink-light-3 {
	background-color:$pink_light_3 !important;
}
.bg-pink-light-2 {
	background-color:$pink_light_2 !important;
}
.bg-pink-light-1 {
	background-color:$pink_light_1 !important;
}
.bg-pink {
	background-color:$pink !important;
}
.bg-pink-dark-5 {
	background-color:$pink_dark_5 !important;
}
.bg-pink-dark-4 {
	background-color:$pink_dark_4 !important;
}
.bg-pink-dark-3 {
	background-color:$pink_dark_3 !important;
}
.bg-pink-dark-2 {
	background-color:$pink_dark_2 !important;
}
.bg-pink-dark-1 {
	background-color:$pink_dark_1 !important;
}
.bg-purple-light-5 {
	background-color:$purple_light_5 !important;
}
.bg-purple-light-4 {
	background-color:$purple_light_4 !important;
}
.bg-purple-light-3 {
	background-color:$purple_light_3 !important;
}
.bg-purple-light-2 {
	background-color:$purple_light_2 !important;
}
.bg-purple-light-1 {
	background-color:$purple_light_1 !important;
}
.bg-purple {
	background-color:$purple !important;
}
.bg-purple-dark-5 {
	background-color:$purple_dark_5 !important;
}
.bg-purple-dark-4 {
	background-color:$purple_dark_4 !important;
}
.bg-purple-dark-3 {
	background-color:$purple_dark_3 !important;
}
.bg-purple-dark-2 {
	background-color:$purple_dark_2 !important;
}
.bg-purple-dark-1 {
	background-color:$purple_dark_1 !important;
}
.bg-violet-light-5 {
	background-color:$violet_light_5 !important;
}
.bg-violet-light-4 {
	background-color:$violet_light_4 !important;
}
.bg-violet-light-3 {
	background-color:$violet_light_3 !important;
}
.bg-violet-light-2 {
	background-color:$violet_light_2 !important;
}
.bg-violet-light-1 {
	background-color:$violet_light_1 !important;
}
.bg-violet {
	background-color:$violet !important;
}
.bg-violet-dark-5 {
	background-color:$violet_dark_5 !important;
}
.bg-violet-dark-4 {
	background-color:$violet_dark_4 !important;
}
.bg-violet-dark-3 {
	background-color:$violet_dark_3 !important;
}
.bg-violet-dark-2 {
	background-color:$violet_dark_2 !important;
}
.bg-violet-dark-1 {
	background-color:$violet_dark_1 !important;
}
.bg-indigo-light-5 {
	background-color:$indigo_light_5 !important;
}
.bg-indigo-light-4 {
	background-color:$indigo_light_4 !important;
}
.bg-indigo-light-3 {
	background-color:$indigo_light_3 !important;
}
.bg-indigo-light-2 {
	background-color:$indigo_light_2 !important;
}
.bg-indigo-light-1 {
	background-color:$indigo_light_1 !important;
}
.bg-indigo {
	background-color:$indigo !important;
}
.bg-indigo-dark-5 {
	background-color:$indigo_dark_5 !important;
}
.bg-indigo-dark-4 {
	background-color:$indigo_dark_4 !important;
}
.bg-indigo-dark-3 {
	background-color:$indigo_dark_3 !important;
}
.bg-indigo-dark-2 {
	background-color:$indigo_dark_2 !important;
}
.bg-indigo-dark-1 {
	background-color:$indigo_dark_1 !important;
}
.bg-teal-light-5 {
	background-color:$teal_light_5 !important;
}
.bg-teal-light-4 {
	background-color:$teal_light_4 !important;
}
.bg-teal-light-3 {
	background-color:$teal_light_3 !important;
}
.bg-teal-light-2 {
	background-color:$teal_light_2 !important;
}
.bg-teal-light-1 {
	background-color:$teal_light_1 !important;
}
.bg-teal {
	background-color:$teal !important;
}
.bg-teal-dark-5 {
	background-color:$teal_dark_5 !important;
}
.bg-teal-dark-4 {
	background-color:$teal_dark_4 !important;
}
.bg-teal-dark-3 {
	background-color:$teal_dark_3 !important;
}
.bg-teal-dark-2 {
	background-color:$teal_dark_2 !important;
}
.bg-teal-dark-1 {
	background-color:$teal_dark_1 !important;
}
.bg-neon-light-5 {
	background-color:$neon_light_5 !important;
}
.bg-neon-light-4 {
	background-color:$neon_light_4 !important;
}
.bg-neon-light-3 {
	background-color:$neon_light_3 !important;
}
.bg-neon-light-2 {
	background-color:$neon_light_2 !important;
}
.bg-neon-light-1 {
	background-color:$neon_light_1 !important;
}
.bg-neon {
	background-color:$neon !important;
}
.bg-neon-dark-5 {
	background-color:$neon_dark_5 !important;
}
.bg-neon-dark-4 {
	background-color:$neon_dark_4 !important;
}
.bg-neon-dark-3 {
	background-color:$neon_dark_3 !important;
}
.bg-neon-dark-2 {
	background-color:$neon_dark_2 !important;
}
.bg-neon-dark-1 {
	background-color:$neon_dark_1 !important;
}
.bg-lime-light-5 {
	background-color:$lime_light_5 !important;
}
.bg-lime-light-4 {
	background-color:$lime_light_4 !important;
}
.bg-lime-light-3 {
	background-color:$lime_light_3 !important;
}
.bg-lime-light-2 {
	background-color:$lime_light_2 !important;
}
.bg-lime-light-1 {
	background-color:$lime_light_1 !important;
}
.bg-lime {
	background-color:$lime !important;
}
.bg-lime-dark-5 {
	background-color:$lime_dark_5 !important;
}
.bg-lime-dark-4 {
	background-color:$lime_dark_4 !important;
}
.bg-lime-dark-3 {
	background-color:$lime_dark_3 !important;
}
.bg-lime-dark-2 {
	background-color:$lime_dark_2 !important;
}
.bg-lime-dark-1 {
	background-color:$lime_dark_1 !important;
}
.bg-sun-light-5 {
	background-color:$sun_light_5 !important;
}
.bg-sun-light-4 {
	background-color:$sun_light_4 !important;
}
.bg-sun-light-3 {
	background-color:$sun_light_3 !important;
}
.bg-sun-light-2 {
	background-color:$sun_light_2 !important;
}
.bg-sun-light-1 {
	background-color:$sun_light_1 !important;
}
.bg-sun {
	background-color:$sun !important;
}
.bg-sun-dark-5 {
	background-color:$sun_dark_5 !important;
}
.bg-sun-dark-4 {
	background-color:$sun_dark_4 !important;
}
.bg-sun-dark-3 {
	background-color:$sun_dark_3 !important;
}
.bg-sun-dark-2 {
	background-color:$sun_dark_2 !important;
}
.bg-sun-dark-1 {
	background-color:$sun_dark_1 !important;
}
.bg-orange-light-5 {
	background-color:$orange_light_5 !important;
}
.bg-orange-light-4 {
	background-color:$orange_light_4 !important;
}
.bg-orange-light-3 {
	background-color:$orange_light_3 !important;
}
.bg-orange-light-2 {
	background-color:$orange_light_2 !important;
}
.bg-orange-light-1 {
	background-color:$orange_light_1 !important;
}
.bg-orange {
	background-color:$orange !important;
}
.bg-orange-dark-5 {
	background-color:$orange_dark_5 !important;
}
.bg-orange-dark-4 {
	background-color:$orange_dark_4 !important;
}
.bg-orange-dark-3 {
	background-color:$orange_dark_3 !important;
}
.bg-orange-dark-2 {
	background-color:$orange_dark_2 !important;
}
.bg-orange-dark-1 {
	background-color:$orange_dark_1 !important;
}
.bg-pumpkin-light-5 {
	background-color:$pumpkin_light_5 !important;
}
.bg-pumpkin-light-4 {
	background-color:$pumpkin_light_4 !important;
}
.bg-pumpkin-light-3 {
	background-color:$pumpkin_light_3 !important;
}
.bg-pumpkin-light-2 {
	background-color:$pumpkin_light_2 !important;
}
.bg-pumpkin-light-1 {
	background-color:$pumpkin_light_1 !important;
}
.bg-pumpkin {
	background-color:$pumpkin !important;
}
.bg-pumpkin-dark-5 {
	background-color:$pumpkin_dark_5 !important;
}
.bg-pumpkin-dark-4 {
	background-color:$pumpkin_dark_4 !important;
}
.bg-pumpkin-dark-3 {
	background-color:$pumpkin_dark_3 !important;
}
.bg-pumpkin-dark-2 {
	background-color:$pumpkin_dark_2 !important;
}
.bg-pumpkin-dark-1 {
	background-color:$pumpkin_dark_1 !important;
}
.bg-brown-light-5 {
	background-color:$brown_light_5 !important;
}
.bg-brown-light-4 {
	background-color:$brown_light_4 !important;
}
.bg-brown-light-3 {
	background-color:$brown_light_3 !important;
}
.bg-brown-light-2 {
	background-color:$brown_light_2 !important;
}
.bg-brown-light-1 {
	background-color:$brown_light_1 !important;
}
.bg-brown {
	background-color:$brown !important;
}
.bg-brown-dark-5 {
	background-color:$brown_dark_5 !important;
}
.bg-brown-dark-4 {
	background-color:$brown_dark_4 !important;
}
.bg-brown-dark-3 {
	background-color:$brown_dark_3 !important;
}
.bg-brown-dark-2 {
	background-color:$brown_dark_2 !important;
}
.bg-brown-dark-1 {
	background-color:$brown_dark_1 !important;
}
.bg-gold-light-5 {
	background-color:$gold_light_5 !important;
}
.bg-gold-light-4 {
	background-color:$gold_light_4 !important;
}
.bg-gold-light-3 {
	background-color:$gold_light_3 !important;
}
.bg-gold-light-2 {
	background-color:$gold_light_2 !important;
}
.bg-gold-light-1 {
	background-color:$gold_light_1 !important;
}
.bg-gold {
	background-color:$gold !important;
}
.bg-gold-dark-5 {
	background-color:$gold_dark_5 !important;
}
.bg-gold-dark-4 {
	background-color:$gold_dark_4 !important;
}
.bg-gold-dark-3 {
	background-color:$gold_dark_3 !important;
}
.bg-gold-dark-2 {
	background-color:$gold_dark_2 !important;
}
.bg-gold-dark-1 {
	background-color:$gold_dark_1 !important;
}
.bg-smoke-light-5 {
	background-color:$smoke_light_5 !important;
}
.bg-smoke-light-4 {
	background-color:$smoke_light_4 !important;
}
.bg-smoke-light-3 {
	background-color:$smoke_light_3 !important;
}
.bg-smoke-light-2 {
	background-color:$smoke_light_2 !important;
}
.bg-smoke-light-1 {
	background-color:$smoke_light_1 !important;
}
.bg-smoke {
	background-color:$smoke !important;
}
.bg-smoke-dark-5 {
	background-color:$smoke_dark_5 !important;
}
.bg-smoke-dark-4 {
	background-color:$smoke_dark_4 !important;
}
.bg-smoke-dark-3 {
	background-color:$smoke_dark_3 !important;
}
.bg-smoke-dark-2 {
	background-color:$smoke_dark_2 !important;
}
.bg-smoke-dark-1 {
	background-color:$smoke_dark_1 !important;
}
.bg-white {
	background-color:$white !important;
}
.bg-light {
	background-color:$light !important;
}
.bg-light-10 {
	background-color:$light_10 !important;
}
.bg-light-15 {
	background-color:$light_15 !important;
}
.bg-light-20 {
	background-color:$light_20 !important;
}
.bg-light-30 {
	background-color:$light_30 !important;
}
.bg-light-40 {
	background-color:$light_40 !important;
}
.bg-light-50 {
	background-color:$light_50 !important;
}
.bg-light-60 {
	background-color:$light_60 !important;
}
.bg-light-70 {
	background-color:$light_70 !important;
}
.bg-light-80 {
	background-color:$light_80 !important;
}
.bg-light-90 {
	background-color:$light_90 !important;
}
.bg-dark {
	background-color:$dark !important;
}
.bg-dark-20 {
	background-color:$dark_20 !important;
}
.bg-dark-40 {
	background-color:$dark_40 !important;
}
.bg-dark-60 {
	background-color:$dark_60 !important;
}
.bg-dark-80 {
	background-color:$dark_80 !important;
}
.bg-dark-100 {
	background-color:$dark_100 !important;
}
.bg-twitter {
	background-color:$twitter_blue !important;
}
.bg-gradient-primary {
	@include bg-linear-gradient(45deg,$sky,$violet);
}
.bg-gradient-info {
	@include bg-linear-gradient(45deg,$cyan,$indigo);
}
.bg-gradient-success {
	@include bg-linear-gradient(45deg,$green,$lime);
}
.bg-gradient-warning {
	@include bg-linear-gradient(45deg,$yellow,$pumpkin);
}
.bg-gradient-danger {
	@include bg-linear-gradient(45deg,$red,$purple);
}
.bg-gradient-secondary {
	@include bg-linear-gradient(45deg,$grey,$smoke);
}
.bg-gradient-light {
	@include bg-linear-gradient(45deg,$light,$grey);
}
.bg-gradient-dark {
	@include bg-linear-gradient(45deg,$dark,$brown);
}
.bg-gradient-pony {
	@include bg-linear-gradient(45deg,$pink,$cyan);
}
.bg-gradient-space {
	@include bg-linear-gradient(45deg,$purple,$sun);
}
.bg-gradient-streaks {
	@include bg-linear-gradient(45deg,$violet,$teal);
}
.bg-gradient-bunting {
	@include bg-linear-gradient(45deg,$indigo,$brown);
}
.bg-gradient-paradise {
	@include bg-linear-gradient(45deg,$blue,$neon);
}
.bg-gradient-heaven {
	@include bg-linear-gradient(45deg,$neon,$sky);
}
.bg-gradient-honey {
	@include bg-linear-gradient(45deg,$lime,$orange);
}
.bg-gradient-warbler {
	@include bg-linear-gradient(45deg,$sun,$grey);
}
.bg-gradient-dusk {
	@include bg-linear-gradient(45deg,$orange,$violet);
}
.bg-gradient-citrine {
	@include bg-linear-gradient(45deg,$pumpkin_light_3,$pink_light_3);
}
.bg-gradient-royston {
	@include bg-linear-gradient(45deg,$brown,$teal);
}
.bg-gradient-ashes	 {
	@include bg-linear-gradient(45deg,$smoke,$dark);
}
.bg-gradient-metal {
	@include bg-linear-gradient(45deg,$gold,$grey);
}
.bg-gradient-sunset {
	@include bg-linear-gradient(45deg,$teal,$pumpkin);
}
.bg-trans-light-10 {
	@include background-opacity($white,.1);
}
.bg-trans-light-15 {
	@include background-opacity($white,.15);
}
.bg-trans-light-20 {
	@include background-opacity($white,.2);
}
.bg-trans-light-25 {
	@include background-opacity($white,.25);
}
.bg-trans-light-30 {
	@include background-opacity($white,.3);
}
.bg-trans-light-35 {
	@include background-opacity($white,.35);
}
.bg-trans-light-40 {
	@include background-opacity($white,.4);
}
.bg-trans-light-45 {
	@include background-opacity($white,.45);
}
.bg-trans-light-50 {
	@include background-opacity($white,.5);
}
.bg-trans-light-55 {
	@include background-opacity($white,.55);
}
.bg-trans-light-60 {
	@include background-opacity($white,.6);
}
.bg-trans-light-65 {
	@include background-opacity($white,.65);
}
.bg-trans-light-70 {
	@include background-opacity($white,.7);
}
.bg-trans-light-75 {
	@include background-opacity($white,.75);
}
.bg-trans-light-80 {
	@include background-opacity($white,.8);
}
.bg-trans-light-85 {
	@include background-opacity($white,.85);
}
.bg-trans-light-90 {
	@include background-opacity($white,.9);
}
.bg-trans-dark-10 {
	@include background-opacity($dark_80,.1);
}
.bg-trans-dark-15 {
	@include background-opacity($dark_80,.15);
}
.bg-trans-dark-20 {
	@include background-opacity($dark_80,.2);
}
.bg-trans-dark-25 {
	@include background-opacity($dark_80,.25);
}
.bg-trans-dark-30 {
	@include background-opacity($dark_80,.3);
}
.bg-trans-dark-35 {
	@include background-opacity($dark_80,.35);
}
.bg-trans-dark-40 {
	@include background-opacity($dark_80,.4);
}
.bg-trans-dark-45 {
	@include background-opacity($dark_80,.45);
}
.bg-trans-dark-50 {
	@include background-opacity($dark_80,.5);
}
.bg-trans-dark-55 {
	@include background-opacity($dark_80,.55);
}
.bg-trans-dark-60 {
	@include background-opacity($dark_80,.6);
}
.bg-trans-dark-65 {
	@include background-opacity($dark_80,.65);
}
.bg-trans-dark-70 {
	@include background-opacity($dark_80,.7);
}
.bg-trans-dark-75 {
	@include background-opacity($dark_80,.75);
}
.bg-trans-dark-80 {
	@include background-opacity($dark_80,.8);
}
.bg-trans-dark-85 {
	@include background-opacity($dark_80,.85);
}
.bg-trans-dark-90 {
	@include background-opacity($dark_80,.9);
}
.bg-trans-dark-95 {
	@include background-opacity($dark_80,.95);
}
.overlay-wrap {
	position:relative;
	.bg-overlay {
		position:absolute;
		left:0;
		top:0;
		height:100%;
		width:100%;
		z-index:1;
	}
}
/*Text Colors*/
.text-sky,
.text-primary {
	color: $sky !important;
}
.text-cyan,
.text-info {
	color: $cyan !important;
}
.text-green,
.text-success {
	color: $green !important;
}
.text-yellow,
.text-warning {
	color: $yellow !important;
}
.text-red,
.text-danger {
	color: $red !important;
}
.text-blue {
	color: $blue !important;
}
.text-pink {
	color: $pink !important;
}
.text-purple {
	color: $purple !important;
}
.text-violet {
	color: $violet !important;
}
.text-indigo {
	color: $indigo !important;
}
.text-blue {
	color: $blue !important;
}
.text-teal {
	color: $teal !important;
}
.text-neon {
	color: $neon !important;
}
.text-lime {
	color: $lime !important;
}
.text-sun {
	color: $sun !important;
}
.text-orange {
	color: $orange !important;
}
.text-pumpkin {
	color: $pumpkin !important;
}
.text-brown {
	color: $brown !important;
}
.text-gold {
	color: $gold !important;
}
.text-smoke,
.text-muted {
	color: $smoke !important;
}
.text-grey,
.text-secondary {
	color:$grey !important;
}
.text-dark {
	color:$dark !important;
}
.text-light {
	color:$light_60 !important;
}
.text-white {
	color:$white !important;
}
.text-light-10 {
	color:$light_10 !important;
}
.text-light-15 {
	color:$light_15 !important;
}
.text-light-20 {
	color:$light_20 !important;
}
.text-light-30 {
	color:$light_30 !important;
}
.text-light-40 {
	color:$light_40 !important;
}
.text-light-50 {
	color:$light_50 !important;
}
.text-light-60 {
	color:$light_60 !important;
}
.text-light-70 {
	color:$light_70 !important;
}
.text-light-80 {
	color:$light_80 !important;
}
.text-light-90 {
	color:$light_90 !important;
}
.text-dark-20 {
	color:$dark_20 !important;
}
.text-dark-40 {
	color:$dark_40 !important;
}
.text-dark-60 {
	color:$dark_60 !important;
}
.text-dark-80 {
	color:$dark_80 !important;
}
.text-dark-100 {
	color:$dark_100 !important;
}
.text-disabled {
	color:$light_30 !important;
}

/*Border*/
.border {
    border: 1px solid $light_10!important;
}
.border-top {
    border-top: 1px solid $light_10!important;
}
.border-bottom {
    border-bottom: 1px solid $light_10!important;
}
.border-left {
    border-left: 1px solid $light_10!important;
}
.border-right {
    border-right: 1px solid $light_10!important;
}
.border-2 {
	border-width:2px!important;
}
.border-3 {
	border-width:3px!important;
}
.border-4 {
	border-width:4px!important;
}
.border-5 {
	border-width:5px!important;
}
.border-0 {
    border: 0!important;
}
.border-top-0 {
    border-top: 0!important;
}
.border-bottom-0 {
    border-bottom: 0!important;
}
.border-left-0 {
    border-left: 0!important;
}
.border-right-0 {
    border-right: 0!important;
}
.rounded {
    border-radius: 4px!important;
}
.rounded-top {
    border-top-left-radius: .25rem!important;
    border-top-right-radius: .25rem!important;
}
.rounded-top-left {
    border-top-left-radius: .25rem!important;
}
.rounded-top-right {
    border-top-right-radius: .25rem!important;
}
.rounded-left {
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important;
}
.rounded-right {
    border-top-right-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important;
}
.rounded-bottom {
    border-bottom-left-radius: .25rem!important;
    border-bottom-right-radius: .25rem!important;
}
.rounded-bottom-left {
    border-bottom-left-radius: .25rem!important;
}
.rounded-bottom-right {
    border-bottom-right-radius: .25rem!important;
}
.rounded-top-0 {
    border-top-left-radius: 0!important;
    border-top-right-radius: 0!important;
}
.rounded-top-left-0 {
    border-top-left-radius: 0!important;
}
.rounded-top-right-0 {
    border-top-right-radius: 0!important;
}
.rounded-left-0 {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important;
}
.rounded-right-0 {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.rounded-bottom-0 {
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.rounded-bottom-left-0 {
    border-bottom-left-radius: 0!important;
}
.rounded-bottom-right-0 {
    border-bottom-right-radius: 0!important;
}
$num: 0;
@while $num < 11 {
    .rounded-#{$num} {
        border-radius: $num +0px!important;
    }
	$num: $num +1;
}
$num: 15;
@while $num < 51 {
    .rounded-#{$num} {
        border-radius: $num +0px!important;
    }
	$num: $num +5;
}
.circle {
    border-radius: 50%!important;
}
.square {
    border-radius: 0!important;
}
.border-primary,
.border-sky {
	border-color:$sky !important;
}
.border-info, 
.border-cyan {
	border-color:$cyan !important;
}
.border-danger,
.border-red  {
	border-color:$red !important;
}
.border-success,
.border-green {
	border-color:$green !important;
}
.border-warning,
.border-yellow {
	border-color:$yellow !important;
}
.border-pink {
	border-color:$pink !important;
}
.border-purple {
	border-color:$purple !important;
}
.border-violet {
	border-color:$violet !important;
}
.border-indigo {
	border-color:$indigo !important;
}
.border-blue {
	border-color:$blue !important;
}
.border-teal {
	border-color:$teal !important;
}
.border-neon {
	border-color:$neon !important;
}
.border-lime {
	border-color:$lime !important;
}
.border-sun {
	border-color:$sun !important;
}
.border-orange {
	border-color:$orange !important;
}
.border-pumpkin {
	border-color:$pumpkin !important;
}
.border-brown {
	border-color:$brown !important;
}
.border-gold {
	border-color:$gold !important;
}
.border-smoke {
	border-color:$smoke !important;
}
.border-white {
	border-color:$white !important;
}
.border-light {
	border-color:$light !important;
}
.border-light-10 {
	border-color:$light_10 !important;
}
.border-light-15 {
	border-color:$light_15 !important;
}
.border-light-20 {
	border-color:$light_20 !important;
}
.border-light-30 {
	border-color:$light_30 !important;
}
.border-light-40 {
	border-color:$light_40 !important;
}
.border-light-50 {
	border-color:$light_50 !important;
}
.border-light-60 {
	border-color:$light_60 !important;
}
.border-light-70 {
	border-color:$light_70 !important;
}
.border-light-80 {
	border-color:$light_80 !important;
}
.border-light-90 {
	border-color:$light_90 !important;
}
.border-dark {
	border-color:$dark !important;
}
.border-dark-20 {
	border-color:$dark_20 !important;
}
.border-dark-40 {
	border-color:$dark_40 !important;
}
.border-dark-60 {
	border-color:$dark_60 !important;
}
.border-dark-80 {
	border-color:$dark_80 !important;
}
.border-dark-100 {
	border-color:$dark_100 !important;
}
.border-secondary {
	border-color:$grey !important;
}
.subtractive-example > .d-inline-block {
	border:1px solid $light_40;
}
/*Colors*/
.color-palette-wrap {
	.color-wrap {
		padding:15px;
	}
}
/*Shadow*/
.shadow-none {
	box-shadow:0 0 0 rgba(0,0,0,0.1)!important;
}
.shadow-top {
	box-shadow:0 -2px 2px rgba(0,0,0,0.1)!important;
}
.shadow-bottom {
	box-shadow:0 2px 2px rgba(0,0,0,0.1)!important;
}
.shadow-left {
	box-shadow:-2px 0 2px rgba(0,0,0,0.1)!important;
}
.shadow-right {
	box-shadow:2px 0 2px rgba(0,0,0,0.1)!important;
}
.shadow-sm {
	  box-shadow:0 2px 2px rgba(0,0,0,0.1)!important;
	&.shadow-top {
		box-shadow:0 -2px 2px rgba(0,0,0,0.1)!important;
	}
	&.shadow-bottom {
		box-shadow:0 2px 2px rgba(0,0,0,0.1)!important;
	}
	&.shadow-left {
		box-shadow:-2px 0 2px rgba(0,0,0,0.1)!important;
	}
	&.shadow-right {
		box-shadow:2px 0 2px rgba(0,0,0,0.1)!important;
	}
}
.shadow-hover-sm {
	@include transition(box-shadow .3s ease);
	&:hover {
		box-shadow:0 2px 2px rgba(0,0,0,0.1)!important;
		&.shadow-top {
			box-shadow:0 -2px 2px rgba(0,0,0,0.1)!important;
		}
		&.shadow-bottom {
			box-shadow:0 2px 2px rgba(0,0,0,0.1)!important;
		}
		&.shadow-left {
			box-shadow:-2px 0 2px rgba(0,0,0,0.1)!important;
		}
		&.shadow-right {
			box-shadow:2px 0 2px rgba(0,0,0,0.1)!important;
		}
	}	
}
.shadow {
	box-shadow:0 4px 5px rgba(0,0,0,0.1)!important;
	&.shadow-top {
		box-shadow:0 -4px 5px rgba(0,0,0,0.1)!important;
	}
	&.shadow-bottom {
		box-shadow:0 4px 5px rgba(0,0,0,0.1)!important;
	}
	&.shadow-left {
		box-shadow:-4px 0 5px rgba(0,0,0,0.1)!important;
	}
	&.shadow-right {
		box-shadow:4px 0 5px rgba(0,0,0,0.1)!important;
	}
}
.shadow-hover {
	@include transition(box-shadow .3s ease);
	&:hover {
		box-shadow:0 4px 5px rgba(0,0,0,0.1)!important;
		&.shadow-top {
			box-shadow:0 -4px 5px rgba(0,0,0,0.1)!important;
		}
		&.shadow-bottom {
			box-shadow:0 4px 5px rgba(0,0,0,0.1)!important;
		}
		&.shadow-left {
			box-shadow:-4px 0 5px rgba(0,0,0,0.1)!important;
		}
		&.shadow-right {
			box-shadow:4px 0 5px rgba(0,0,0,0.1)!important;
		}
	}	
}
.shadow-lg {
	box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
	&.shadow-top {
		box-shadow:0 -6px 10px rgba(0,0,0,0.1)!important;
	}
	&.shadow-bottom {
		box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
	}
	&.shadow-left {
		box-shadow:-6px 0 10px rgba(0,0,0,0.1)!important;
	}
	&.shadow-right {
		box-shadow:6px 0 10px rgba(0,0,0,0.1)!important;
	}
}
.shadow-hover-lg {
	@include transition(box-shadow .3s ease);
	&:hover {
		box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		&.shadow-top {
			box-shadow:0 -6px 10px rgba(0,0,0,0.1)!important;
		}
		&.shadow-bottom {
			box-shadow:0 6px 10px rgba(0,0,0,0.1)!important;
		}
		&.shadow-left {
			box-shadow:-6px 0 10px rgba(0,0,0,0.1)!important;
		}
		&.shadow-right {
			box-shadow:6px 0 10px rgba(0,0,0,0.1)!important;
		}
	}	
}
.shadow-xl {
	box-shadow:0 8px 10px rgba(0,0,0,0.1)!important;
	&.shadow-top {
		box-shadow:0 -8px 10px rgba(0,0,0,0.1)!important;
	}
	&.shadow-bottom {
		box-shadow:0 8px 10px rgba(0,0,0,0.1)!important;
	}
	&.shadow-left {
		box-shadow:-8px 0 10px rgba(0,0,0,0.1)!important;
	}
	&.shadow-right {
		box-shadow:8px 0 10px rgba(0,0,0,0.1)!important;
	}
}
.shadow-hover-xl {
	@include transition(box-shadow .3s ease);
	&:hover {
		box-shadow:0 8px 10px rgba(0,0,0,0.1)!important;
		&.shadow-top {
			box-shadow:0 -8px 10px rgba(0,0,0,0.1)!important;
		}
		&.shadow-bottom {
			box-shadow:0 8px 10px rgba(0,0,0,0.1)!important;
		}
		&.shadow-left {
			box-shadow:-8px 0 10px rgba(0,0,0,0.1)!important;
		}
		&.shadow-right {
			box-shadow:8px 0 10px rgba(0,0,0,0.1)!important;
		}
	}	
}

/*Font Size*/
$num: 11;//Font Size
@while $num < 51 {
    .font-#{$num} {
        font-size: $num +0px !important;
    }
    $num: $num +1;
}
@media (min-width: 576px) { 
	$num: 11;//Font Size
	@while $num < 51 {
		.font-sm-#{$num} {
			font-size: $num +0px !important;
		}
		$num: $num +1;
	}
}
@media (min-width: 768px) { 
	$num: 11;//Font Size
	@while $num < 51 {
		.font-md-#{$num} {
			font-size: $num +0px !important;
		}
		$num: $num +1;
	}
}
@media (min-width: 992px) { 
	$num: 11;//Font Size
	@while $num < 51 {
		.font-lg-#{$num} {
			font-size: $num +0px !important;
		}
		$num: $num +1;
	}
}
@media (min-width: 1200px) { 
	$num: 11;//Font Size
	@while $num < 51 {
		.font-xl-#{$num} {
			font-size: $num +0px !important;
		}
		$num: $num +1;
	}
}
/*Font Weight*/
$num: 400;//Font Weight
@while $num < 701 {
    .font-weight-#{$num} {
        font-weight: $num +0 !important;
    }
    $num: $num +100;
}

/*Text Case*/
.text-nonecase {
	text-transform:none !important;
}

/*Letter Spacing*/
$num0: 5;
$num1: 0;
@while $num0 < 51 {
    .letter-spacing-#{$num0} {
        letter-spacing: $num1 +0.5px !important;
    }
    $num0: $num0 +5;
    $num1: $num1 +.5;
}

/*Line Height*/
.line-height-0 {
	line-height:0!important;
}
$num0: 10;
$num1: 0.9;
@while $num0 < 21 {
    .line-height-#{$num0} {
        line-height: $num1 +0.1 !important;
    }
    $num0: $num0 +1;
    $num1: $num1 +0.1;
}

/*Width*/
$num: 25;
@while $num < 801 {
    .w-#{$num}p {
        width: $num +0px!important;
    }
	$num: $num +5;
}
@media (min-width: 576px) { 
	$num: 25;
	@while $num < 801 {
		.w-sm-#{$num}p {
			width: $num +0px!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 768px) { 
	$num: 25;
	@while $num < 801 {	
		.w-md-#{$num}p {
			width: $num +0px!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 992px) { 
	$num: 25;
	@while $num < 801 {	
		.w-lg-#{$num}p {
			width: $num +0px!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 1200px) { 
	$num: 25;
	@while $num < 801 {	
		.w-xl-#{$num}p {
			width: $num +0px!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 1400px) { 
	$num: 25;
	@while $num < 801 {	
		.w-xxl-#{$num}p {
			width: $num +0px!important;
		}
		$num: $num +5;
	}
}

$num: 5;
@while $num < 101 {	
	.w-#{$num} {
        width: $num +0%!important;
    }
    $num: $num +5;
}
@media (min-width: 576px) { 
	$num: 5;
	@while $num < 101 {	
		.w-sm-#{$num} {
			width: $num +0%!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 768px) { 
	$num: 5;
	@while $num < 101 {	
		.w-md-#{$num} {
			width: $num +0%!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 992px) { 
	$num: 5;
	@while $num < 101 {	
		.w-lg-#{$num} {
			width: $num +0%!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 1200px) { 
	$num: 5;
	@while $num < 101 {	
		.w-xl-#{$num} {
			width: $num +0%!important;
		}
		$num: $num +5;
	}
}
@media (min-width: 1400px) { 
	$num: 5;
	@while $num < 101 {	
		.w-xxl-#{$num} {
			width: $num +0%!important;
		}
		$num: $num +5;
	}
}
/*Max Width*/
$num: 25;
@while $num < 801 {
    .mw-#{$num}p {
        max-width: $num +0px!important;
    }
	$num: $num +25;
}

/*Min Width*/
.mnw-0 {
	min-width:0!important;
}
$num: 25;
@while $num < 801 {
    .mnw-#{$num}p {
        min-width: $num +0px!important;
    }
	$num: $num +25;
}

/*Height*/
$num: 25;
@while $num < 801 {
    .h-#{$num}p {
        height: $num +0px !important;
    }
    $num: $num +5;
}

$num: 25;
@while $num < 101 {
    .h-#{$num} {
        height: $num +0% !important;
    }
    $num: $num +5;
}

/*Max Height*/
$num: 100;
@while $num < 801 {
    .mh-#{$num}p {
        max-height: $num +0px !important;
    }
    $num: $num +25;
}

/*Min Height*/
$num: 100;
@while $num < 801 {
    .mnh-#{$num}p {
        min-height: $num +0px !important;
    }
    $num: $num +25;
}

/*Hr*/
hr {
	margin-top:20px;
	margin-bottom:20px;
	border-top: 1px solid $light_10;
	&.hr-light {
		border-top: 1px solid $light;
	}
	&.hr-light-10 {
		border-top: 1px solid $light_10;
	}
	&.hr-light-15 {
		border-top: 1px solid $light_15;
	}
	&.hr-light-20 {
		border-top: 1px solid $light_20;
	}
	&.hr-light-30 {
		border-top: 1px solid $light_30;
	}
	&.hr-dark {
		border-top: 1px solid $dark;
	}
	&.hr-dark-20 {
		border-top: 1px solid $dark_20;
	}
	&.hr-dark-40 {
		border-top: 1px solid $dark_40;
	}
	&.hr-white {
		border-top: 1px solid $white;
	}
	&.hr-primary {
		border-top: 1px solid $sky;
	}
	&.hr-success {
		border-top: 1px solid $green;
	}
	&.hr-info {
		border-top: 1px solid $cyan;
	}
	&.hr-warning {
		border-top: 1px solid $yellow;
	}
	&.hr-danger {
		border-top: 1px solid $red;
	}
	&.hr-secondary {
		border-top: 1px solid $grey;
	}
	&.hr-soft-primary {
		border-top: 1px solid $sky_light_4;
	}
	&.hr-soft-success {
		border-top: 1px solid $green_light_4;
	}
	&.hr-soft-info {
		border-top: 1px solid $cyan_light_4;
	}
	&.hr-soft-warning {
		border-top: 1px solid $yellow_light_4;
	}
	&.hr-soft-danger {
		border-top: 1px solid $red_light_4;
	}
	&.hr-soft-secondary {
		border-top: 1px solid $grey_light_4;
	}
	&.hr-2 {
		border-width: 2px!important;
	}
	&.hr-3 {
		border-width: 3px!important;
	}
}

/*Opacity*/
$num0: 10;
$num1: 0.1;
@while $num0 < 101 {
    .opacity-#{$num0} {
        opacity: $num1 +0 !important;
    }
    $num0: $num0 +5;
    $num1: $num1 +0.05;
}

/*Div Sizes*/
$num: 8;
@while $num < 51 {
    .d-#{$num} {
        height: $num +0px !important;
        width: $num +0px !important;
    }
    $num: $num +2;
}
$num: 54;
@while $num < 99 {
    .d-#{$num} {
        height: $num +0px !important;
        width: $num +0px !important;
    }
    $num: $num + 4;
}
$num: 100;
@while $num < 151 {
    .d-#{$num} {
        height: $num +0px !important;
        width: $num +0px !important;
    }
    $num: $num + 10;
}
/*Z-Index*/
$num: 1;
@while $num < 11 {
    .z-index-#{$num} {
        z-index: $num +0 !important;
    }
    $num: $num + 1;
}
/*Loader*/
.preloader-it {
	background: $white;
	position: fixed;
	z-index: 10001;
	height: 100%;
	width: 100%;
	overflow:hidden;
}
.loader-pendulums {
	position: relative;
	top: 50%;
	@include transform (translateY(-50%));
	display: block;
	margin: 0 auto;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-size: 4rem;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	border: 0.1em solid $light_10;
	&:before,&:after {
		content: '';
		width: 1em;
		height: 1em;
		left: -.1em;
		top: -.1em;
		padding: .1em;
		display: block;
		border-radius: 50%;
		position: absolute;
		-webkit-transform-origin: center center;
		transform-origin: center center;
		border: .1em solid;
		border-color: $light_40 transparent transparent transparent;
		-webkit-animation: pendulum infinite 2s cubic-bezier(0.3, 1.65, 0.7, -0.65);
		animation: pendulum infinite 2s cubic-bezier(0.3, 1.65, 0.7, -0.65);
	}
	&:before {
		border-color: $dark transparent transparent transparent;
		-webkit-animation-delay: -1s;
		animation-delay: -1s;
		-webkit-animation-name: pendulum2;
		animation-name: pendulum2;
	}
}

@-webkit-keyframes pendulum {
	from {
		@include transform(rotate(0deg));
	}
	to {
		@include transform(rotate(359deg));
	}
}

@keyframes pendulum {
	from {
		@include transform(rotate(0deg));
	}
	to {
		@include transform(rotate(359deg));
	}
}
@-webkit-keyframes pendulum2 {
	from {
		@include transform(rotate(180deg));
	}
	to {
		@include transform(rotate(520deg));
	}
}
@keyframes pendulum2 {
	from {
		@include transform(rotate(180deg));
	}
	to {
		@include transform(rotate(520deg));
	}
}
/*Scrollbar*/
.slimScrollBar {
	right:0!important;
}
.nicescroll-rails.nicescroll-rails-vr {
    z-index: 999 !important;
}
/*Typography*/
.blockquote {
    font-size: 18px;
	padding: 15px 20px;
	background:$light;
	color:$dark;
	border-left: 3px solid $sky;
	>p {
		margin-bottom:5px;
	}
}
.blockquote-footer {
    font-size: 76%;
}
.mark, mark {
	padding: 3px 6px;	
	background-color: $sun_light_4;	
	color: $dark;	
	border-radius: 3px;	
}
.small, small {
    font-size: 80%;
    font-weight: 400;
}

code {
	background-color: $sky_light_5;	
	border-radius: 3px;	
	padding: 3px 6px;	
	font-size: 87.5%;	
	color: $sky;	
}
ul.list-ul {
	list-style: none;
	counter-reset: li;
	> li {
		padding-left: 30px;
		&:before {
			content: '\2022';
			display: inline-block;
			height: 20px;
			width: 20px;
			text-align: center;
			font-size: 26px;
			margin-left: -20px;
			position: relative;
			left: -10px;
			padding-top: 1px;
			top: 5px;
			line-height: 12px;
		}
	}
}
ol.list-ol {
	list-style: none;
	counter-reset: li;
	> li {
		padding-left: 30px;
		counter-increment: li;
		&:before {
			content: counter(li);
			display: inline-block;
			height: 20px;
			width: 20px;
			background: $light_10;
			text-align: center;
			border-radius: 50%;
			font-size: 12px;
			margin-left: -20px;
			position: relative;
			left: -10px;
			padding: 1px;
			top: -1px;
		}
	}
}
ul.list-icon {
	list-style: none;
	> li {
		padding-left: 30px;
		counter-increment: li;
		.feather-icon {
			display: inline-block;
			height: 20px;
			width: 20px;
			text-align: center;
			margin-left: -20px;
			position: relative;
			left: -10px;
			padding: 1px;
			top: -1px;
			
		}
	}
}
.display-1 {
	font-size: 5rem;
}
.display-2 {
    font-size: 4.5rem;
}
.display-3 {
    font-size: 3.5rem;
}	
.display-4 {
    font-size: 2.5rem;
}	
.display-5 {
    font-size: 2rem;
	font-weight: 300;
	line-height: 1.2;
}	
.display-6 {
    font-size: 1.6rem;
	font-weight: 300;
	line-height: 1.2;
}
@media (max-width: 576px) {
	.display-1 {
		font-size: 3.5rem;
	}	
	.display-2 {
		font-size: 3rem;
	}	
	.display-3 {
		font-size: 2.75rem;
	}	
	.display-4 {
		font-size: 2.25rem;
	}
}
/*Buttons*/
.btn {
	text-transform:capitalize;
	outline:none;
	border-width:2px;
	&:focus,&:active,&:hover {
		box-shadow:none!important;
	}
	&.btn-wth-icon {
		position:relative;
		.feather-icon .feather{
			height:20px;
			width:20px;
		}
		.btn-text {
			padding-left:35px;
		}
		.icon-label {
			background: rgba(0, 0, 0, 0.08) none repeat scroll 0 0;
			position: absolute;
			border-radius:.25rem;
			top: -2px;
			left:-2px;
			bottom:-2px;
			width:40px;
			display: -webkit-inline-box;
			display: -ms-inline-flexbox;
			display: inline-flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			>i,>span{
				font-size:15px;
			}
			
		}
		&.icon-right {
			.icon-label { 
				right: -2px;
				left: auto;
			}
			.btn-text {
				padding-left:0!important;
				padding-right:35px;
			}
		}
		&.icon-wthot-bg {
			.icon-label {
				background:transparent;
				width:auto!important;
				left:.75em;
			}	
			.btn-text {
				padding-left:20px;
			}
			&.icon-right {
				.icon-label {
					right:.75em;
					left:auto!important;
				}
				.btn-text {
					padding-left:0!important;
					padding-right:20px;
				}
			}
		}
		
		&.btn-lg {
			.feather-icon .feather{
				height:24px;
				width:24px;
			}
			.icon-label {
				width:46px;
				border-radius:.25rem;
				>i,>span{
					font-size:18px;
				}
			}
			.btn-text {
				padding-left: 42px;
			}
			&.icon-right {
				.btn-text {
					padding-right:42px;
				}
			}
			&.icon-wthot-bg {
				.icon-label {
					left:1rem;
				}
				.btn-text {
					padding-left:28px;
				}
				&.icon-right {
					.icon-label {
						right:1rem;
					}
					.btn-text {
						padding-right:28px;
					}
				}
			}
		}
		&.btn-sm {
			.feather-icon .feather{
				height:16px;
				width:16px;
			}
			.icon-label {
				width:33px;
				border-radius:.2rem;
				>i,>span{
					font-size:13px;
				}
			}
			.btn-text {
				padding-left: 30px;
			}
			&.icon-right {
				.btn-text {
					padding-right:30px;
				}
			}
			&.icon-wthot-bg {
				.icon-label {
					left:.5rem;
				}
				.btn-text {
					padding-left:18px;
				}
				&.icon-right {
					.icon-label {
						right:.5rem;
					}
					.btn-text {
						padding-right:18px;
					}
				}
			}
		}
		&.btn-xs {
			.feather-icon .feather{
				height:13px;
				width:13px;
			}
			.icon-label {
				width:26px;
				border-radius:.2rem;
				>i,>span{
					font-size:11px;
				}
			}
			.btn-text {
				padding-left: 24px;
			}
			&.icon-right {
				.btn-text {
					padding-right:24px;
				}
			}
			&.icon-wthot-bg {
				.icon-label {
					left:.3rem;
				}
				.btn-text {
					padding-left:15px;
				}
				&.icon-right {
					.icon-label {
						right:.3rem;
					}
					.btn-text {
						padding-right:15px;
					}
				}
			}
		}
	}
	&.btn-rounded {
	  border-radius: 50px;
		.icon-label { 
			border-radius: 50%!important;
		}
	}
	&.btn-xs {
		padding: .15rem .4rem;
		font-size: .75rem;
	}
	&.btn-icon {
		height:40px;
		width:40px;
		padding:0;
		>i,>span{
			font-size:15px;
		}
		.feather-icon .feather{
			height:20px;
			width:20px;
		}
		&.btn-icon-circle {
			border-radius:50%;
		}
		&.btn-icon-only {
			height:auto;
			width:auto;
			border:none;
			padding:0;
			background:transparent!important;
		}
		&.btn-icon-style-1 {
			@include transition(all 400ms);
			&:hover {
				border-radius:50%;
			}
		}
		&.btn-icon-style-2 {
			@include transition(all 400ms);
			&:hover {
				border-radius:0;
			}
		}
		&.btn-icon-style-3 {
			position:relative;
			border:none;
			background:transparent!important;
			color:$dark;
			> i,> span {
				position:relative;
				z-index:1;
			}
			&::before {
				content: "";
				position: absolute;
				top: 50%;
				left: 50%;
				height: 0;
				width: 0;
				background:$sky;
				@include transition(all 400ms);
			}
			&.btn-icon-circle {
				&::before {
					border-radius:50%;
				}
			}
			&:hover {
				&::before {
					top:0;
					left:0;
					height:100%;
					width:100%;
				}
				>i,>span {
					color:$white;
				}
			}
		}
		&.btn-icon-style-4 {
			color:$dark;
			@include transition(color .2s ease-in-out);
			&:hover {
				color:$red;
			}
		}
		&.btn-lg {
			height:50px;
			width:50px;
			>i,>span{
				font-size:18px;
			}
			.feather-icon .feather{
				height:24px;
				width:24px;
			}
		}
		&.btn-sm {
			height:35px;
			width:35px;
			>i,>span{
				font-size:13px;
			}
			.feather-icon .feather{
				height:16px;
				width:16px;
			}
		}
		&.btn-xs {
			height: 25px;
			width: 25px;
			padding-left: 0;
			padding-right: 0;
			>i,>span{
				font-size:11px;
			}
			.feather-icon .feather{
				height:13px;
				width:13px;
			}
		}
	}
}
.btn-icon-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	height:100%;
	width:100%;
	> i, >span {
		line-height:0;
	}
}
.btn-icon-dropdown {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.btn-primary,
.btn-sky {
	color:$white;
	background-color: $sky;
	border-color: $sky;
	&:hover,&:focus {
		color:$white;
		background-color: $sky_dark_1;
		border-color: $sky_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $sky_dark_2;
		border-color: $sky_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $sky_light_3;
		border-color: $sky_light_3;
	}
}
.btn-info,
.btn-cyan {
	color:$white;
	background-color: $cyan;
	border-color: $cyan;
	&:hover,&:focus {
		color:$white;
		background-color: $cyan_dark_1;
		border-color: $cyan_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $cyan_dark_2;
		border-color: $cyan_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $cyan_light_3;
		border-color: $cyan_light_3;
	}
}
.btn-success,
.btn-green {
	color:$white;
	background-color: $green;
	border-color: $green;
	&:hover,&:focus {
		color:$white;
		background-color: $green_dark_1;
		border-color: $green_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $green_dark_2;
		border-color: $green_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $green_light_3;
		border-color: $green_light_3;
	}
}
.btn-warning,
.btn-yellow {
	color:$white;
	background-color: $yellow;
	border-color: $yellow;
	&:hover,&:focus {
		color:$white;
		background-color: $yellow_dark_1;
		border-color: $yellow_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $yellow_dark_2;
		border-color: $yellow_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $yellow_light_3;
		border-color: $yellow_light_3;
	}
}
.btn-danger,
.btn-red {
	color:$white;
	background-color: $red;
	border-color: $red;
	&:hover,&:focus {
		color:$white;
		background-color: $red_dark_1;
		border-color: $red_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $red_dark_2;
		border-color: $red_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $red_light_3;
		border-color: $red_light_3;
	}
}
.btn-secondary,
.btn-grey {
	color:$white;
	background-color: $grey;
	border-color: $grey;
	&:hover,&:focus {
		color:$white;
		background-color: $grey_dark_1;
		border-color: $grey_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $grey_dark_2;
		border-color: $grey_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $grey_light_3;
		border-color: $grey_light_3;
	}
}
.btn-pink {
	color:$white;
	background-color: $pink;
	border-color: $pink;
	&:hover,&:focus {
		color:$white;
		background-color: $pink_dark_1;
		border-color: $pink_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $pink_dark_2;
		border-color: $pink_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $pink_light_3;
		border-color: $pink_light_3;
	}
}
.btn-purple {
	color:$white;
	background-color: $purple;
	border-color: $purple;
	&:hover,&:focus {
		color:$white;
		background-color: $purple_dark_1;
		border-color: $purple_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $purple_dark_2;
		border-color: $purple_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $purple_light_3;
		border-color: $purple_light_3;
	}
}
.btn-violet {
	color:$white;
	background-color: $violet;
	border-color: $violet;
	&:hover,&:focus {
		color:$white;
		background-color: $violet_dark_1;
		border-color: $violet_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $violet_dark_2;
		border-color: $violet_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $violet_light_3;
		border-color: $violet_light_3;
	}
}
.btn-indigo {
	color:$white;
	background-color: $indigo;
	border-color: $indigo;
	&:hover,&:focus {
		color:$white;
		background-color: $indigo_dark_1;
		border-color: $indigo_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $indigo_dark_2;
		border-color: $indigo_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $indigo_light_3;
		border-color: $indigo_light_3;
	}
}
.btn-blue {
	color:$white;
	background-color: $blue;
	border-color: $blue;
	&:hover,&:focus {
		color:$white;
		background-color: $blue_dark_1;
		border-color: $blue_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $blue_dark_2;
		border-color: $blue_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $blue_light_3;
		border-color: $blue_light_3;
	}
}
.btn-teal {
	color:$white;
	background-color: $teal;
	border-color: $teal;
	&:hover,&:focus {
		color:$white;
		background-color: $teal_dark_1;
		border-color: $teal_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $teal_dark_2;
		border-color: $teal_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $teal_light_3;
		border-color: $teal_light_3;
	}
}
.btn-neon {
	color:$white;
	background-color: $neon;
	border-color: $neon;
	&:hover,&:focus {
		color:$white;
		background-color: $neon_dark_1;
		border-color: $neon_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $neon_dark_2;
		border-color: $neon_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $neon_light_3;
		border-color: $neon_light_3;
	}
}
.btn-lime {
	color:$dark;
	background-color: $lime;
	border-color: $lime;
	&:hover,&:focus {
		color:$dark;
		background-color: $lime_dark_1;
		border-color: $lime_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$dark;
		background-color: $lime_dark_2;
		border-color: $lime_dark_2;
	}
	&.disabled,&:disabled {
		color:$dark;
		background-color: $lime_light_3;
		border-color: $lime_light_3;
	}
}
.btn-sun {
	color:$dark;
	background-color: $sun;
	border-color: $sun;
	&:hover,&:focus {
		color:$dark;
		background-color: $sun_dark_1;
		border-color: $sun_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$dark;
		background-color: $sun_dark_2;
		border-color: $sun_dark_2;
	}
	&.disabled,&:disabled {
		color:$dark;
		background-color: $sun_light_3;
		border-color: $sun_light_3;
	}
}
.btn-orange {
	color:$white;
	background-color: $orange;
	border-color: $orange;
	&:hover,&:focus {
		color:$white;
		background-color: $orange_dark_1;
		border-color: $orange_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $orange_dark_2;
		border-color: $orange_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $orange_light_3;
		border-color: $orange_light_3;
	}
}
.btn-pumpkin {
	color:$white;
	background-color: $pumpkin;
	border-color: $pumpkin;
	&:hover,&:focus {
		color:$white;
		background-color: $pumpkin_dark_1;
		border-color: $pumpkin_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $pumpkin_dark_2;
		border-color: $pumpkin_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $pumpkin_light_3;
		border-color: $pumpkin_light_3;
	}
}
.btn-brown {
	color:$white;
	background-color: $brown;
	border-color: $brown;
	&:hover,&:focus {
		color:$white;
		background-color: $brown_dark_1;
		border-color: $brown_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $brown_dark_2;
		border-color: $brown_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $brown_light_3;
		border-color: $brown_light_3;
	}
}
.btn-gold {
	color:$white;
	background-color: $gold;
	border-color: $gold;
	&:hover,&:focus {
		color:$white;
		background-color: $gold_dark_1;
		border-color: $gold_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $gold_dark_2;
		border-color: $gold_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $gold_light_3;
		border-color: $gold_light_3;
	}
}
.btn-smoke {
	color:$white;
	background-color: $smoke;
	border-color: $smoke;
	&:hover,&:focus {
		color:$white;
		background-color: $smoke_dark_1;
		border-color: $smoke_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $smoke_dark_2;
		border-color: $smoke_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $smoke_light_3;
		border-color: $smoke_light_3;
	}
}
.btn-dark {
	color:$white;
	background-color: $dark;
	border-color: $dark;
	&:hover,&:focus {
		color:$white;
		background-color: $dark_20;
		border-color: $dark_20;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $dark_40;
		border-color: $dark_40;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $light_40;
		border-color: $light_40;
	}
}
.btn-light {
	color:$dark;
	background-color: $light_10;
	border-color: $light_10;
	&:hover,&:focus {
		color:$dark;
		background-color: $light_20;
		border-color: $light_20;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$dark;
		background-color: $light_30;
		border-color: $light_30;
	}
	&.disabled,&:disabled {
		color:$dark;
		background-color: $light;
		border-color: $light;
	}
}

.btn-gradient-primary {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$sky,$violet);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$sky_dark_1,$violet_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$sky_dark_2,$violet_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$sky_light_3,$violet_light_3);
	}
}
.btn-gradient-info {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$cyan,$indigo);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$cyan_dark_1,$indigo_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$cyan_dark_2,$indigo_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$cyan_light_3,$indigo_light_3);
	}
}
.btn-gradient-success {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$green,$lime);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$green_dark_1,$lime_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$green_dark_2,$lime_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$green_light_3,$lime_light_3);
	}
}
.btn-gradient-warning {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$yellow,$pumpkin);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$yellow_dark_1,$pumpkin_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$yellow_dark_2,$pumpkin_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$yellow_light_3,$pumpkin_light_3);
	}
}
.btn-gradient-danger {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$red,$purple);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$red_dark_1,$purple_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$red_dark_2,$purple_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$red_light_3,$purple_light_3);
	}
}
.btn-gradient-secondary {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$grey,$smoke);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$grey_dark_1,$smoke_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$grey_dark_2,$smoke_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$grey_light_3,$smoke_light_3);
	}
}
.btn-gradient-light {
	color:$dark;
	border:none;
	@include bg-linear-gradient(45deg,$light,$grey);
	&:hover,&:focus {
		color:$dark;
		@include bg-linear-gradient(45deg,$light_15,$grey_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$dark;
		@include bg-linear-gradient(45deg,$light_30,$grey_dark_2);
	}
	&.disabled,&:disabled {
		color:$dark;
		@include bg-linear-gradient(45deg,$light,$grey_light_3);
	}
}
.btn-gradient-dark {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$dark,$brown);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$dark_20,$brown_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$dark_40,$brown_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$dark,$brown_light_3);
	}
}
.btn-gradient-pony {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$pink,$cyan);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$pink_dark_1,$cyan_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$pink_dark_2,$cyan_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$pink_light_3,$cyan_light_3);
	}
}
.btn-gradient-space {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$purple,$sun);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$purple_dark_1,$sun_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$purple_dark_2,$sun_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$purple_light_3,$sun_light_3);
	}
}
.btn-gradient-streaks {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$violet,$teal);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$violet_dark_1,$teal_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$violet_dark_2,$teal_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$violet_light_3,$teal_light_3);
	}
}
.btn-gradient-bunting {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$indigo,$brown);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$indigo_dark_1,$brown_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$indigo_dark_2,$brown_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$indigo_light_3,$brown_light_3);
	}
}
.btn-gradient-paradise {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$blue,$neon);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$blue_dark_1,$neon_dark_1);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$blue_dark_2,$neon_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$blue_light_3,$neon_light_3);
	}
}
.btn-gradient-heaven {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$neon,$sky);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$neon_dark_1,$sky_dark_1,);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$neon_dark_2,$sky_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$neon_light_3,$sky_light_3);
	}
}
.btn-gradient-honey {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$lime,$orange);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$lime_dark_1,$orange_dark_1,);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$lime_dark_2,$orange_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$lime_light_3,$orange_light_3);
	}
}
.btn-gradient-warbler {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$sun,$grey);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$sun_dark_1,$grey_dark_1,);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$sun_dark_2,$grey_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$sun_light_3,$grey_light_3);
	}
}
.btn-gradient-dusk {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$orange,$violet);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$orange_dark_1,$violet_dark_1,);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$orange_dark_2,$violet_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$orange_light_3,$violet_light_3);
	}
}
.btn-gradient-citrine {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$pumpkin,$brown);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$pumpkin_dark_1,$brown_dark_1,);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$pumpkin_dark_2,$brown_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$pumpkin_light_3,$brown_light_3);
	}
}
.btn-gradient-royston {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$brown,$teal);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$brown_dark_1,$teal_dark_1,);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$brown_dark_2,$teal_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$brown_light_3,$teal_light_3);
	}
}
.btn-gradient-ashes {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$smoke,$dark);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$smoke_dark_1,$dark_20,);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$smoke_dark_2,$dark_40);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$smoke_light_3,$dark);
	}
}
.btn-gradient-metal {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$gold,$grey);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$gold_dark_1,$grey_dark_1,);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$gold_dark_2,$grey_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$gold_light_3,$grey_light_3);
	}
}
.btn-gradient-sunset {
	color:$white;
	border:none;
	@include bg-linear-gradient(45deg,$teal,$pumpkin);
	&:hover,&:focus {
		color:$white;
		@include bg-linear-gradient(45deg,$teal_dark_1,$pumpkin_dark_1,);
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		@include bg-linear-gradient(45deg,$teal_dark_2,$pumpkin_dark_2);
	}
	&.disabled,&:disabled {
		color:$white;
		@include bg-linear-gradient(45deg,$teal_light_3,$pumpkin_light_3);
	}
}

.btn-outline-primary,
.btn-outline-primary,
.btn-outline-sky {
	color:$sky;
	background-color: transparent;
    background-image: none;
	border-color: $sky;
	&:hover,&:focus {
		color:$white;
		background-color: $sky_dark_1;
		border-color: $sky_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $sky_dark_2;
		border-color: $sky_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $sky_light_3;
		border-color: $sky_light_3;
	}
}
.btn-outline-info,
.btn-outline-cyan {
	color:$cyan;
	background-color: transparent;
    background-image: none;
	border-color: $cyan;
	&:hover,&:focus {
		color:$white;
		background-color: $cyan_dark_1;
		border-color: $cyan_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $cyan_dark_2;
		border-color: $cyan_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $cyan_light_3;
		border-color: $cyan_light_3;
	}
}
.btn-outline-success,
.btn-outline-green {
	color:$green;
	background-color: transparent;
    background-image: none;
	border-color: $green;
	&:hover,&:focus {
		color:$white;
		background-color: $green_dark_1;
		border-color: $green_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $green_dark_2;
		border-color: $green_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $green_light_3;
		border-color: $green_light_3;
	}
}
.btn-outline-warning,
.btn-outline-yellow {
	color:$yellow;
	background-color: transparent;
    background-image: none;
	border-color: $yellow;
	&:hover,&:focus {
		color:$white;
		background-color: $yellow_dark_1;
		border-color: $yellow_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $yellow_dark_2;
		border-color: $yellow_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $yellow_light_3;
		border-color: $yellow_light_3;
	}
}
.btn-outline-danger,
.btn-outline-red {
	color:$red;
	background-color: transparent;
    background-image: none;
	border-color: $red;
	&:hover,&:focus {
		color:$white;
		background-color: $red_dark_1;
		border-color: $red_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $red_dark_2;
		border-color: $red_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $red_light_3;
		border-color: $red_light_3;
	}
}
.btn-outline-secondary,
.btn-outline-grey {
	color:$grey;
	background-color: transparent;
    background-image: none;
	border-color: $grey;
	&:hover,&:focus {
		color:$white;
		background-color: $grey_dark_1;
		border-color: $grey_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $grey_dark_2;
		border-color: $grey_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $grey_light_3;
		border-color: $grey_light_3;
	}
}
.btn-outline-pink {
	color:$pink;
	background-color: transparent;
    background-image: none;
	border-color: $pink;
	&:hover,&:focus {
		color:$white;
		background-color: $pink_dark_1;
		border-color: $pink_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $pink_dark_2;
		border-color: $pink_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $pink_light_3;
		border-color: $pink_light_3;
	}
}
.btn-outline-purple {
	color:$purple;
	background-color: transparent;
    background-image: none;
	border-color: $purple;
	&:hover,&:focus {
		color:$white;
		background-color: $purple_dark_1;
		border-color: $purple_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $purple_dark_2;
		border-color: $purple_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $purple_light_3;
		border-color: $purple_light_3;
	}
}
.btn-outline-violet {
	color:$violet;
	background-color: transparent;
    background-image: none;
	border-color: $violet;
	&:hover,&:focus {
		color:$white;
		background-color: $violet_dark_1;
		border-color: $violet_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $violet_dark_2;
		border-color: $violet_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $violet_light_3;
		border-color: $violet_light_3;
	}
}
.btn-outline-indigo {
	color:$indigo;
	background-color: transparent;
    background-image: none;
	border-color: $indigo;
	&:hover,&:focus {
		color:$white;
		background-color: $indigo_dark_1;
		border-color: $indigo_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $indigo_dark_2;
		border-color: $indigo_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $indigo_light_3;
		border-color: $indigo_light_3;
	}
}
.btn-outline-blue {
	color:$blue;
	background-color: transparent;
    background-image: none;
	border-color: $blue;
	&:hover,&:focus {
		color:$white;
		background-color: $blue_dark_1;
		border-color: $blue_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $blue_dark_2;
		border-color: $blue_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $blue_light_3;
		border-color: $blue_light_3;
	}
}
.btn-outline-teal {
	color:$teal;
	background-color: transparent;
    background-image: none;
	border-color: $teal;
	&:hover,&:focus {
		color:$white;
		background-color: $teal_dark_1;
		border-color: $teal_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $teal_dark_2;
		border-color: $teal_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $teal_light_3;
		border-color: $teal_light_3;
	}
}
.btn-outline-neon {
	color:$neon;
	background-color: transparent;
    background-image: none;
	border-color: $neon;
	&:hover,&:focus {
		color:$white;
		background-color: $neon_dark_1;
		border-color: $neon_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $neon_dark_2;
		border-color: $neon_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $neon_light_3;
		border-color: $neon_light_3;
	}
}
.btn-outline-lime {
	color:$lime;
	background-color: transparent;
    background-image: none;
	border-color: $lime;
	&:hover,&:focus {
		color:$dark;
		background-color: $lime_dark_1;
		border-color: $lime_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$dark;
		background-color: $lime_dark_2;
		border-color: $lime_dark_2;
	}
	&.disabled,&:disabled {
		color:$dark;
		background-color: $lime_light_3;
		border-color: $lime_light_3;
	}
}
.btn-outline-sun {
	color:$sun;
	background-color: transparent;
    background-image: none;
	border-color: $sun;
	&:hover,&:focus {
		color:$dark;
		background-color: $sun_dark_1;
		border-color: $sun_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$dark;
		background-color: $sun_dark_2;
		border-color: $sun_dark_2;
	}
	&.disabled,&:disabled {
		color:$dark;
		background-color: $sun_light_3;
		border-color: $sun_light_3;
	}
}
.btn-outline-orange {
	color:$orange;
	background-color: transparent;
    background-image: none;
	border-color: $orange;
	&:hover,&:focus {
		color:$white;
		background-color: $orange_dark_1;
		border-color: $orange_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $orange_dark_2;
		border-color: $orange_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $orange_light_3;
		border-color: $orange_light_3;
	}
}
.btn-outline-pumpkin {
	color:$pumpkin;
	background-color: transparent;
    background-image: none;
	border-color: $pumpkin;
	&:hover,&:focus {
		color:$white;
		background-color: $pumpkin_dark_1;
		border-color: $pumpkin_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $pumpkin_dark_2;
		border-color: $pumpkin_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $pumpkin_light_3;
		border-color: $pumpkin_light_3;
	}
}
.btn-outline-brown {
	color:$brown;
	background-color: transparent;
    background-image: none;
	border-color: $brown;
	&:hover,&:focus {
		color:$white;
		background-color: $brown_dark_1;
		border-color: $brown_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $brown_dark_2;
		border-color: $brown_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $brown_light_3;
		border-color: $brown_light_3;
	}
}
.btn-outline-gold {
	color:$gold;
	background-color: transparent;
    background-image: none;
	border-color: $gold;
	&:hover,&:focus {
		color:$white;
		background-color: $gold_dark_1;
		border-color: $gold_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $gold_dark_2;
		border-color: $gold_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $gold_light_3;
		border-color: $gold_light_3;
	}
}
.btn-outline-smoke {
	color:$smoke;
	background-color: transparent;
    background-image: none;
	border-color: $smoke;
	&:hover,&:focus {
		color:$white;
		background-color: $smoke_dark_1;
		border-color: $smoke_dark_1;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $smoke_dark_2;
		border-color: $smoke_dark_2;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $smoke_light_3;
		border-color: $smoke_light_3;
	}
}
.btn-outline-dark {
	color:$dark;
	background-color: transparent;
    background-image: none;
	border-color: $dark;
	&:hover,&:focus {
		color:$white;
		background-color: $dark_20;
		border-color: $dark_20;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$white;
		background-color: $dark_40;
		border-color: $dark_40;
	}
	&.disabled,&:disabled {
		color:$white;
		background-color: $light_40;
		border-color: $light_40;
	}
}
.btn-outline-light {
	color:$dark;
	background-color: transparent;
    background-image: none;
	border-color: $light_10;
	&:hover,&:focus {
		color:$dark;
		background-color: $light_20;
		border-color: $light_20;
	}
	&:not(:disabled):not(.disabled).active,
	&:not(:disabled):not(.disabled):active {
		color:$dark;
		background-color: $light_30;
		border-color: $light_30;
	}
	&.disabled,&:disabled {
		color:$dark;
		background-color: $light;
		border-color: $light;
	}
}

.show {
	> {
		.btn-primary,.btn-sky,
		.btn-outline-primary,.btn-outline-sky {
			&.dropdown-toggle {
				color:$white;
				background-color: $sky_dark_2;
				border-color: $sky_dark_2;
			}	
		}
		.btn-info,.btn-cyan,
		.btn-outline-info,.btn-outline-cyan {
			&.dropdown-toggle {
				color:$white;
				background-color: $cyan_dark_2;
				border-color: $cyan_dark_2;
			}
		}
		.btn-success,.btn-green,
		.btn-outline-success,.btn-outline-green {
			&.dropdown-toggle {
				color:$white;
				background-color: $green_dark_2;
				border-color: $green_dark_2;
			}
		}
		.btn-warning,.btn-yellow,
		.btn-outline-warning,.btn-outline-yellow {
			&.dropdown-toggle {
				color:$white;
				background-color: $yellow_dark_2;
				border-color: $yellow_dark_2;
			}
		}
		.btn-danger,.btn-red,
		.btn-outline-danger,.btn-outline-red {
			&.dropdown-toggle {
				color:$white;
				background-color: $red_dark_2;
				border-color: $red_dark_2;
			}
		}
		.btn-secondary,.btn-grey,
		.btn-outline-secondary,.btn-outline-grey {
			&.dropdown-toggle {
				color:$white;
				background-color: $grey_dark_2;
				border-color: $grey_dark_2;
			}
		}
		.btn-grey,
		.btn-outline-grey {
			&.dropdown-toggle {
				color:$white;
				background-color: $grey_dark_2;
				border-color: $grey_dark_2;
			}
		}
		.btn-pink,
		.btn-outline-pink {
			&.dropdown-toggle {
				color:$white;
				background-color: $pink_dark_2;
				border-color: $pink_dark_2;
			}
		}
		.btn-purple,
		.btn-outline-purple {
			&.dropdown-toggle {
				color:$white;
				background-color: $purple_dark_2;
				border-color: $purple_dark_2;
			}
		}
		.btn-violet,
		.btn-outline-violet {
			&.dropdown-toggle {
				color:$white;
				background-color: $violet_dark_2;
				border-color: $violet_dark_2;
			}
		}
		.btn-indigo,
		.btn-outline-indigo {
			&.dropdown-toggle {
				color:$white;
				background-color: $indigo_dark_2;
				border-color: $indigo_dark_2;
			}
		}
		.btn-blue,
		.btn-outline-blue {
			&.dropdown-toggle {
				color:$white;
				background-color: $blue_dark_2;
				border-color: $blue_dark_2;
			}
		}
		.btn-teal,
		.btn-outline-teal {
			&.dropdown-toggle {
				color:$white;
				background-color: $teal_dark_2;
				border-color: $teal_dark_2;
			}
		}
		.btn-neon,
		.btn-outline-neon {
			&.dropdown-toggle {
				color:$white;
				background-color: $neon_dark_2;
				border-color: $neon_dark_2;
			}
		}
		.btn-lime,
		.btn-outline-lime {
			&.dropdown-toggle {
				color:$dark;
				background-color: $lime_dark_2;
				border-color: $lime_dark_2;
			}
		}
		.btn-sun,
		.btn-outline-sun {
			&.dropdown-toggle {
				color:$dark;
				background-color: $sun_dark_2;
				border-color: $sun_dark_2;
			}
		}
		.btn-orange,
		.btn-outline-orange {
			&.dropdown-toggle {
				color:$white;
				background-color: $orange_dark_2;
				border-color: $orange_dark_2;
			}
		}
		.btn-pumpkin,
		.btn-outline-pumpkin {
			&.dropdown-toggle {
				color:$white;
				background-color: $pumpkin_dark_2;
				border-color: $pumpkin_dark_2;
			}
		}
		.btn-brown,
		.btn-outline-brown {
			&.dropdown-toggle {
				color:$white;
				background-color: $brown_dark_2;
				border-color: $brown_dark_2;
			}
		}
		.btn-gold,
		.btn-outline-gold {
			&.dropdown-toggle {
				color:$white;
				background-color: $gold_dark_2;
				border-color: $gold_dark_2;
			}
		}
		.btn-smoke,
		.btn-outline-smoke {
			&.dropdown-toggle {
				color:$white;
				background-color: $smoke_dark_2;
				border-color: $smoke_dark_2;
			}
		}
		.btn-light,
		.btn-outline-light {
			&.dropdown-toggle {
				color:$dark;
				background-color: $light_30;
				border-color: $light_30;
			}
		}
		.btn-dark,
		.btn-outline-dark {
			&.dropdown-toggle {
				color:$white;
				background-color: $dark_40;
				border-color: $dark_40;
			}
		}
	}		
}
.btn-group .btn + .btn, .btn-group .btn + .btn-group, .btn-group .btn-group + .btn, .btn-group .btn-group + .btn-group {
    margin-left: -2px;
}
.btn-group-vertical > .btn + .btn, .btn-group-vertical > .btn + .btn-group, .btn-group-vertical > .btn-group + .btn, .btn-group-vertical > .btn-group + .btn-group {
    margin-top: -2px;
}	
.btn-group {
	&.btn-group-rounded {
		.btn {
			&:first-child {
				border-top-left-radius:50px;
				border-bottom-left-radius:50px;
			}
			&:last-child {
				border-top-right-radius:50px;
				border-bottom-right-radius:50px;
			}
		}
	}
}
.btn-group-lg > .btn, .btn-lg {
	font-size: 1.1rem;
	border-radius:0.25rem;
}
.btn-group-xs > .btn, .btn-xs {
	font-size: 1.1rem;
	border-radius:0.2rem;
	padding: .05rem .3rem;
	font-size: .85rem;
}

.button-list {
	>.btn,>.btn-group {
		margin-top:15px;
		margin-right:10px;
	}
}
button.close {
	outline:none;
}
/*Wrapper*/
.hk-wrapper  {
	background:$smoke_light_5;
	position:relative;
}

.hk-pg-wrapper {
	background:$light;	
	padding:57px 50px 60px;
	position:relative;
	.hk-pg-header {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: space-between;
		-webkit-justify-content: space-between;
		-ms-flex-pack: space-between;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-bottom:25px;
		.hk-pg-title {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			.pg-title-icon {
				padding-right:10px;
				color:$light_30;
				position: relative;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
			}
		}
		>div {
			&:first-child,&:last-child  {
				margin-bottom:15px;
			}
		}
	}
}

/*Navigation*/
@import "navbar.scss";

/*Grid*/
.hk-row {
   display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}
.hk-row {
	> .col,
	> [class*="col-"] {
		padding-right: 10px;
		padding-left: 10px;
	}
}
/*Section*/
.hk-sec-wrapper {
	background:$white;
	padding:1.5rem;
	border-radius: .25rem;
	margin-bottom:14px;
	.hk-sec-title {
		display: block;
		margin-bottom:10px;
	}
	.hk-sec-label {
		font-size: 14px;
		display: block;
		text-transform: uppercase;
	}
}
@media (max-width: 1400px) {
	.hk-sec-wrapper {
		padding-left:1.25rem;
		padding-right:1.25rem;
	}
}
@media (max-width: 1600px) {
	.hk-pg-wrapper {
		padding-left:0;
		padding-right:0;
	}
}
@media (max-width: 767px) {
	.hk-pg-wrapper {
		padding-bottom:85px;
	}
}
@media (max-width: 575px) {
	.hk-sec-wrapper {
		padding-left: 1rem;
		padding-right: 1rem;
	} 
}	
/*Card*/
.card {
	border:none;
	box-shadow:none;
	margin-bottom:20px;
	.card-header {
		background:transparent;
		border:none;
		&.card-header-action {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			-ms-flex-pack: space-between;
		}
	}
	.card-action-wrap {
		a {
			color:$light_70;
			@include transition(.2s ease);
			&[data-toggle="collapse"] {
				i,span {
					font-size:24px;
				}
			}
			&.collapsed  {
				span,i {
					&.zmdi-chevron-down {
						&:before {
							content: '\f2fc';
						}	
					}
				}
			}
			&.card-close {
				i,span {
					font-size:20px;
				}
			}
			&.full-screen {
				i,span {
					font-size:18px;
				}
			}
			&.dropdown-toggle {
				i,span {
					font-size:23px;
				}
			}
			&:hover {
				color:$dark;
			}
		}
	}
	.card-text {
		margin-bottom:15px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.card-footer {
		background:transparent;
		border-color:$light_10;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.card-img-bg {
		overflow: hidden;
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
	}
	.card-img-overlay {
		position:static;
	}
	&.card-refresh {
		position:relative;
		.refresh-container {	
			background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			display:none;
			z-index: 5;
			cursor:wait;
			overflow:hidden;
			.loader-pendulums {
				font-size:2rem;
			}
		}
		> .card-header {
			.card-action-wrap {
				> a.refresh{
					span,i {
						font-size:21px;
					}
				}
			}
		}		
	}
	&.fullscreen {
		height: 100%;
		left: 0;
		margin: 0;
		position: fixed;
		overflow-y: auto;
		top: 0;
		width: 100%;
		z-index: 1040;
		> .card-header {
			.card-action-wrap {
				> a,.dropdown {
					opacity:0;
					visibility:hidden;
					&.full-screen {
						opacity:1;
						visibility:visible;
						i {
							&.ion-md-expand::before {
								content:"\f2d8";
							}
						}	
					}
				}
			}
		}
	}
	&.card-lg {
		.card-header,
		.card-footer {
			padding:.9rem 1.5rem;
		}
		.card-body {
			padding:1.5rem;
		}
	}
	&.card-sm {
		.card-header,
		.card-footer {
			padding:.75rem 1rem;
		}
		.card-body {
			padding:1rem;
		}
	}
	&.card-flush {
		border:0!important;
		box-shadow:none!important;
	}
}
.card-column-1 {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-gap: 0;
    -moz-column-gap: 0;
    column-gap: 0;
}
.card-group > .card {
	margin-bottom:14px;
}
@media (max-width: 576px) { 
	.card.card-lg {
		.card-header,
		.card-footer {
			padding: .75rem 1.25rem;
		}
		.card-body {
			padding: 1.25rem;
		}
	}
}
/*Breadcrumb*/
.hk-breadcrumb {
	.breadcrumb {
		border-bottom: 1px solid;
		border-radius: 0;
		padding:12px 15px;
		margin-left: -50px;
		margin-right: -50px;
		margin-bottom:2rem;
		@include border-opacity($dark,0.05);
		.breadcrumb-item {
			font-size:14px;
		}
		&.breadcrumb-light {
			background:$white;
			.breadcrumb-item {
				> a {
					@include color-opacity($dark,.5);
					&:hover,&:focus {
						@include color-opacity($dark,.7);
					}
				}
				&.active {
					@include color-opacity($dark);
				}
			}
			.breadcrumb-item + .breadcrumb-item {
				&:before { 
					@include color-opacity($dark,.2);
				}
			}
		}
		&.breadcrumb-dark {
			background:$dark;
			.breadcrumb-item {
				> a {
					@include color-opacity($white,.5);
					&:hover,&:focus {
						@include color-opacity($white,.7);
					}
				}
				&.active {
					@include color-opacity($white);
				}
			}
			.breadcrumb-item + .breadcrumb-item {
				&:before { 
					@include color-opacity($white,.2);
				}
			}
		}
	}
	.breadcrumb-item + .breadcrumb-item {
		padding-left: .3rem; 
		&:before {
			font-family: "dripicons-v2" !important;
			content: "V";
			font-style: normal !important;
			font-weight: normal !important;
			font-variant: normal !important;
			text-transform: none !important;
			speak: none;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			font-size:11px;
			padding-right: .3rem;  
			position: relative;
			top: 1px;
			@include color-opacity($light_30,.8);
		}
	}
}
@media (max-width: 1600px) { 
	.hk-breadcrumb {
		.breadcrumb {
			margin-left:0;
			margin-right:0;
		}
	}	
}
/*Footer*/
.hk-footer-wrap {
	position:absolute;
	left:0;
	right:0;
	bottom: 0;
	.footer {
		p {
			display: block;
			font-size: 13px;
			line-height:60px;
		}
		a {
			margin-left:5px;
		}
		.col-sm-12:nth-child(2) {
			text-align:right;
		}
	}
}	
@media (max-width: 767px) {
	.hk-footer-wrap {
		.footer {
			padding-left: 5px;
			padding-right: 5px;
			margin-left: 0;
			margin-right: 0;
			padding-bottom:15px;
			p {
				display: block;
				font-size: 13px;
				line-height:35px;
			}
			a {
				margin-left:5px;
			}
			.col-sm-12,.col-sm-12:nth-child(2){
				text-align:center;
			}
		}
	}	
}
/*Form*/
.form-control {
	color: $dark;
	border-width: 2px;
	border-color:$light_10;
	padding: .375rem .75rem;
	height: calc(2.25rem + 4px);
	&:focus {
		box-shadow:none;
		border-color:$sky;
	}
	&.square-input {
		border-radius:0;
	}
	&:disabled {
		background-color: $white;
		border-color:$light;
		color:$light_30;
		opacity: 1;
	}
	&[readonly] {
		background-color: $white;
		border-color:$light_20;
	}
	&.filled-input {
		background:$light;
		border:none;
		&:focus {
			background:$light_10;
			border:none;
			box-shadow: none;
		}
		&:disabled {
			@include background-opacity($light,.6);
		}
		&[readonly] {
			background-color: $light_20;
		}
	}
	&.outline-input {
		background:transparent;
	}
	&.transparent-input {
		background:transparent;
		border:none;
		&:focus {
			background:transparent;
			border:none;
			box-shadow: none;
		}
	}
	&.rounded-input {
		border-radius:50px;
	}
	&::-webkit-input-placeholder {
		color:$light_30;
	}
	&:-moz-placeholder {
		color:$light_30;
	}
	&::-moz-placeholder {
		color:$light_30;
	}
	&:-ms-input-placeholder {
		color:$light_30;
	}
}
.form-control-lg,
.custom-select-lg {
    font-size: 1.1rem;
	padding: .5rem 1rem;
	height: calc(2.875rem + 4px);
}
.form-control-sm,
.custom-select-sm {
    font-size: 0.875rem;
	padding: .25rem .5rem;
	height: calc(1.8125rem + 4px);
}
.custom-select {
    padding-right:1.75rem;
}
/*Inut group*/
.input-group-prepend {
    margin-right: -2px;
}
.input-group-append {
    margin-left: -2px;
}
.input-group-append .btn+.btn, .input-group-append .btn+.input-group-text, .input-group-append .input-group-text+.btn, .input-group-append .input-group-text+.input-group-text, .input-group-prepend .btn+.btn, .input-group-prepend .btn+.input-group-text, .input-group-prepend .input-group-text+.btn, .input-group-prepend .input-group-text+.input-group-text {
    margin-left: -2px;
}
.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control {
    margin-left: -2px;
}
.input-group-text { 
	border-color:$light_10;
	background-color: $light;
	border-width: 2px;
}
label {
    margin-bottom: 10px;
	color:$dark;
}
.custom-control-input:disabled ~ .custom-control-label {
    color: $smoke;
}
.custom-file-label {
    color: $dark;
    border: 2px solid $light_10;
	height: calc(2.25rem + 4px);
	&:after {
		border-left:0;
		background:$light;
	}
}
/*Form validation*/
.custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid {
    border-color: $green;
}
.custom-select.is-invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .was-validated .form-control:invalid {
    border-color: $red_light_1;
}
.valid-feedback {
   color: $green;
}
.invalid-feedback {
    color: $red_light_1;
}
.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
    color: $green;
}
.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: $red_light_1;
}
.custom-control-input.is-invalid ~ .custom-control-label::before, .was-validated .custom-control-input:invalid ~ .custom-control-label::before {
    background-color: $red_light_1;
}
.custom-control-input.is-valid ~ .custom-control-label::before, .was-validated .custom-control-input:valid ~ .custom-control-label::before {
    background-color: $green;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before {
    background-color: $green;
}
.custom-control-input.is-invalid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before {
    box-shadow: none;
}
.custom-control-input.is-valid:focus ~ .custom-control-label::before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before {
    box-shadow: none;
}
.custom-select.is-valid:focus, .form-control.is-valid:focus, .was-validated .custom-select:valid:focus, .was-validated .form-control:valid:focus {
    border-color:$green;
    box-shadow: none;
}
.custom-select.is-invalid:focus, .form-control.is-invalid:focus, .was-validated .custom-select:invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: $red_light_1;
	box-shadow: none;
}
.custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: $red_light_1;
}
.custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: $green;
}
.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    box-shadow: none;
	border-color: $green;
}
.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    box-shadow: none;
	border-color: $red_light_1;
}
.valid-tooltip {
	background:$green;
}
.invalid-tooltip {
	background:$red_light_1;
}
/*Select2*/
.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
 .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {

    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

}
.select2-dropdown {
	border-color:$light_10;
}
.select2-container {
	width:100%!important;
}
.select2-container--default {
	.select2-selection--single,
	.select2-selection--multiple {
		display: block;
		width: 100%;
		height: calc(2.25rem + 4px);
		padding: .375rem .75rem;
		font-size: 1rem;
		line-height: 1.5;
		color: $dark;
		background-color: #fff;
		border: 2px solid $light_10;
		border-radius: .25rem;
		transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
		&:focus {
			border-color:$sky;
		}
		.select2-selection__arrow {
			height: calc(2.25rem + 4px);
			top: 0;
			right: 6px;
		}
		.select2-selection__rendered {
			color:$dark;
			line-height: inherit;
		}
	}
	.select2-search--dropdown .select2-search__field {
		border-color: $light_10;
	}
	&.select2-container--focus .select2-selection--multiple {
		border: solid $sky 2px;
	}
	&.select2-container--open {
		.select2-selection--single {
			border-color:$sky;
		}	
		&.select2-container--below {
			.select2-selection--single,
			.select2-selection--multiple {
				border-radius: .25rem;
			}
			
		}
	}
	.select2-results__option--highlighted[aria-selected] {
		background-color: $sky;
	}
	.select2-results__option[aria-selected="true"] {
		background-color: $light;
		color:$dark;
	}
	.select2-selection--multiple {
			height:auto;
			min-height:calc(2.25rem + 4px);
		.select2-selection__choice {
			background-color: $sky;
			border: none;
			color: $white;
			margin-right: 6px;
			margin-top: 6px;
			padding: 1px 7px;
			.select2-selection__choice__remove {
				color: $white;
				font-weight: 600;
				margin-left: 6px;
				float: right;
				font-size: 14px;
				opacity: 0.5; 
				padding-top:1px;
				&:hover {
					opacity:1;
				}
			}
		}	
		.select2-selection__rendered {
			padding: 0 6px;  
		}

	}
}
/*Tinymce*/
.mce-container, .mce-container *, .mce-widget, .mce-widget *, .mce-reset {
	color:$light_50!important;
}
.mce-text {
	font-family:"Roboto", sans-serif!important;
}
.mce-menu-item .mce-caret {
    border-left-color: $light_50!important;
}
div.mce-menu .mce-menu-item-sep, .mce-menu-item-sep:hover {
	border-bottom-color: $light_10!important;
}
.mce-menu { 
	border-bottom-color: $light_10!important;
}
.tinymce-wrap {
	.mce-tinymce {
		box-shadow: none;
	}
	.mce-toolbar .mce-btn-group {
		padding: 3px 0;
	}
	.mce-btn-group {
		.mce-btn {
			margin-left: 3px;
			margin-right: 3px;
		}
		&:not(:first-child) {
			border-left: none;
			padding-left: 0;
			margin-left: 0;
		}	
	}
	.mce-container, .mce-container *, .mce-widget, .mce-widget *, .mce-reset {
		font-family:inherit;
		color:$light_50;
	}
	.mce-panel {
		border-color:$light_10;
		border-radius: 4px;
	}	
	.mce-top-part::before {
		box-shadow: none;
	}
	.mce-btn:hover,.mce-btn.mce-disabled:hover button,
	.mce-menubar .mce-menubtn:hover,.mce-menubar .mce-menubtn:focus {
		border-color: transparent;
		border-radius:3px;
		background:$light;
		color:$dark;
	}
	.mce-btn.mce-active:hover, .mce-btn.mce-active:focus, .mce-btn.mce-active:active {
		background: $light;
		border-color: transparent;
	}
	.mce-btn.mce-active, 
	.mce-btn:active {
		background: $light;
		border-color: transparent;
		button {
			i {
				color: $sky!important;
			}
		}
	}
	.mce-btn.mce-active button, .mce-btn.mce-active:hover button, .mce-btn.mce-active i, .mce-btn.mce-active:hover i {
		color: $sky;
	}
	.mce-btn.mce-active .mce-caret, .mce-btn.mce-active:hover .mce-caret {
		border-top-color: $light_50;
	}
}
.mce-ico {
	font-family: 'tinymce',Arial !important;
	color:$light_80!important;
}	
.mce-menu-item.mce-active.mce-menu-item-normal {
    background: $light!important;
}
.mce-tooltip {
	opacity:1!important;
	filter:alpha(opacity=100)!important;
}
.mce-tooltip-inner {
	font-size:12px!important;
	background-color:$dark!important;
	padding: 0.25rem 0.5rem!important;
	border-radius: 0.25rem;
	color:$white!important;
	font-family:"Roboto", sans-serif!important;
}
.mce-tooltip-arrow {
	border-bottom-color:$dark!important;
}
.mce-menu-item:hover, .mce-menu-item:focus {
    background: $light!important;
}

/*File Upload*/
.btn-file {
	overflow: hidden;
	position: relative;
	vertical-align: middle;
	display:inline-block;
	input {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		opacity: 0;
		filter: alpha(opacity=0);
		font-size: 23px;
		height: 100%;
		width: 100%;
		direction: ltr;
		cursor: pointer;
	}
}
.fileinput-exists .fileinput-new, 
.fileinput-new .fileinput-exists {
	display: none;
}
.fileinput {
	&.input-group > .input-group-append:last-child > .btn.btn-file:not(:last-child):not(.dropdown-toggle), &.input-group > .input-group-append:not(:last-child) > .btn.btn-file, &.input-group > .input-group-prepend > .btn.btn-file {
		border-top-right-radius: .25rem;
		border-bottom-right-radius: .25rem;
	}
	&.fileinput-exists {
		&.input-group > .input-group-append:last-child > .btn.btn-file:not(:last-child):not(.dropdown-toggle), &.input-group > .input-group-append:not(:last-child) > .btn.btn-file, &.input-group > .input-group-prepend > .btn.btn-file {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
}	
.dropzone {
    border: 2px dashed $light_10; 
	color: $dark;
	.dz-message {
		margin: 3em 0;
	}
	.dz-preview {
		.dz-details {
			color: $dark;
		}	
		.dz-filename span {
			background-color: transparent;
		}
		.dz-image {
			border-radius: 4px;
		}	
		&.dz-file-preview .dz-image {
			border-radius: 4px;
		}
	}
}
.dropify-wrapper {
	border: 2px dashed $light_10; 
	font-family: inherit;
	color: $dark;
	.dropify-message span.file-icon {
		font-size: 28px;
		color: $white;
		height: 60px;
		width: 60px;
		background: $sky;
		border-radius: 50%;
		display: block;
		margin: auto;
		position:relative;
		&:before {
			display: inline-block;
			font-family: "Ionicons";
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			text-rendering: auto;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f2c8";
			top: 50%;
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			@include transform(translateY(-50%));
		}
	}
	.dropify-preview .dropify-infos {
		@include background-opacity($dark,.85);
		.dropify-infos-inner p {
			font-weight:500;
			&.dropify-infos-message {
				margin-top: 0px;
				padding-top: 0px;
				opacity:1;
				font-weight:700;
				text-transform:uppercase;
				letter-spacing:0.5px;
				color:$light_40;
				font-size: 11px;
				&:before {
					display:none;
				}
			}
		}
	}
	.dropify-clear {
		border:none;
		font-size: 11px;
		padding: 2px 6px;
		font-weight: 500;
		letter-spacing: 0.5px;
		top: 8px;
		right: 8px;
		&:hover {
			border-radius:4px;
		}
	}
}	
/*Jquery Toggle*/
.toggle-wrap {
	.toggle {
		margin-top:15px;
		margin-right:10px;
	}	
}
.toggle {
	width: 50px;
	height: 25px;
	display:inline-block;
	.toggle-on,.toggle-select .toggle-inner .active {
		background:$sky;
		color: $white;
		text-shadow:none;
		box-shadow:none;
	}
	.toggle-off {
		background:$light_70;
		text-shadow: none;
		color:$white;
	}
	.toggle-slide {
		box-shadow: none;
	}
	.toggle-on {
		+ .toggle-blob {
			background:$white;
			box-shadow: none;
			border: 3px solid $light_70;
		}
		&.active + .toggle-blob  {
			border-color:$sky;
		}
	}
	.toggle-select {
		.toggle-on,.toggle-off {
			background:$light_70;
			color:$white;
		}
	}
	&.toggle-bg-sky,
	&.toggle-bg-primary {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $sky;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$sky;
		}
	}
	&.toggle-bg-cyan,
	&.toggle-bg-info {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $cyan;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$cyan;
		}
	}
	&.toggle-bg-green,
	&.toggle-bg-success {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $green;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$green;
		}
	}
	&.toggle-bg-yellow,
	&.toggle-bg-warning {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $yellow;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$yellow;
		}
	}
	&.toggle-bg-red,
	&.toggle-bg-danger {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $red;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$red;
		}
	}
	&.toggle-bg-pink {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $pink;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$pink;
		}
	}
	&.toggle-bg-purple {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $purple;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$purple;
		}
	}
	&.toggle-bg-violet {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $violet;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$purple;
		}
	}
	&.toggle-bg-indigo {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $indigo;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$indigo;
		}
	}
	&.toggle-bg-blue {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $blue;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$blue;
		}
	}
	&.toggle-bg-teal {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $teal;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$teal;
		}
	}
	&.toggle-bg-neon {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $neon;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$neon;
		}
	}
	&.toggle-bg-lime {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $lime;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$lime;
		}
	}
	&.toggle-bg-sun {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $sun;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$sun;
		}
	}
	&.toggle-bg-orange {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $orange;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$orange;
		}
	}
	&.toggle-bg-pumpkin {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $pumpkin;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$pumpkin;
		}
	}
	&.toggle-bg-brown {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $brown;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$brown;
		}
	}
	&.toggle-bg-gold {
		.toggle-on,.toggle-select .toggle-inner .active {
			background: $gold;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$gold;
		}
	}
	&.toggle-bg-grey,
	&.toggle-bg-secondary,
	&.toggle-bg-muted {
		.toggle-on,.toggle-select .toggle-inner .active {
			background:$grey;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$grey;
		}
	}
	&.toggle-bg-dark {
		.toggle-on,.toggle-select .toggle-inner .active {
			background:$dark;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$dark;
		}
	}
	&.toggle-bg-light {
		.toggle-on,.toggle-select .toggle-inner .active {
			background:$light_60;
			color: $white;
			text-shadow:none;
			box-shadow:none;
		}
		.toggle-select {
			.toggle-on,.toggle-off {
				background:$light_70;
				color:$white;
			}
		}
		.toggle-on.active + .toggle-blob  {
			border-color:$light_60;
		}
	}
	&.toggle-lg {
		width: 64px;
		height: 32px;
		.toggle-on,.toggle-select .toggle-inner .active {
			font-size:12px;
		}
	}
	&.toggle-sm {
		width: 50px;
		height: 20px;
		.toggle-on,.toggle-select .toggle-inner .active {
			font-size:10px;
		}
	}
	&.toggle-simple {
		width: 47px;
		&.toggle-lg {
			width: 58px;
		}
		&.toggle-sm {
			width: 35px;
		}
	}
}

/*Form-Wizard*/
.wizard {
	.wizard-icon-wrap {
		display: table;
		height: 35px;
		width: 35px;
		background:$light_15;
		border-radius: 50%;
		float: left;
		text-align: center;
		position:relative;
		&:before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: $pink;
			border-radius: 50%;
			@include transform(scale(0));
			@include transition(transform .3s ease-in-out);
			z-index:1;
		}
		i {
			display:table-cell;
			vertical-align:middle;
			color:$light_70;
			font-size: 21px;
			position:relative;
			z-index:2;
		}
	}
	.wizard-head-text-wrap {
		display:inline-block;
		margin-left: 10px;
		padding-top: 7px;
		> span {
			display:block;
			font-size: 13px;
			font-weight: 600;
			color: $dark;
			text-transform: uppercase;
		}
		.step-count {
			color:$light_40;
			font-size:13px;
		}
	}
	> .content {
		background: $white;
		border-radius: 0;
		min-height: 100px;
		margin: 0;
		border: none;
		padding-bottom: 10px;
		>.body {
			width: 100%;
			height: auto;
			padding: 30px;
			position: static;
			input {
				border-width:2px;
				border-color:$light_10;
			}
			ul {
				> li {

					display: inherit;

				}
			}	
		}
	}	
	> .actions {
		background:$white;
		padding:10px 30px;
		a,a:hover,a:active {
			display: inline-block;
			font-weight: 400;
			text-align: center;
			white-space: nowrap;
			background:$pink;
			vertical-align: middle;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			border: 1px solid $pink;
			text-transform:capitalize;
			outline:none;
			border-width:2px;
			border-radius: .25rem;
			padding: .15rem .4rem;
			font-size: .75rem;
		}
		.disabled {
			a,a:hover,a:active {
				background:$grey_light_3;
				color:$white;
				border: 1px solid $grey_light_3;
				opacity:.65;
			}
		}
		>ul>li {
			margin: 0 0.3em ;
		}
	}
	> .steps {
		margin-bottom: 20px;
		>ul >li {
			width:auto;
			margin-right:30px;
			margin-bottom:20px;
		}
		a,a:hover,a:active {
			padding:0;
			margin:0;
			overflow:hidden;
		}
		.current {
			a,a:hover,a:active {
				background: transparent;
				color: inherit;
				.wizard-icon-wrap {
					background:transparent;
					&:before {
						@include transform(scale(1));
					}
					i {
						color:$white;
					}
				}
			}
		}
		.done {
			a,a:hover,a:active {
				background: transparent;
				color: inherit;
				overflow: hidden;
				.wizard-icon-wrap {
					background:$teal;
					&:before {
						@include transform(scale(0));
					}
					i {
						color:$white;
					}
				}
			}
		}
		.disabled {
			a,a:hover,a:active {
				background: transparent;
				color: inherit;
			}
		}
	}
}	
@media (max-width: 991px) {
	.wizard .steps ul > li {
		width: 100%;
	}
}	

/*Popover*/
.popover {
	border: 1px solid $light_20;
   border-radius: 3px;
}  
.popover-header {
	color: $dark;
	background-color: $light;
	border-bottom: none;
	padding: 0.65rem .75rem;
}	
.popover-body {
	padding: .65rem .75rem;
	color: inherit;
}   

/*Accordion*/
.accordion {
	.card {
		margin-bottom:0;
		&:first-of-type .card-header:first-child {
			> a { 
				border-top-left-radius: calc(.25rem - 1px);
				border-top-right-radius: calc(.25rem - 1px);
			}
		}
		.card-header {
			padding:0;
			border-width:0;
			&.activestate {
				border-width:1px;
			}
			> a {
				background:$sky;
				color:$white;
				font-weight:500;
				padding:.75rem 1.25rem;
				display: block;
				width:100%;
				text-align:left;
				position:relative;
				@include transition(all 0.2s ease-in-out);
				&::before {
					content: '\f2fc';
					font: normal normal normal 14px/1 'Material-Design-Iconic-Font';
					font-size: 20px;
					text-rendering: auto;
					position: absolute;
					top: 15px;
					right: 20px;
				}
			} 
			>a {
				&.collapsed {
					color:$dark;
					background:transparent;
					&:before {
						content: '\f2f9';
					}	
				}
			}
		}
	}
	&.accordion-info,
	&.accordion-cyan {
		.card .card-header > a {
			background:$cyan;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-primary,
	&.accordion-sky {
		.card .card-header > a {
			background:$sky;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-success,
	&.accordion-green {
		.card .card-header > a {
			background:$green;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-danger,
	&.accordion-red {
		.card .card-header > a {
			background:$red;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-warning,
	&.accordion-yellow {
		.card .card-header > a {
			background:$yellow;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-pink {
		.card .card-header > a {
			background:$pink;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-purple {
		.card .card-header > a {
			background:$purple;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-violet {
		.card .card-header > a {
			background:$violet;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-indigo {
		.card .card-header > a {
			background:$indigo;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-blue {
		.card .card-header > a {
			background:$blue;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-teal {
		.card .card-header > a {
			background:$teal;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-neon {
		.card .card-header > a {
			background:$neon;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-lime {
		.card .card-header > a {
			background:$lime;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-sun {
		.card .card-header > a {
			background:$sun;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-orange {
		.card .card-header > a {
			background:$orange;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-pumpkin {
		.card .card-header > a {
			background:$pumpkin;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-brown {
		.card .card-header > a {
			background:$brown;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-gold {
		.card .card-header > a {
			background:$gold;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-smoke {
		.card .card-header > a {
			background:$smoke;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-secondary,
	&.accordion-grey {
		.card .card-header > a {
			background:$grey;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-light {
		.card .card-header > a {
			background:$light_10;
			color:$dark;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-dark {
		.card .card-header > a {
			background:$dark;
			color:$white;
			&.collapsed {
				color:$dark;
				background:transparent;
			}
		}
	}
	&.accordion-type-2 {
		.card {
			.card-header {
				> a {
					background:transparent;
					color:$sky;
					padding-left:50px;
					&:before {
						display:none;
					}
					&::after {
						display: inline-block;
						font: normal normal normal 14px/1 'Ionicons';
						speak: none;
						text-transform: none;
						line-height: 1;
						-webkit-font-smoothing: antialiased;
						-moz-osx-font-smoothing: grayscale;
						text-rendering: auto;
						position: absolute;
						content: "\f176";
						font-size: 21px;
						top: 15px;
						left: 20px;
					}
				}	
				>a {
					&.collapsed {
						color:$dark;
						&:after {
							content: "\f158";
						}
					}
				}
			}
		}
		&.accordion-info,
		&.accordion-cyan {
			.card .card-header > a {
				color:$cyan;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-primary,
		&.accordion-sky {
			.card .card-header > a {
				color:$sky;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-success,
		&.accordion-green {
			.card .card-header > a {
				color:$green;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-danger,
		&.accordion-red {
			.card .card-header > a {
				color:$red;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-warning,
		&.accordion-yellow {
			.card .card-header > a {
				color:$yellow;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-pink {
			.card .card-header > a {
				color:$pink;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-purple {
			.card .card-header > a {
				color:$purple;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-violet {
			.card .card-header > a {
				color:$violet;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-indigo {
			.card .card-header > a {
				color:$indigo;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-blue {
			.card .card-header > a {
				color:$blue;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-teal {
			.card .card-header > a {
				color:$teal;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-neon {
			.card .card-header > a {
				color:$neon;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-lime {
			.card .card-header > a {
				color:$lime;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-sun {
			.card .card-header > a {
				color:$sun;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-orange {
			.card .card-header > a {
				color:$orange;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-pumpkin {
			.card .card-header > a {
				color:$pumpkin;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-brown {
			.card .card-header > a {
				color:$brown;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-gold {
			.card .card-header > a {
				color:$gold;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-smoke {
			.card .card-header > a {
				color:$smoke;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-secondary,
		&.accordion-grey {
			.card .card-header > a {
				color:$grey;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-light {
			.card .card-header > a {
				color:$light_50;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
		&.accordion-dark {
			.card .card-header > a {
				color:$dark;
				&.collapsed {
					color:$dark;
					background:transparent;
				}
			}
		}
	}
	&.accordion-flush {
		>.card {
			border-left: 0;
			border-right: 0;
			border-radius: 0;
		}
	}
}

/*Table*/
.table {
	thead th {
		border-top: none;
		border-bottom: none;
		vertical-align:middle;
	}
	&.table-dark {
		thead th {
			background:$dark;
		}
	}
	th {
		font-weight:500;
		color:$dark;
		font-size: 14px;
		text-transform: capitalize;
	}
	td,th {
		border-top: 1px solid $light_10;
		vertical-align: middle;
		padding: .75rem 1.25rem;
	}
	&.table-hover tbody tr:hover,
	&.table-striped tbody tr:nth-of-type(2n+1) {
		background-color: $light;
	}
	&.table-bordered {
		border:none;
		td{
			border-color:$light_10;
			border-top:none;
		}
		th {
			border-top:1px solid $light_10;
			border-bottom:1px solid $light_10;
		}
	} 
	&.table-flush {
		td,th {
			border-top: none;
			border-bottom: none;
		}	
	}
	.thead-primary th, 
	.thead-blue th,
	.thead-success th, 
	.thead-green th,
	.thead-info th, 
	.thead-cyan th,
	.thead-warning th, 
	.thead-yellow th,
	.thead-danger th, 
	.thead-red th,
	.thead-secondary th,
	.thead-grey th {
		color: $white;
	}
	
	.thead-primary th, 
	.thead-sky th {
		background-color: $sky;
	}
	.thead-success th, 
	.thead-green th {
		background-color: $green;
	}
	.thead-info th, 
	.thead-cyan th {
		background-color: $cyan;
	}
	.thead-warning th, 
	.thead-yellow th {
		background-color: $yellow;
	}
	.thead-danger th, 
	.thead-red th {
		background-color: $red;
	}
	.thead-secondary th,
	.thead-grey th	{
		background-color: $grey;
	}
	.thead-pink th {
		background-color: $pink;
	}
	.thead-purple th {
		background-color: $purple;
	}
	.thead-violet th {
		background-color: $violet;
	}
	.thead-indigo th {
		background-color: $indigo;
	}
	.thead-blue th {
		background-color: $blue;
	}
	.thead-teal th {
		background-color: $teal;
	}
	.thead-neon th {
		background-color: $neon;
	}
	.thead-lime th {
		background-color: $lime;
	}
	.thead-sun th {
		background-color: $sun;
	}
	.thead-orange th {
		background-color: $orange;
	}
	.thead-pumpkin th {
		background-color: $pumpkin;
	}
	.thead-brown th {
		background-color: $brown;
	}
	.thead-gold th {
		background-color: $gold;
	}
	.thead-smoke th {
		background-color: $smoke;
	}
	.thead-active th {
		background-color: $smoke;
		color:$white;
	}
	.thead-light th {
		background-color: $light_10;
	}
	.thead-dark th {
		background-color: $dark;
		color:$white;
	}
}
.table-lg { 
	td,th {
		padding: 0.75rem 1.5rem;
	}
}
.table-sm { 
	td,th {
		padding: 0.45rem 1.25rem;
	}
	td {
		font-size:15px;
	}
}
.table-primary,
.table-sky {
	background-color:$sky_light_5;
	> td,>th {
		background-color:$sky_light_5;
	}
}
.table-success,
.table-green {
	background-color:$green_light_5;
	> td,>th {
		background-color:$green_light_5;
	}
}
.table-info,
.table-cyan {
	background-color:$cyan_light_5;
	> td,>th {
		background-color:$cyan_light_5;
	}
}
.table-warning ,
.table-yellow {
	background-color:$yellow_light_5;
	> td,>th {
		background-color:$yellow_light_5;
	}
}
.table-danger,
.table-red {
	background-color:$red_light_5;
	> td,>th {
		background-color:$red_light_5;
	}
}
.table-active {
	background-color:$smoke_light_5;
	> td,>th {
		background-color:$smoke_light_5;
	}
}
.table-grey,
.table-secondary {
	background-color:$grey_light_5;
	> td,>th {
		background-color:$grey_light_5;
	}
}
.table-pink {
	background-color:$pink_light_5;
	> td,>th {
		background-color:$pink_light_5;
	}
}
.table-purple {
	background-color:$purple_light_5;
	> td,>th {
		background-color:$purple_light_5;
	}
}
.table-violet {
	background-color:$violet_light_5;
	> td,>th {
		background-color:$violet_light_5;
	}
}
.table-indigo {
	background-color:$indigo_light_5;
	> td,>th {
		background-color:$indigo_light_5;
	}
}
.table-blue {
	background-color:$blue_light_5;
	> td,>th {
		background-color:$blue_light_5;
	}
}.table-teal {
	background-color:$teal_light_5;
	> td,>th {
		background-color:$teal_light_5;
	}
}
.table-neon {
	background-color:$neon_light_5;
	> td,>th {
		background-color:$neon_light_5;
	}
}
.table-lime {
	background-color:$lime_light_5;
	> td,>th {
		background-color:$lime_light_5;
	}
}
.table-orange {
	background-color:$orange_light_5;
	> td,>th {
		background-color:$orange_light_5;
	}
}
.table-pumpkin {
	background-color:$pumpkin_light_5;
	> td,>th {
		background-color:$pumpkin_light_5;
	}
}
.table-brown {
	background-color:$brown_light_5;
	> td,>th {
		background-color:$brown_light_5;
	}
}
.table-gold {
	background-color:$gold_light_5;
	> td,>th {
		background-color:$gold_light_5;
	}
}
.table-smoke {
	background-color:$smoke_light_5;
	> td,>th {
		background-color:$smoke_light_5;
	}
}
.table-light {
	background-color:$light;
	> td,>th {
		background-color:$light;
	}
}
.table-dark {
	background:$dark_20;
	td,th {
		border-top-color: $dark_20;
	}
	th {
		color:$white;
	}
	> td,>th {
		color:$white;
		background:$dark_20;
	}
	&.table.table-bordered {
		td,th {
			border-color: $dark_20;
		}
	}
}
@media (max-width: 1200px) {
	.table-responsive  .table > tbody > tr > td, .table-responsive  .table > tbody > tr > th, .table-responsive  .table > tfoot > tr > td, .table-responsive  .table > tfoot > tr > th, .table-responsive  .table > thead > tr > td, .table-responsive  .table > thead > tr > th {
		white-space:nowrap;
	}
}
/*Data Table*/
table.dataTable {
	box-sizing: border-box;
	th,td {
		box-sizing: border-box;
	}
	thead,tbody{
		th,td {
			padding:.75rem 1.25rem;
		}
	}	
	thead {
		td,th {
			border-bottom:none;
			font-weight:500;
			position:relative;
			&.sorting::before,
			&.sorting_asc::before,
			&.sorting_desc::before,
			&.sorting::after,
			&.sorting_asc::after,
			&.sorting_desc::after {
				display: inline-block;
				font-family: "Ionicons";
				speak: none;
				font-size: 18px;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				text-rendering: auto;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				position: absolute;
				z-index: 10;
				right: 7px;
			}
			&.sorting::before,
			&.sorting_asc::before,
			&.sorting_desc::before,
			&.sorting::after,
			&.sorting_asc::after,
			&.sorting_desc::after {
				@include transform(translateY(0));
				content: "";
				color: $blue;
			}
			&.sorting_asc::before {
				content: "\f11a";
				
			}
			&.sorting_desc::after {
				content: "\f118";
			}
		}
	}
	tfoot {
		td,th {
			border-top:none;
			font-weight:500;
			padding:.75rem 1.25rem;
			background:$light_10;
		}
	}
	&.no-footer {
		border-bottom: none;
	}
	&.row-border,&.display {
		tbody{
			th,td {
				border-top: 1px solid $light_10;
			}
			tr:first-child {
				th,td {
					border-top: 1px solid $light_10;
				}
			}
		}
	}
	&.stripe,&.display {
		tbody tr.odd {
			@include background-opacity($light,.5);
			&.selected {
				background:$dark;
				color:$white;
			}
		}
		
	}
	tbody tr.selected {
		background:$dark;
		color:$white;
	}
	&.display tbody tr.odd,
	&.order-column.stripe tbody tr.odd {
		> .sorting_1 {
			@include background-opacity($light_20,.2);
		}
		&.selected {
			> .sorting_1 {
				background:$dark;
				color:$white;
			}
		}
	}
	&.display tbody tr.even,
	&.order-column.stripe tbody tr.even {
		> .sorting_1 {
			@include background-opacity($light_10,.3);
		}
		&.selected {
			> .sorting_1 {
				background:$dark;
				color:$white;
			}
		}
	}
	&.hover tbody tr:hover,&.display tbody tr:hover {
		background-color:$blue;
		color:$white;
		&.selected {
			background:$dark;
			color:$white;
		}
	}
	
	&.dtr-inline.collapsed > tbody > tr[role="row"]{
		> td,> tr {
			&:first-child {
				padding-left:1.25rem;
				&:before {
					display:none;
				}
			}
		}
	}
	> tbody {
		> tr.child {
			ul.dtr-details {
				width:100%;
				> li {
					border-bottom:none;
					padding: 0.25em;
					&:first-child {
						padding-top:0.25em;
					}
				}
			}
			span {
				&.dtr-title {
					min-width: 80px;
					font-weight: 500;
					font-size: 13px;
					text-transform: uppercase;
					color:$dark;
				}
				&.dtr-data {
					color:$light_60;
				}
			}
		}
	}
}
.dataTables_wrapper {
	.dataTables_paginate .paginate_button {
		padding: 0;
		margin-left: 0;
		color: $dark !important;
		border: none!important;
		background: transparent!important;
		box-shadow: none!important;
		border-radius: 0;
	}
	.dataTables_filter input {
		margin-left: 0;
	}
	.dataTables_length {
		select {
			width: 80px;
			margin-right: 10px;
		}
	}
}
@media (max-width: 991px) { 
	.dataTables_wrapper {
		.dataTables_length {
			display:none;
		}
	}
}
/*Responsive Table*/
.tablesaw {
	.tablesaw-sortable-head {
		padding: 0.75rem 1.25rem;
		.tablesaw-sortable-arrow {
			position: absolute;
			right: 8px;
			@include transform(translateY(-50%));
			top: 50%;
			height: 24px;
		}
		.tablesaw-sortable-btn {
			padding:0;
		}
	}
	&.tablesaw-swipe .tablesaw-swipe-cellpersist {
		border-right:none;
	}
	&.tablesaw-stack td .tablesaw-cell-label, .tablesaw-stack th .tablesaw-cell-label {
		font-weight:500;
		color:$dark;
		font-size: 13px;
		text-transform: uppercase;
		padding: 3px .6em 0 0;
	}
}
.tablesaw-bar .tablesaw-bar-section .tablesaw-btn {
	margin-left: 10px;
	margin-top: 0;
	padding: 0;
	height: auto;
	position:relative;
	top:-2px;
	span {
		display:none;
	}
	&:after {
		display:none;
	}
}
.tablesaw-bar .tablesaw-bar-section {
	label {
		color:$dark;
		text-transform:capitalize;
	}
	select {
		position: static;
		min-height: 1em;
		opacity:1;
		display: inline-block;
		width: 100%;
		height: calc(2.25rem + 4px);
		padding: .375rem 1.75rem .375rem .75rem;
		line-height: 1.5;
		color: $dark;
		vertical-align: middle;
		background: $white url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
		background-size: auto auto;
		background-size: 8px 10px;
		border: 2px solid $light_10;
		border-radius: .25rem;
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		@include transition(border-color .15s ease-in-out);
		&:focus {
			border-color:$sky;
		}
	}
}
.tablesaw-columntoggle-popup .btn-group > label {
	margin-right: 15px;
	margin-bottom: 0;
	&:last-child {
		margin-right:0;
	}
	input {
		margin-right: 5px;
	}
}
@media (max-width: 39.9375em) {
	.tablesaw-stack tbody tr {
		border-bottom: none;
	}
	.tablesaw-stack tbody tr:first-child {
		border-top: 1px solid $light_10;
	}
}

/*Morris Chart*/
.morris-chart {
	width:100%;
	svg {
		width:100%;
	}
	&.donut-chart {
		svg {
			path {
				stroke:transparent;
			}
		}
	}
}
.morris-hover.morris-default-style {
    background: $white;
    border: none;
    border-radius: 6px;
	box-shadow:0 6px 10px rgba(0,0,0,0.1);
    color: $dark;
    font-size: 12px;
	font-family:inherit;
	.morris-hover-row-label {
		color:$dark;
		font-size: 12px;
	}
}

/*Sparkline*/
.jqstooltip {
  width: auto !important;
  height: auto !important;
  background:$white!important;
  border:none!important;
  box-shadow:0 6px 10px rgba(0,0,0,0.1);
  border-radius: 6px;
  .jqsfield {  
	color: $dark!important;
	font-size: 12px;
  }
}

/*Flot Chart*/
.flot-container {
	width: 100%;
	height:400px;
	.legendColorBox > div {
		border: none!important;
	}
}

.demo-placeholder {
	width: 100%;
	height: 100%;
}
.flotTip {
	background: $white;
    border: none;
    border-radius: 6px;
	box-shadow:0 6px 10px rgba(0,0,0,0.1);
    color: $dark;
    font-size: 12px;
	font-family:inherit;
}
.legendLabel {
	color:$dark;
	font-size:12px;
	padding-left:9px;
}

/*Peity chart*/
.peity-gradient {
	polygon {
		fill: url(#grad);
	}
}	
.css-peity {
	height:0;
	width:0;
	position:absolute;
}
/*Echats*/
.echart {
	>div:last-child {
		box-shadow:0 6px 10px rgba(0,0,0,0.1);
	}	
}
/*Easy pie chart*/
.easy-pie-chart {
	position: relative;
	width: 100%;
  > span {
		font-size: 12px;
		position: absolute;
		text-align: center;
		line-height: 50px;
		width: 100%;
	}
}

/*Nestable*/
.dd-handle {
	@extend .form-control;
	font-weight: 500;
	margin: 0 0 6px;
	font-size: 14px;
	padding: 8px 15px;
	background:$white;
	color: $dark;
	height: 38px;
	border-color:$light_10;
	&:hover,&:focus,&:active {
		color:$dark;
		background:$light;
	}
}
.dd-item > button {
	margin:0;
	height:38px;
	font-size:24px;
	width:42px;
	color:$light_40;
	outline:none;
		&.dd-expand:before,&.dd-collapse:before {
			font-family: "Ionicons";
			content: "";
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			text-rendering: auto;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
		}	
		&.dd-expand::before {
			content: "\f102";
		}
		&.dd-collapse::before {
			content: "\f1fc";
		}
		&:hover,&:focus,&:active {
			outline:none;
		}
}
.dd-placeholder {
	background:transparent;
	border-color:$light_15;
	
}
.dd3-content {
	display: block;
	width: 100%;
	border-radius:3px;
	font-weight: 500;  
	margin: 0 0 6px;    
	font-size: 14px;
	padding: 8px 15px 8px 66px;
	background:$white;
	color: $dark;
	border:2px solid $light_10;
	&:hover,&:focus,&:active {
		color:$dark;
		background:$light;
	}
}
.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}
.dd3-item > button {
  margin-left: 42px;
}
.dd3-handle {
	position: absolute;
	margin: 0;
	left: 0;
	top: 0;
	cursor: pointer;
	font-size:19px;
	color:$light_40;
	width: 42px;
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	padding:18px;
	border-color:$light_10;
	border-radius:3px 0 0 3px;
	&:before {
		font-family: "Ionicons";
		content: "\f32a";
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		text-rendering: auto;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color:$grey;
		display: block;
		position: absolute;
		left: 0;
		top: 8px;
		width: 100%;
		text-align: center;
		text-indent: 0;
  }
}
/*Modals*/
.modal-content,
.modal-header,
.modal-footer {
	border-color:$light_10;
}	
.modal-content {
	border-radius:.25rem;
	border:none;
	box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
}	
.modal-header {
	border-top-left-radius:.25rem;
	border-top-right-radius:.25rem;
}
.modal-header,
.modal-body,
.modal-footer {
	Padding: 0.75rem 1.25rem;
}
.close {
	color:$light_70;
	opacity: 1;
	text-shadow:none;
	&:not(:disabled):not(.disabled):focus, &:not(:disabled):not(.disabled):hover {
		color:$light_70;
		opacity: 1;
	}
}
.modal-backdrop {
	background-color:$dark;
}
/*Sweet-alert*/
.sweet-alert {
	border-radius:2px;
	font-family:inherit;
	h2 {
		font-weight:400;
		color:$dark;
	}
	button {
		box-shadow:none!important;
		font-size:inherit;
	}
}
/*Notification*/
.alert {
	border-radius:3px;
	i {
		font-size:24px;
	}
	.alert-link {
		font-weight:normal;
		font-weight: 500;
		color: inherit;
		filter: brightness(120%) contrast(100%);
	}
	&.alert-dismissible .close {
		padding: .60rem 1.25rem;
		text-shadow: none;
	}
	&.alert-primary {
		color:$sky_dark_5;
		background:$sky_light_5;
		@include border-opacity($sky_light_4,0.3);
	}
	&.alert-success {
		color:$green_dark_5;
		background:$green_light_5;
		@include border-opacity($green_light_4,0.3);
	}
	&.alert-info {
		color:$cyan_dark_5;
		background:$cyan_light_5;
		@include border-opacity($cyan_light_4,0.3);
	}
	&.alert-danger {
		color:$red_dark_5;
		background:$red_light_5;
		@include border-opacity($red_light_4,0.3);
	}
	&.alert-warning {
		color:$yellow_dark_5;
		background:$yellow_light_5;
		@include border-opacity($yellow_light_4,0.3);
	}
	&.alert-secondary {
		color:$grey_dark_5;
		background:$grey_light_5;
		@include border-opacity($grey_light_4,0.3);
	}
	&.alert-light {
		color:$light_80;
		background:$light;
		@include border-opacity($light_10,1);
	}
	&.alert-dark {
		color:$white;
		background:$dark;
		&.alert-dismissible {
			.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
				color:$white;
				text-decoration: none;
				opacity: 1;
			}
		}
		@include border-opacity($dark_20,1);
	}
	&.alert-inv {
		&.alert-inv-primary {
			background:$sky;
			color:$sky_light_5;
			&.alert-dismissible {
				.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
		&.alert-inv-info {
			background:$cyan;
			color:$cyan_light_5;
			&.alert-dismissible {
				.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
		&.alert-inv-success {
			background:$green;
			color:$green_light_5;
			&.alert-dismissible {
				.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
		&.alert-inv-warning {
			background:$yellow;
			color:$yellow_light_5;
			&.alert-dismissible {
				.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
		&.alert-inv-danger {
			background:$red;
			color:$red_light_5;
			&.alert-dismissible {
				.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
		&.alert-inv-secondary {
			background:$grey;
			color:$grey_light_5;
			&.alert-dismissible {
				.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
					color:$white;
					text-decoration: none;
					opacity: 1;
				}
			}
		}
	}
	&.alert-wth-icon {
		padding-left:54px;
		position:relative;
		.alert-icon-wrap {
			display: block;
			text-align: center;
			padding-top: 12px;
			height:100%;
			width:54px;
			left:0;
			top:0;
			position:absolute;
		}
	}
}
.alert-dismissable .close, .alert-dismissible .close {
	top:0;
}
.jq-toast-wrap {
	width:300px;
	.jq-toast-single {
		color:$white;
		border-radius:2px;
		margin:0;
		border:1px solid;
		background:$dark;
		@extend .main-font;
		font-size:14px;
		line-height: 1.3;
		.jq-toast-loader {
			border-radius:2px 2px 0 0;
			height: 2px;
		}
		.close-jq-toast-single {
			font-size: 23px;
			top:0;
		}	
		.jq-toast-heading {
			font-size:16px!important;
			color:inherit;
			font-weight:500;
			@extend .main-font;
		}	
		&.jq-toast-primary {
			background:$sky_light_5;
			color:$sky_dark_5;
			@include border-opacity($sky_light_4,0.3);
		}
		&.jq-toast-secondary {
			background:$grey_light_5;
			color:$grey_dark_5;
			@include border-opacity($grey_light_4,0.3);
		}
		&.jq-toast-info {
			background:$cyan_light_5;
			color:$cyan_dark_5;
			@include border-opacity($cyan_light_4,0.3);
		}
		&.jq-toast-success {
			background:$green_light_5;
			color:$green_dark_5;
			@include border-opacity($green_light_4,0.3);
		}
		&.jq-toast-warning {
			background:$yellow_light_5;
			color:$yellow_dark_5;
			@include border-opacity($yellow_light_4,0.3);
		}
		&.jq-toast-danger {
			background:$red_light_5;
			color:$red_dark_5;
			@include border-opacity($red_light_4,0.3);
		}
		
		&.jq-toast-light {
			background:$light;
			color:$light_80;
			@include border-opacity($light_10,1);
		}
		&.jq-toast-dark {
			background:$dark;
			color:$white;
			@include border-opacity($dark_20,1);
		}
		&.jq-has-icon {
			padding: 11px 25px 15px 45px;
			.jq-toast-icon {
				position:absolute;
				font-size: 17px;
				left: 11px;
				top: 14px;
			}
		}
	}
}	
.bottom-center-fullwidth { 
	.jq-toast-wrap{
		left:0!important;
		right:0!important;
		width:100%!important;
		bottom:0!important;
	}
}
.top-center-fullwidth {
	.jq-toast-wrap {
		left:0!important;
		right:0!important;
		width:100%!important;
		top:2px!important;
	}
}
@media (max-width: 576px) {
	.jq-toast-wrap{
		&.top-right,
		&.top-left,
		&.bottom-right,
		&.bottom-left {
			left:0;
			right:0;
			width:100%;
		}	
	}
}
/*Tooltop*/
.tooltip {
	font-size:12px;
	.tooltip-inner {
		background:$dark;
	}
	&.show {
		opacity:1;
	}
	&.bs-tooltip-left .arrow::before {
		border-left-color:$dark;
	}
	&.bs-tooltip-right .arrow::before {
		border-right-color:$dark;
	}
	&.bs-tooltip-top .arrow::before {
		border-top-color:$dark;
	}
	&.bs-tooltip-bottom .arrow::before
	{
		border-bottom-color:$dark;
	}
	&.tooltip-primary,
	&.tooltip-sky {
		.tooltip-inner {
			background:$sky;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$sky;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$sky;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$sky;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$sky;
		}
	}
	&.tooltip-success,
	&.tooltip-green {
		.tooltip-inner {
			background:$green;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$green;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$green;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$green;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$green;
		}
	}
	&.tooltip-info,
	&.tooltip-cyan {
		.tooltip-inner {
			background:$cyan;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$cyan;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$cyan;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$cyan;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$cyan;
		}
	}
	&.tooltip-warning,
	&.tooltip-yellow {
		.tooltip-inner {
			background:$yellow;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$yellow;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$yellow;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$yellow;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$yellow;
		}
	}
	&.tooltip-danger,
	&.tooltip-red {
		.tooltip-inner {
			background:$red;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$red;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$red;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$red;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$red;
		}
	}
	&.tooltip-secondary,
	&.tooltip-grey	{
		.tooltip-inner {
			background:$grey;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$grey;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$grey;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$grey;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$grey;
		}
	}
	&.tooltip-light {
		.tooltip-inner {
			background:$light_10;
			color:$dark;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$light_10;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$light_10;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$light_10;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$light_10;
		}
	}
	&.tooltip-dark {
		.tooltip-inner {
			background:$dark;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$dark;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$dark;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$dark;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$dark;
		}
	}
	&.tooltip-pink {
		.tooltip-inner {
			background:$pink;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$pink;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$pink;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$pink;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$pink;
		}
	}
	&.tooltip-purple {
		.tooltip-inner {
			background:$purple;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$purple;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$purple;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$purple;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$purple;
		}
	}
	&.tooltip-violet {
		.tooltip-inner {
			background:$violet;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$violet;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$violet;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$violet;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$violet;
		}
	}
	&.tooltip-indigo {
		.tooltip-inner {
			background:$indigo;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$indigo;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$indigo;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$indigo;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$indigo;
		}
	}
	&.tooltip-blue {
		.tooltip-inner {
			background:$blue;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$blue;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$blue;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$blue;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$blue;
		}
	}
	&.tooltip-teal {
		.tooltip-inner {
			background:$teal;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$teal;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$teal;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$teal;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$teal;
		}
	}
	&.tooltip-neon {
		.tooltip-inner {
			background:$neon;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$neon;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$neon;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$neon;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$neon;
		}
	}
	&.tooltip-lime {
		.tooltip-inner {
			background:$lime;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$lime;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$lime;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$lime;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$lime;
		}
	}
	&.tooltip-sun {
		.tooltip-inner {
			background:$sun;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$sun;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$sun;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$sun;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$sun;
		}
	}
	&.tooltip-orange {
		.tooltip-inner {
			background:$orange;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$orange;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$orange;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$orange;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$orange;
		}
	}
	&.tooltip-pumpkin {
		.tooltip-inner {
			background:$pumpkin;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$pumpkin;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$pumpkin;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$pumpkin;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$pumpkin;
		}
	}
	&.tooltip-brown {
		.tooltip-inner {
			background:$brown;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$brown;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$brown;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$brown;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$brown;
		}
	}
	&.tooltip-gold {
		.tooltip-inner {
			background:$gold;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$gold;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$gold;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$gold;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$gold;
		}
	}
	&.tooltip-smoke {
		.tooltip-inner {
			background:$smoke;
		}
		&.bs-tooltip-left .arrow::before {
			border-left-color:$smoke;
		}
		&.bs-tooltip-right .arrow::before {
			border-right-color:$smoke;
		}
		&.bs-tooltip-top .arrow::before {
			border-top-color:$smoke;
		}
		&.bs-tooltip-bottom .arrow::before
		{
			border-bottom-color:$smoke;
		}
	}
}

/*Images*/
.img-thumbnail {
    border-color: $light_10;
    border-radius: 0;
}
.img-background {
	background-size:cover;
	background-repeat:no-repeat;
	background-position:center;
}
/*Icons*/
.font-icons-wrap {
	li {
		text-align: center;
		display: inline-block;
		padding: 10px;
		min-width: 50px;
		min-height: 60px;
		a {
			position: relative;
			color: $dark;
			> i {
				font-size: 27px;
			}
			span {
				display: inline-block;
				position: absolute;
				background-color: $blue;
				color: #fff;
				padding: 4px 7px;
				font-size: 12px;
				white-space: nowrap;
				top: -30px;
				left: 50%;
				@include transform(translateX(-50%));
				display: none;
			}
			&:hover {
				span {
					display: block;
				}
			}
		}
	}
}
.feather {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}
.feather-icon{
	font-size: 0 !important;
	&.fe-x {
		>svg {
			height:16px;
			width:16px;
		}	
	}
}

/*Checkbox*/
.custom-checkbox {
	.custom-control-input:checked ~ .custom-control-label::before {
		background-color: $sky;
	}
	&.checkbox-primary,
	&.checkbox-sky {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $sky;
		}
	}
	&.checkbox-success,
	&.checkbox-green {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $green;
		}
	}
	&.checkbox-info,
	&.checkbox-cyan {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $cyan;
		}
	}	
	&.checkbox-warning,
	&.checkbox-yellow {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $yellow;
		}
	}
	&.checkbox-danger,
	&.checkbox-red {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $red;
		}
	}
	&.checkbox-secondary,
	&.checkbox-grey {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $grey;
		}
	}
	&.checkbox-dark {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $dark;
		}
	}
	&.checkbox-light {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $light_20;
		}
	}
	&.checkbox-pink {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $pink;
		}
	}
	&.checkbox-purple {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $purple;
		}
	}
	&.checkbox-violet {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $violet;
		}
	}
	&.checkbox-indigo {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $indigo;
		}
	}
	&.checkbox-blue {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $blue;
		}
	}
	&.checkbox-teal {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $teal;
		}
	}
	&.checkbox-neon {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $neon;
		}
	}
	&.checkbox-lime {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $lime;
		}
	}
	&.checkbox-sun {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $sun;
		}
	}
	&.checkbox-orange {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $orange;
		}
	}
	&.checkbox-pumpkin {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $pumpkin;
		}
	}
	&.checkbox-brown {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $brown;
		}
	}
	&.checkbox-gold {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $gold;
		}
	}
	&.checkbox-smoke {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $smoke;
		}
	}
	
}
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: none;
}
/*Radio*/
.custom-radio {
	.custom-control-input:checked ~ .custom-control-label::before {
		background-color: $sky;
	}
	&.radio-primary,
	&.radio-sky {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $sky;
		}
	}
	&.radio-success,
	&.radio-green {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $green;
		}
	}
	&.radio-info,
	&.radio-cyan {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $cyan;
		}
	}	
	&.radio-warning,
	&.radio-yellow {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $yellow;
		}
	}
	&.radio-danger,
	&.radio-red {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $red;
		}
	}
	&.radio-secondary,
	&.radio-grey {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $grey;
		}
	}
	&.radio-dark {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $dark;
		}
	}
	&.radio-light {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $light_20;
		}
	}
	&.radio-pink {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $pink;
		}
	}
	&.radio-purple {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $purple;
		}
	}
	&.radio-violet {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $violet;
		}
	}
	&.radio-indigo {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $indigo;
		}
	}
	&.radio-blue {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $blue;
		}
	}
	&.radio-teal {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $teal;
		}
	}
	&.radio-neon {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $neon;
		}
	}
	&.radio-lime {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $lime;
		}
	}
	&.radio-sun {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $sun;
		}
	}
	&.radio-orange {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $orange;
		}
	}
	&.radio-pumpkin {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $pumpkin;
		}
	}
	&.radio-brown {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $brown;
		}
	}
	&.radio-gold {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $gold;
		}
	}
	&.radio-smoke {
		.custom-control-input:checked ~ .custom-control-label::before {
			background-color: $smoke;
		}
	}
}

/*Carousel*/
.carousel-control-prev,
.carousel-control-next {
	opacity:1;
	&:hover {
		.carousel-control-next-icon,
		.carousel-control-prev-icon	{
			@include border-opacity($white, 1);
		}
	}
}
.carousel-control-next-icon,
.carousel-control-prev-icon	{
	height: 50px;
	width: 50px;
	border: 2px solid;
	@include border-opacity($white, 0.5);
	border-radius: 50%;
	background:none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	font-size: 21px;
	&:before {
		display: inline-block;
		font-family: "Ionicons";
		speak: none;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		text-rendering: auto;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
}
.carousel-control-next-icon:before {
	content: "\f287";
}
.carousel-control-prev-icon:before {
	content: "\f27d";
}
.carousel-indicators {
	bottom:20px;
	 li {
		width: 8px;
		height: 8px;
		margin-right: 5px;
		margin-left: 5px;
		border-radius: 50%;
		@include background-opacity($light_30,.5);
		&.active {
			@include background-opacity($light_30,1);
		}
	}
}
.carousel-caption {
    bottom: 30px;
}	
.owl-carousel {
	.item-video {
		height:auto;
	}
	.owl-video-tn { 
		background-size:cover;
	}

	.owl-dots .owl-dot {
		span {
			width: 8px;
			height: 8px;
			margin: 0 5px;
			@include background-opacity($light_30,.5);
		}	
		&:focus {
			outline:none;
		}
		&:hover {
			span {
				@include background-opacity($light_30,1);
			}	
		}
		&.active {
			span {
				@include background-opacity($light_30,1);
			}
		}
	}
	.owl-video-play-icon {
		@include background-opacity($white,.8);
		left: 20px;
		bottom: 20px;
		margin: 0;
		height: 35px;
		width: 35px;
		border-radius: 50%;
		top: auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		color:$sky;
		&:before {
			display: inline-block;
			font-family: "Ionicons";
			padding-left:3px;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			text-rendering: auto;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content:"\f488";
		}
		&:hover {
			@include transform(scale(1.1,1.1));
		}
	}
	&.dots-on-item {
		.owl-dots {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 10px;
		}
	}
}
@media (max-width: 991px) {
	.carousel-control-next-icon, .carousel-control-prev-icon {
		height: 30px;
		width: 30px;
		font-size: 17px;
	}
}
/*Twitter slider*/
.twitter-slider-wrap {
	i.fa-twitter {
		font-size:30px;
		color:$white;
	}
	.owl-carousel {
		text-align:center;
		.user,.interact {
			display:none;
		}
		.tweet {
			font-size:16px;
			color:$white;
			margin-bottom:30px;
			> a {
				color:$white;
			}
		}
		.timePosted {
			> a {
				font-size:12px;
				color:$white;
			}
		}
	}
}

/*Color Pickr*/
.pickr {
	&:before {
		position: absolute;
		content: "";
		height: 100%;
		width: 35px;
		background: $light;
		right: 0;
		z-index: 1;
		pointer-events: none;
	}
	&:after {
		font-family: "Ionicons";
		speak: none;
		font-size: 18px;
		font-style: normal;
		font-weight: normal;
		font-variant: normal;
		text-transform: none;
		text-rendering: auto;
		line-height: 1;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		content: "\f280";
		color:$dark;
		position: absolute;
		right: 0;
		top: 8px;
		z-index: 2;
		width: 35px;
		height: 100%;
		text-align: center;
		pointer-events: none;
	}
	.pcr-app {
		z-index:100;
		.pcr-interaction {
			input {
				padding: .3em .5em;
				letter-spacing:inherit;
				border-radius: 4px;
				background:$light;
				color:$light_30;
			}
			.pcr-save {
				background: $sky;
				color:$white;
			}
			.pcr-clear {
				background: $red;
				color:$white;
			}
			.pcr-type.active {
				background: $sky_light_5;
				color:$sky;
			}
			.pcr-result {
				color: $dark;
				border:2px solid $light_10;
				background:$white;
				border-radius:.25rem;
				&:focus {
					box-shadow:none;
					border-color:$sky;
				}
			}
		}
	}
	.pcr-button {
		width: 100%;
		border-radius: 0;
	}	
}	
@media (max-width: 414px) {
	.pcr-app {
		max-width:310px;
	}
}	
/*Range Slider*/
.irs-line {
    height: 7px;
    background: $light_10;
    border: none;
    border-radius: 0;
}
.irs-bar {
    height: 7px; 
	border:none;
	border-radius: 0;
    background: $sky;
}
.irs-slider {
    top: 27px;
    width: 20px;
    height: 20px;
    border: 2px solid $white;
    background: $sky;
	border-radius: 50%;
    box-shadow: none;
	&.state_hover,&:hover {
		background: $sky;
	}
}

.irs-bar-edge {
    height: 7px;
    top: 33px;
    width: 14px;
    border: none;
	background: $sky;
    border-radius: 0;
}
.irs-from, .irs-to, .irs-single {
    font-size: 14px;
    background: $dark;
	line-height:1.5;
    border-radius: 4px;
    -moz-border-radius: 4px;
}
.irs-min, .irs-max {
    color: $dark;
    font-size: 12px;
    background: $light_10;
	line-height:1.5;
    border-radius: 4px;
    -moz-border-radius: 4px;
}
.irs.irs-sm {
	.irs-line {
		height: 3px;
	}
	.irs-bar {
		height: 3px; 
	}
	.irs-slider {
		top: 29px;
		width: 12px;
		height: 12px;
	}

	.irs-bar-edge {
		height: 3px;
	}
}
.irs-sky,
.irs-primary {
	.irs-bar,.irs-bar-edge {
		background: $sky;
	}
	.irs-slider {
		background: $sky;
		&.state_hover,&:hover {
			background: $sky;
		}
	}
}
.irs-cyan,
.irs-info {
	.irs-bar,.irs-bar-edge {
		background: $cyan;
	}
	.irs-slider {
		background: $cyan;
		&.state_hover,&:hover {
			background: $cyan;
		}
	}
}
.irs-green,
.irs-success {
	.irs-bar,.irs-bar-edge {
		background: $green;
	}
	.irs-slider {
		background: $green;
		&.state_hover,&:hover {
			background: $green;
		}
	}
}
.irs-yellow,
.irs-warning {
	.irs-bar,.irs-bar-edge {
		background: $yellow;
	}
	.irs-slider {
		background: $yellow;
		&.state_hover,&:hover {
			background: $yellow;
		}
	}
}
.irs-red,
.irs-danger {
	.irs-bar,.irs-bar-edge {
		background: $red;
	}
	.irs-slider {
		background: $red;
		&.state_hover,&:hover {
			background: $red;
		}
	}
}
.irs-blue {
	.irs-bar,.irs-bar-edge {
		background: $blue;
	}
	.irs-slider {
		background: $blue;
		&.state_hover,&:hover {
			background: $blue;
		}
	}
}
.irs-pink {
	.irs-bar,.irs-bar-edge {
		background: $pink;
	}
	.irs-slider {
		background: $pink;
		&.state_hover,&:hover {
			background: $pink;
		}
	}
}
.irs-purple {
	.irs-bar,.irs-bar-edge {
		background: $purple;
	}
	.irs-slider {
		background: $purple;
		&.state_hover,&:hover {
			background: $purple;
		}
	}
}
.irs-violet {
	.irs-bar,.irs-bar-edge {
		background: $violet;
	}
	.irs-slider {
		background: $violet;
		&.state_hover,&:hover {
			background: $violet;
		}
	}
}
.irs-indigo {
	.irs-bar,.irs-bar-edge {
		background: $indigo;
	}
	.irs-slider {
		background: $indigo;
		&.state_hover,&:hover {
			background: $indigo;
		}
	}
}
.irs-teal {
	.irs-bar,.irs-bar-edge {
		background: $teal;
	}
	.irs-slider {
		background: $teal;
		&.state_hover,&:hover {
			background: $teal;
		}
	}
}
.irs-neon {
	.irs-bar,.irs-bar-edge {
		background: $neon;
	}
	.irs-slider {
		background: $neon;
		&.state_hover,&:hover {
			background: $neon;
		}
	}
}
.irs-lime {
	.irs-bar,.irs-bar-edge {
		background: $lime;
	}
	.irs-slider {
		background: $lime;
		&.state_hover,&:hover {
			background: $lime;
		}
	}
}
.irs-sun {
	.irs-bar,.irs-bar-edge {
		background: $sun;
	}
	.irs-slider {
		background: $sun;
		&.state_hover,&:hover {
			background: $sun;
		}
	}
}
.irs-orange {
	.irs-bar,.irs-bar-edge {
		background: $orange;
	}
	.irs-slider {
		background: $orange;
		&.state_hover,&:hover {
			background: $orange;
		}
	}
}
.irs-pumpkin {
	.irs-bar,.irs-bar-edge {
		background: $pumpkin;
	}
	.irs-slider {
		background: $pumpkin;
		&.state_hover,&:hover {
			background: $pumpkin;
		}
	}
}
.irs-brown {
	.irs-bar,.irs-bar-edge {
		background: $brown;
	}
	.irs-slider {
		background: $brown;
		&.state_hover,&:hover {
			background: $brown;
		}
	}
}
.irs-gold {
	.irs-bar,.irs-bar-edge {
		background: $gold;
	}
	.irs-slider {
		background: $gold;
		&.state_hover,&:hover {
			background: $gold;
		}
	}
}
.irs-grey,
.irs-secondary {
	.irs-bar,.irs-bar-edge {
		background: $grey;
	}
	.irs-slider {
		background: $grey;
		&.state_hover,&:hover {
			background: $grey;
		}
	}
}
.irs-dark {
	.irs-bar,.irs-bar-edge {
		background: $dark;
	}
	.irs-slider {
		background: $dark;
		&.state_hover,&:hover {
			background: $dark;
		}
	}
}
.irs-light {
	.irs-bar,.irs-bar-edge {
		background: $light;
	}
	.irs-slider {
		background: $light;
		&.state_hover,&:hover {
			background: $light;
		}
	}
}
/*Daterangepicker*/
.daterangepicker {
	font-family: inherit;
	border-color:$light_10;
	&:before,&:after {
		display:none;
	}
	.drp-buttons .btn {
		margin-left: 8px;
		padding: .25rem .5rem;
		font-size: .875rem;
		font-weight:400;
	}
	td {
		&.active,&.active:hover {
			background-color: $sky;
			&.available:hover {
				background-color: $sky;
			}
		}
		&.in-range {
			background-color: $sky_light_5;
			color:$dark;
			&.active,&.active:hover {
				background-color: $sky;
			}
		}
		&.available:hover {
			background-color: $light;
		}
	}
	th {
		&.available:hover {
			background-color: transparent;
		}
	}
	.calendar-table {
		th,td {
			min-width: 33px;
			width: 33px;
			height: 33px;
			font-size:13px;
			color:$smoke;
			&.active,&.active:hover {
				color:$white;
				&.available:hover {
					color:$white;
				}
			}
		}
		th {
			&.next span,&.prev span {
				border-color:$light_30;
			}
			&:hover {
				&.next span,&.prev span {
					border-color:$dark;
				}
			}
		}	
	}
	th.month {
		width: auto;
		font-size: 14px;
		color: $dark;
		letter-spacing:.5px;
		text-transform: uppercase;
		font-weight: 600;
	}	
	.drp-buttons {
		border:none;
		background:$light;
	}
	.drp-selected {
		font-size: 13px;
		padding-right: 3px;
		color: $dark;
		font-weight: 500;
		letter-spacing: 0.5px;
	}	
	td.off, td.off.in-range,td.off.start-date,td.off.end-date {
		color:$light_15;
	}
	select.hourselect, select.minuteselect, select.secondselect,select.ampmselect {
		background:$white;
		width: 60px;
		margin: 10px auto;
		border: 2px solid $light_10;
		padding: 3px;
		border-radius: 4px;
		font-size: 13px;
		color: $dark;
	}
	select.monthselect, select.yearselect {
		background:$white;
		border: 2px solid $light_10;
		padding: 3px;
		border-radius: 4px;
		font-size: 13px;
		color: $dark;
	}
	.ranges li {
		font-size: 14px;
		padding:10px 12px;
		&:hover {
			background-color: $light;
		}
		&.active {
			background-color:$sky;
		}
	}
	&.show-ranges .drp-calendar.left {
		border-color:$light_10;
	}
}
@media (max-width: 767px) {
 	.daterangepicker {
		width: 281px;
		&.show-ranges {
			.drp-calendar.left {
				border:none;
			}
		}
		.drp-buttons {
			text-align:left;
			.btn {
				margin-left: 0;
				margin-right: 8px;
			}
			.drp-selected {
				display: block;
				margin-bottom: 10px;
			}
		}
		&.ltr .ranges {
			width: 100%;
			ul {
				width: 100%;
			}
		}
		&.ltr .drp-calendar {
			width: 100%;
			&.left .calendar-table {
				padding-right: 0;
			}
		}
	}
} 

/*Invoice*/
.hk-invoice-wrap {
	.invoice-from-wrap,
	.invoice-to-wrap {
		>.row {
			div:last-child {
				text-align:right;
			}
		}
	}
}
@media (max-width: 767px) {
	.hk-invoice-wrap {
		.invoice-from-wrap,
		.invoice-to-wrap {
			>.row {
				div:last-child {
					text-align:left;
				}
			}
		}
	}
}
/*Faq*/
.faq-search-wrap {
	padding:50px 0 60px;
	.form-group {
		.form-control {
			border-top-right-radius:.25rem;
			border-bottom-right-radius:.25rem;
		}
		.input-group-append {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 10;
			pointer-events:none;
			.input-group-text {
				background:transparent;
				border:none;
				.feather-icon {
					>svg {
						height:18px;
						width:18px;
					}
				}
			}
		}
	}
	
}
/*Profile*/
.profile-cover-wrap {
	.profile-cover-img {
		background-size:cover;
		background-position:center;
		background-repeat:no-repeat;
		height:100%;
		width:100%;
		position: absolute;
		left: 0;
		top: 0;
	}
	.profile-cover-content {
		position:relative;
		z-index:1;
		.button-list {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			justify-content: flex-end;
			-webkit-justify-content: flex-end;
			-ms-flex-pack: flex-end;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			height:100%;
			>.btn {
				margin-left:15px;
				margin-top: 0;
				margin-right: 0;
			}
		}
		.media {
			.media-img-wrap {
				margin-right:15px;
				.avatar {
					height:100px;
					width:100px;
				}
			}
		}
	}
}	
.card.card-profile-feed {
	.btn-pg-link{
		position:absolute;
		bottom:25px;
		left:25px;
	}
	.feed-img-layout {
		.row {
			margin-right: -5px;
			margin-left: -5px;
			> [class*="col-"] {
				padding-right: 5px;
				padding-left: 5px;
			}	
		}
		.feed-img {
			background-size:cover;
			background-repeat:no-repeat;
			background-position:center;
			width: 100%;
		}
	}
	.btn-video-link {
		@include background-opacity($dark,.5);
		height: 45px;
		width: 45px;
		position:absolute;
		border-radius: 50%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin:auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;	
		color: $white;
		&:before {
			display: inline-block;
			font-family: "Ionicons";
			padding-left: 3px;
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			text-rendering: auto;
			line-height: 1;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "\f488";
		}
	}
	.card-footer {
		>div {
			a {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				color:$light_40;
				font-size:15px;
				>i {
					font-size:18px;
					margin-right:10px;
				}
			}
			&:last-child {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				a {
					margin-right:10px;
					&:last-child {
						margin-right:0;
					}
				}
			}
		}
	}
}
@media (max-width: 991px) {
	.profile-cover-wrap .profile-cover-content {
		.media {
			-ms-flex-direction: column;
			flex-direction: column;	
			margin-bottom: 20px;
			text-align:center;
			.media-img-wrap {
				margin-right:0;
				margin-bottom:15px;
				.avatar {
					height:70px;
					width:70px;
				}
			}
		}
		.button-list {
			ms-flex-pack: center;
			justify-content: center;
		}
	}
}

/*Activity*/
.user-activity {
  .media {
		position: relative;
		padding-bottom: 20px;
		margin-left:20px;
		.media-img-wrap {
			position: absolute;
			left: -20px;
		}
		&:after {
			content: "";
			position: absolute;
			width: 1px;
			height: calc(100% - 77px);
			background: $light-10;
			top: 60px;
		}
		.media-body {
			border-bottom: 1px solid $light_10;
			padding-bottom: 30px;
			margin-left: 40px;
			min-width:0;
		}
		&:last-child {
			.media-body {
				border-bottom:none;
			}
		}
	}
	&.user-activity-sm {
		 .media {
			padding-bottom: 10px;
			margin-left:16px;
			.media-img-wrap {
				left: -16px;
			}
			&:after {
				height: calc(100% - 47px);
				top: 40px;
			}
			.media-body {
				padding-bottom: 10px;
				margin-left: 30px;
			}
		}
	}
}
@media (max-width: 576px) { 
	.user-activity .media .media-body {
		margin-left: 35px;
	}
}
/*Widgets*/
.hk-dash-type-1 {
	.card-header {
		.nav.nav-tabs {
			min-height:100px;
			>a.nav-item {
				@include background-opacity($light,.5);
				border-right:1px solid $light_10;
				border-bottom:0;
				&:last-child {
					border-right:0!important;
				}
				&.active {
					border:none;
					background:$white;	
					border-right:1px solid $light_10;
					.display-4 {
						color:$sky;
					}
				}
				>div {
					> div {
						&:last-child {
							>span {
								&:last-child {
									font-size:14px;
									text-transform:capitalize;
									font-weight:600;
								}
							}
						}
					}
				}
			}	
		}
	}
}
.review-star {
	color:$dark;
	>.feather-icon {
		display:block;
		> svg {
			height:20px;
			width:20px;
		}
	}
	&:hover {
		color:$dark;
		>.feather-icon {
			> svg {
				fill:$dark!important;
				polygon {
					fill:$dark!important;
				}
			}
		}
	}
	&.starred {
		color:$dark;
		>.feather-icon {
			> svg {
				fill:$dark!important;
				polygon {
					fill:$dark!important;
				}
			}
		}
	}
}
@media (max-width: 991px) {
	.hk-dash-type-2 {
		&.card-group {
			display:block;
			> .card {
				&:first-child,&:last-child {
					border-radius: .25rem;
				}
			}
		}	
	}
}
@media (max-width: 576px) {
	.hk-dash-type-1 {
		.card-header {
			.nav.nav-tabs {
				min-height:70px;
				> a.nav-item {
					.display-4 {
						font-size:1.5rem;
					}
				}	
			}
		}
	}
}
@media (max-width: 414px) {
	.hk-dash-type-1 .card-header .nav.nav-tabs > a.nav-item > div > div:last-child > span:last-child {
		font-size: 13px;
	}
}
.hk-legend-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	-ms-flex-wrap: wrap;
    flex-wrap: wrap;
	.hk-legend {
		margin-left:15px;
		margin-right:15px;
		>span {
			&:last-child {
				display:inline-block;
				text-transform:capitalize;
				font-size:14px;
				margin-left:8px;
			}
		}
	}	
}
/*Dropdown*/
.dropdown-toggle{
	&.no-caret {
		&:after {
			display:none;
		}
	}
	&:after {
		vertical-align: .2em;
		border-top: 0.23em solid;
		border-right: .23em solid transparent;
		border-left: .23em solid transparent;
	}
}
.dropup .dropdown-toggle::after {
	vertical-align: .2em;
    border-right: .23em solid transparent;
    border-bottom: .23em solid;
    border-left: .23em solid transparent;
}
.dropleft .dropdown-toggle::before {
	vertical-align: .2em;
    border-top: .23em solid transparent;
    border-right: .23em solid;
    border-bottom: .23em solid transparent;
}
.dropright .dropdown-toggle::before {
	vertical-align: .2em;
    border-top: .23em solid transparent;
    border-right: .23em solid;
    border-bottom: .23em solid transparent;
}
.dropright .dropdown-toggle::after {
	vertical-align: .2em;
    border-top: .23em solid transparent;
    border-bottom: .23em solid transparent;
    border-left: .23em solid;
}
.dropdown-menu {
	color:$dark;
	border-color:$light_10;
	.dropdown-header,
	.dropdown-item-text {
		color:$smoke;
	}
	.dropdown-item {
		color:$dark;
		.dropdown-icon {
			color:$light_40;
			font-size: 18px;
			margin-right: 10px;
			width: 18px;
		}
		&.active,&:active {
			background-color:$sky;
			color:$white;
		} 
		&:focus:not(.active):not(.disabled),&:hover:not(.active):not(.disabled) {
			background-color:$light;
			color:$dark_100;
		}
		&:disabled,&.disabled {
			color:$light_30;
			background-color:transparent;
		}
	}
	.sub-dropdown-menu {
		position:relative;
		.dropdown-item {
			i {
				font-size: 18px;
				margin-right: 10px;
				width: 18px;
			}
		}
		.dropdown-menu {
			&:before ,&:after{
				display:none!important;
			}
			top:0;
			&.open-left-side {
				right:100%!important;
				left:auto!important;
			}  
			&.open-right-side {
				left:100%!important;
			}  
		}	
	}
	&.dropdown-menu-dark {
		background:$dark;
		border-color:$dark_20;
		color:$white;
		.dropdown-header,
		.dropdown-item-text {
			color:$light_70;
		}
		.dropdown-item {
			color:$light_10;
			&:focus:not(.active):not(.disabled),&:hover:not(.active):not(.disabled) {
				background-color:$light_90;
				color:$white;
			}
			&.active,&:active {
				color:$white;
			}
			&:disabled,&.disabled {
				color:$light_80;
			}
		}
		.dropdown-divider {
			border-color: $dark_20;
		}
	}
}
.dropdown-divider {
	border-color: $light_10;

}	
.show-on-hover {
	&:hover > .dropdown-menu {
		display: block;    
	}
	>.dropdown-toggle:active {
		pointer-events: none;
	}
}
.dropdown-menu.animated {
  -webkit-animation-duration: 0.55s;
  animation-duration: 0.55s;
  
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  
}
/*Avatar*/
.avatar {
    font-size: 1rem;
    position: relative;
    display: inline-block;
    width: 3.875rem;
    height: 3.875rem;
	&.avatar-xl {
		font-size: 1.70833rem;
		width: 5.125rem;
		height: 5.125rem;
	}
	&.avatar-lg {
		font-size: 1.4rem;
		width: 4.5rem;
		height: 4.5rem;
	}
	&.avatar-md {
		font-size: .95rem;
		width: 3.25rem;
		height: 3.25rem;
	}
	&.avatar-sm {
		font-size: .9rem;
		width: 2.625rem;
		height: 2.625rem;
	}
	&.avatar-xs {
		font-size: .6rem;
		width: 2rem;
		height: 2rem;
	}
	.avatar-img {
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}
	.avatar-text {
		display: block;
		width: 100%;
		height: 100%;
		position: relative;
		text-transform:uppercase;
		overflow: hidden;
		background:$white;
		font-weight:600;
		color:$sky;
		.initial-wrap {
			display: table;
			width: 100%;
			height: 100%;
			>span {
				position: relative;
				display:table-cell;
				vertical-align:middle;
				text-align:center;
			}
		}	
		&:before {
			content: "";
			position: absolute;
			height: 100%;
			width: 100%;
			left:0;
			top:0;
			@include background-opacity($sky_light_4,.5);
		}
		&.avatar-text-cyan,
		&.avatar-text-info {
			&:before {
				@include background-opacity($cyan_light_4,.5);
			}
			color:$cyan;
		}
		&.avatar-text-light {
			&:before {
				@include background-opacity($light_40,.5);
			}
			color:$light_70;
		}
		&.avatar-text-dark {
			&:before {
				background:$dark!important;
			}
			color:$white;
		}
		&.avatar-text-sky,
		&.avatar-text-primary {
			&:before {
				@include background-opacity($sky_light_4,.5);
			}
			color:$sky;
		}
		&.avatar-text-green,
		&.avatar-text-success {
			&:before {
				@include background-opacity($green_light_4,.5);
			}
			color:$green;
		}
		&.avatar-text-red,
		&.avatar-text-danger {
			&:before {
				@include background-opacity($red_light_4,.5);
			}
			color:$red;
		}
		&.avatar-text-yellow,
		&.avatar-text-warning {
			&:before {
				@include background-opacity($yellow_light_4,.5);
			}
			color:$yellow;
		}
		&.avatar-text-pink {
			&:before {
				@include background-opacity($pink_light_4,.5);
			}
			color:$pink;
		}
		&.avatar-text-purple {
			&:before {
				@include background-opacity($purple_light_4,.5);
			}
			color:$purple;
		}
		&.avatar-text-violet {
			&:before {
				@include background-opacity($violet_light_4,.5);
			}
			color:$violet;
		}
		&.avatar-text-indigo {
			&:before {
				@include background-opacity($indigo_light_4,.5);
			}
			color:$indigo;
		}
		&.avatar-text-blue {
			&:before {
				@include background-opacity($blue_light_4,.5);
			}
			color:$blue;
		}
		&.avatar-text-teal {
			&:before {
				@include background-opacity($teal_light_4,.5);
			}
			color:$teal;
		}
		&.avatar-text-neon {
			&:before {
				@include background-opacity($neon_light_4,.5);
			}
			color:$neon;
		}
		&.avatar-text-lime {
			&:before {
				@include background-opacity($lime_light_4,.5);
			}
			color:$lime;
		}
		&.avatar-text-sun {
			&:before {
				@include background-opacity($sun_light_4,.5);
			}
			color:$sun;
		}
		&.avatar-text-orange {
			&:before {
				@include background-opacity($orange_light_4,.5);
			}
			color:$orange;
		}
		&.avatar-text-pumpkin {
			&:before {
				@include background-opacity($pumpkin_light_4,.5);
			}
			color:$pumpkin;
		}
		&.avatar-text-brown {
			&:before {
				@include background-opacity($brown_light_4,.5);
			}
			color:$brown;
		}
		&.avatar-text-gold {
			&:before {
				@include background-opacity($gold_light_4,.5);
			}
			color:$gold;
		}
		&.avatar-text-grey,
		&.avatar-text-secondary {
			&:before {
				@include background-opacity($grey_light_4,.5);
			}
			color:$grey;
		}
		&.avatar-text-inv-cyan,
		&.avatar-text-inv-info {
			&:before {
				background-color:$cyan!important;
			}
			color:$white;
		}
		&.avatar-text-inv-sky,
		&.avatar-text-inv-primary {
			&:before {
				background-color:$sky!important;
			}
			color:$white;
		}
		&.avatar-text-inv-green,
		&.avatar-text-inv-success {
			&:before {
				background-color:$green!important;
			}
			color:$white;
		}
		&.avatar-text-inv-red,
		&.avatar-text-inv-danger {
			&:before {
				background-color:$red!important;
			}
			color:$white;
		}
		&.avatar-text-inv-yellow,
		&.avatar-text-inv-warning {
			&:before {
				background-color:$yellow!important;
			}
			color:$white;
		}
		&.avatar-text-inv-pink {
			&:before {
				background-color:$pink!important;
			}
			color:$white;
		}
		&.avatar-text-inv-purple {
			&:before {
				background-color:$purple!important;
			}
			color:$white;
		}
		&.avatar-text-inv-violet {
			&:before {
				background-color:$violet!important;
			}
			color:$white;
		}
		&.avatar-text-inv-indigo {
			&:before {
				background-color:$indigo!important;
			}
			color:$white;
		}
		&.avatar-text-inv-blue {
			&:before {
				background-color:$blue!important;
			}
			color:$white;
		}
		&.avatar-text-inv-teal {
			&:before {
				background-color:$teal!important;
			}
			color:$white;
		}
		&.avatar-text-inv-neon {
			&:before {
				background-color:$neon!important;
			}
			color:$white;
		}
		&.avatar-text-inv-lime {
			&:before {
				background-color:$lime!important;
			}
			color:$white;
		}
		&.avatar-text-inv-sun {
			&:before {
				background-color:$sun!important;
			}
			color:$white;
		}
		&.avatar-text-inv-orange {
			&:before {
				background-color:$orange!important;
			}
			color:$white;
		}
		&.avatar-text-inv-pumpkin {
			&:before {
				background-color:$pumpkin!important;
			}
			color:$white;
		}
		&.avatar-text-inv-brown {
			&:before {
				background-color:$brown!important;
			}
			color:$white;
		}
		&.avatar-text-inv-gold {
			&:before {
				background-color:$gold!important;
			}
			color:$white;
		}
		&.avatar-text-inv-grey,
		&.avatar-text-inv-secondary {
			&:before {
				background-color:$grey!important;
			}
			color:$white;
		}
	}
}
.avatar-group {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	.avatar { 
		font-size: .9rem;
		width: 2.625rem;
		height: 2.625rem;
		.avatar-img,
		.avatar-text {
			border:2px solid $white;
		}
	}
	&.avatar-group-overlapped {
		.avatar {
			margin-right: -13px;
			&:hover{
				z-index:1;
			}
		}
	}	
	&.avatar-group-lg {
		.avatar { 
			font-size: .95rem;
			width: 3.25rem;
			height: 3.25rem;
		}
		&.avatar-group-overlapped .avatar {
			margin-right: -15px;
		}	
	}
	&.avatar-group-sm {
		.avatar { 
			font-size: .6rem;
			width: 2rem;
			height: 2rem;
		}
		&.avatar-group-overlapped .avatar {
			margin-right: -10px;
		}	
	}
}
/*Pagination*/
.pagination-wrap{
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}
.page-link {
	color:$dark;
	border-color:$light_10;
	&:focus,&:hover {
		color:$dark;
		background-color: $light_10;
		border-color: $light_10;
	}
}
.pagination {
	.page-item {
		&.active {
			.page-link {
				background:$sky;
				border-color:$sky;
				color:$white;
			}	
			&.active-info,
			&.active-cyan {
				.page-link {
					background:$cyan;
					border-color:$cyan;
					color:$white;
				}	
			}
			&.active-primary,
			&.active-sky {
				.page-link {
					background:$sky;
					border-color:$sky;
					color:$white;
				}	
			}
			&.active-success,
			&.active-green {
				.page-link {
					background:$green;
					border-color:$green;
					color:$white;
				}	
			}
			&.active-danger,
			&.active-red {
				.page-link {
					background:$red;
					border-color:$red;
					color:$white;
				}	
			}
			&.active-warning,
			&.active-yellow {
				.page-link {
					background:$yellow;
					border-color:$yellow;
					color:$white;
				}	
			}
			&.active-pink {
				.page-link {
					background:$pink;
					border-color:$pink;
					color:$white;
				}	
			}
			&.active-purple {
				.page-link {
					background:$purple;
					border-color:$purple;
					color:$white;
				}	
			}
			&.active-violet {
				.page-link {
					background:$violet;
					border-color:$violet;
					color:$white;
				}	
			}
			&.active-indigo {
				.page-link {
					background:$indigo;
					border-color:$indigo;
					color:$white;
				}	
			}
			&.active-blue {
				.page-link {
					background:$blue;
					border-color:$blue;
					color:$white;
				}	
			}
			&.active-teal {
				.page-link {
					background:$teal;
					border-color:$teal;
					color:$white;
				}	
			}
			&.active-neon {
				.page-link {
					background:$neon;
					border-color:$neon;
					color:$white;
				}	
			}
			&.active-lime {
				.page-link {
					background:$lime;
					border-color:$lime;
					color:$white;
				}	
			}
			&.active-sun {
				.page-link {
					background:$sun;
					border-color:$sun;
					color:$white;
				}	
			}
			&.active-orange {
				.page-link {
					background:$orange;
					border-color:$orange;
					color:$white;
				}	
			}
			&.active-pumpkin {
				.page-link {
					background:$pumpkin;
					border-color:$pumpkin;
					color:$white;
				}	
			}
			&.active-brown {
				.page-link {
					background:$brown;
					border-color:$brown;
					color:$white;
				}	
			}
			&.active-gold {
				.page-link {
					background:$gold;
					border-color:$gold;
					color:$white;
				}
			}	
			&.active-secondary,
			&.active-grey {
				.page-link {
					background:$grey;
					border-color:$grey;
					color:$white;
				}	
			}
			&.active-light {
				.page-link {
					background:$light_10;
					border-color:$light_10;
					color:$dark;
				}	
			}
			&.active-dark {
				.page-link {
					background:$dark;
					border-color:$dark;
					color:$white;
				}	
			}
		}
		&.disabled {
			.page-link {
				color:$light_30;
			}
		}
	}
	&.custom-pagination {
		.page-item {
			+ .page-item {
				padding-left: 8px;
				margin: 0;
			}
		}
		.page-link {
			padding: .5rem .8rem;
			border-radius:.25rem;
		}
		&.pagination-rounded {
			.page-link {
				border-radius:50px;
			}
			.page-item:first-child,
			.page-item:last-child {
				.page-link {
					border-radius:50px;
				}
			}
		}
		&.pagination-filled {
			.page-item:not(.active) {
				.page-link {
					background:$light;
					border-color: $light;
				}
				&:hover {
					.page-link {
						background-color: $light_10;
					}
				}
			}	
		}
		&.pagination-simple {
			.page-item:not(.active) {
				.page-link {
					border-color:transparent;
				}	
			}
		}
	}
}

/*Badge*/
.badge {
    font-weight: 500;
	border-radius: 4px;
	padding: 5px 7px;
	font-size: 72%;
	letter-spacing: 0.3px;
	vertical-align: middle;  
	display: inline-block;
	text-align: center;
	text-transform: capitalize;
	&.badge-indicator {
		width: 7px;
		height: 7px;
		border-radius: 50%;
		padding: 0;
		position: relative;
		&:empty {
			display: inline-block;
		}
		&.badge-indicator-sm {
			width: 5px;
			height: 5px;
		}
	}
	&.badge-sm {
		padding: 3px 5px;
		font-size: 60%;
	}
	&.badge-info,
	&.badge-cyan {
		background:$cyan;
		color:$white;
	}
	&.badge-primary,
	&.badge-sky {
		background:$sky;
		color:$white;
	}
	&.badge-success,
	&.badge-green {
		background:$green;
		color:$white;
	}
	&.badge-danger,
	&.badge-red {
		background:$red;
		color:$white;
	}
	&.badge-warning,
	&.badge-yellow {
		background:$yellow;
		color:$white;
	}
	&.badge-pink {
		background:$pink;
		color:$white;
	}
	&.badge-purple {
		background:$purple;
		color:$white;
	}
	&.badge-violet {
		background:$violet;
		color:$white;
	}
	&.badge-indigo {
		background:$indigo;
		color:$white;
	}
	&.badge-blue {
		background:$blue;
		color:$white;
	}
	&.badge-teal {
		background:$teal;
		color:$white;
	}
	&.badge-neon {
		background:$neon;
		color:$white;
	}
	&.badge-lime {
		background:$lime;
		color:$white;
	}
	&.badge-sun {
		background:$sun;
		color:$white;
	}
	&.badge-orange {
		background:$orange;
		color:$white;
	}
	&.badge-pumpkin {
		background:$pumpkin;
		color:$white;
	}
	&.badge-brown {
		background:$brown;
		color:$white;
	}
	&.badge-gold {
		background:$gold;
		color:$white;
	}
	&.badge-smoke {
		background:$smoke;
		color:$white;
	}
	&.badge-secondary,
	&.badge-grey {
		background:$grey;
		color:$white;
	}
	&.badge-light {
		background:$light_10;
		color:$dark;
	}
	&.badge-dark {
		background:$dark;
		color:$white;
	}
	&.badge-outline {
		background:transparent;
		border:2px solid;
		&.badge-info,
		&.badge-cyan {
			border-color:$cyan;
			color:$cyan;
		}
		&.badge-primary,
		&.badge-sky {
			border-color:$sky;
			color:$sky;
		}
		&.badge-success,
		&.badge-green {
			border-color:$green;
			color:$green;
		}
		&.badge-danger,
		&.badge-red {
			border-color:$red;
			color:$red;
		}
		&.badge-warning,
		&.badge-yellow {
			border-color:$yellow;
			color:$yellow;
		}
		&.badge-secondary,
		&.badge-grey {
			border-color:$grey;
			color:$grey;
		}
		&.badge-light {
			border-color:$light_10;
			color:$dark;
		}
		&.badge-dark {
			border-color:$dark;
			color:$dark;
		}
		&.badge-pink {
			border-color:$pink;
			color:$pink;
		}
		&.badge-purple {
			border-color:$purple;
			color:$purple;
		}
		&.badge-violet {
			border-color:$violet;
			color:$violet;
		}
		&.badge-indigo {
			border-color:$indigo;
			color:$indigo;
		}
		&.badge-blue {
			border-color:$blue;
			color:$blue;
		}
		&.badge-teal {
			border-color:$teal;
			color:$teal;
		}
		&.badge-neon {
			border-color:$neon;
			color:$neon;
		}
		&.badge-lime {
			border-color:$lime;
			color:$lime;
		}
		&.badge-sun {
			border-color:$sun;
			color:$sun;
		}
		&.badge-orange {
			border-color:$orange;
			color:$orange;
		}
		&.badge-pumpkin {
			border-color:$pumpkin;
			color:$pumpkin;
		}
		&.badge-brown {
			border-color:$brown;
			color:$brown;
		}
		&.badge-gold {
			border-color:$gold;
			color:$gold;
		}
	}
	&.badge-soft-cyan,
	&.badge-soft-info {
		background:$cyan_light_5;
		color:$cyan;
	}
	&.badge-soft-sky,
	&.badge-soft-primary {
		background:$sky_light_5;
		color:$sky;
	}
	&.badge-soft-green,
	&.badge-soft-success{
		background:$green_light_5;
		color:$green;
	}
	&.badge-soft-red,
	&.badge-soft-danger {
		background:$red_light_5;
		color:$red;
	}
	&.badge-soft-yellow,
	&.badge-soft-warning {
		background:$yellow_light_5;
		color:$yellow;
	}
	&.badge-soft-pink {
		background:$pink_light_5;
		color:$pink;
	}
	&.badge-soft-purple {
		background:$purple_light_5;
		color:$purple;
	}
	&.badge-soft-violet {
		background:$violet_light_5;
		color:$violet;
	}
	&.badge-soft-indigo {
		background:$indigo_light_5;
		color:$indigo;
	}
	&.badge-soft-blue {
		background:$blue_light_5;
		color:$blue;
	}
	&.badge-soft-teal {
		background:$teal_light_5;
		color:$teal;
	}
	&.badge-soft-neon {
		background:$neon_light_5;
		color:$neon;
	}
	&.badge-soft-lime {
		background:$lime_light_5;
		color:$lime;
	}
	&.badge-soft-sun {
		background:$sun_light_5;
		color:$sun;
	}
	&.badge-soft-orange {
		background:$orange_light_5;
		color:$orange;
	}
	&.badge-soft-pumpkin {
		background:$pumpkin_light_5;
		color:$pumpkin;
	}
	&.badge-soft-brown {
		background:$brown_light_5;
		color:$brown;
	}
	&.badge-soft-gold {
		background:$gold_light_5;
		color:$gold;
	}
	&.badge-soft-smoke {
		background:$smoke_light_5;
		color:$smoke;
	}
	&.badge-soft-grey,
	&.badge-soft-secondary {
		background:$grey_light_5;
		color:$grey;
	}
	&.badge-pill {
		border-radius:50px;
	}
	
}
.badge-indicator-demo {
	.badge-indicator {
		bottom:2px;
	}
}
.btn .badge {
    top: -2px;
}
/*List Group*/
.list-group-item { 
	border-color:$light_10;
	&.active {
		background-color: $sky;
		border-color: $sky;
		h1,h2,h3,h4,h5,h6 {
			color:$white;
		}
	}
	&.disabled,&:disabled {
		color:$light_30;
	}
	&.list-group-item-action:not(.active):focus, &.list-group-item-action:not(.active):hover {
		background-color:$light;
	}
	&.list-group-item-primary:not(.active) { 
		color:$sky_dark_5;
		background-color:$sky_light_5;
	}
	&.list-group-item-info:not(.active) { 
		color:$cyan_dark_5;
		background-color:$cyan_light_5;
	}
	&.list-group-item-success:not(.active) { 
		color:$green_dark_5;
		background-color:$green_light_5;
	}
	&.list-group-item-warning:not(.active) { 
		color:$yellow_dark_5;
		background-color:$yellow_light_5;
	}
	&.list-group-item-danger:not(.active) { 
		color:$red_dark_5;
		background-color:$red_light_5;
	}
	&.list-group-item-secondary:not(.active) { 
		color:$grey_dark_5;
		background-color:$grey_light_5;
	}
	&.list-group-item-light:not(.active) { 
		color:$light_80;
		background-color:$light;
	}
	&.list-group-item-dark:not(.active) { 
		color:$white;
		background-color:$dark;
	}
	&.list-group-inv {
		color:$white;
		&.list-group-inv-primary:not(.active) {
			background-color:$sky;
			color:$sky_light_5;
			border-color:$sky;
		}
		&.list-group-inv-info:not(.active) {
			background-color:$cyan;
			color:$cyan_light_5;
			border-color:$cyan;
		}
		&.list-group-inv-success:not(.active) {
			background-color:$green;
			color:$green_light_5;
			border-color:$green;
		}
		&.list-group-inv-warning:not(.active) {
			background-color:$yellow;
			color:$yellow_light_5;
			border-color:$yellow;
		}
		&.list-group-inv-danger:not(.active) {
			background-color:$red;
			color:$red_light_5;
			border-color:$red;
		}
		&.list-group-inv-secondary:not(.active) {
			background-color:$grey;
			color:$grey_light_5;
			border-color:$grey;
		}
	}
}
/*Google Map*/
.gmap {
	height: calc(100vh - 103px);
}
.infowindow-wrap {
	.infowindow-header {
		color: $dark;
		padding: 10px 5px 0;
		font-family: "Roboto", sans-serif;  
	}
	.infowindow-body {
		padding: 10px 5px 10px;
		color: $smoke;
		font-size: 16px;
		font-weight: 400;
		font-family: "Roboto", sans-serif;
	}
}

/*Vecotor Map*/
.jvectormap-zoomin, 
.jvectormap-zoomout {
	line-height: 9px;
	background:$dark;
	padding:4px;
	width: 12px;
    height: 13px;
	border-radius:0;
}
.jvectormap-zoomout { 
	top:31px;
}
.jvectormap-goback {
	padding: .15rem .4rem;
	font-size: .75rem;
	background:$sky;
	border-radius: 0.2rem;
}
.jvectormap-legend-cnt-h {
	.jvectormap-legend-tick-sample {
		height: 23px;
	}
	.jvectormap-legend {
		background: $dark;
		padding: 3px 5px 5px 5px;
		border-radius: 2px;
		&.jvectormap-legend-icons {
			color: $dark;
			background: $white;
			border: $light_20 1px solid;
		}
		.jvectormap-legend-title {
			font-weight: 500;
			margin-bottom:10px
		}
		.jvectormap-legend-tick {
			width: 55px;
			.jvectormap-legend-tick-text {
				font-size: 11px;
				font-weight: 500;
				text-transform: capitalize;
				margin-top:5px;
			}
		}
	}
}

/*Progressbar*/
.progress { 
	border-radius:0;
	&.progress-bar-rounded {
		border-radius:50px;
		.progress-bar {
			border-radius:50px;
		}
	}
	&.progress-bar-xs {
		height:5px;
	}
	&.progress-bar-sm {
		height:9px;
	}
	&.progress-bar-md {
		height:13px;
	}
	&.progress-bar-lg {
		height:18px;
	}
	&.progress-bar-xl {
		height:20px;
	}
	.progress-bar {
		background-color:$blue;
		&.progress-anim {
			width:0;
		}
	}
}
.progress-label {
	text-transform: capitalize;
	font-size: 14px;
	font-weight: 400;
	margin-bottom:7px;
}
.progress-wrap {
	&.lb-side-left {
		.progress-lb-wrap {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			.progress-label {
				display: inline-block;
				padding-right: 15px;
				flex: 0 0 auto;
				-ms-flex: 0 0 auto;
				margin-bottom:0;
			}
			.progress {
				flex: 1 1 100%;
				-ms-flex: 1 1 100%;
				max-width: 100%;
			}
		}	
	}
}	
/*Chat UI*/	
.chatapp-wrap {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	.chatapp-left {
		-ms-flex:0 0 35%;
		flex:0 0 35%;
		max-width:35%;
		border-right: 1px solid $light_10;
		position:relative;
		z-index:4;
		left:0;
		header {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			-ms-flex-pack: space-between;
			height:72px;
			padding: 0 15px;
			background:$white;
			border-bottom:1px solid $light_10;
			color:$dark;
			span {
				font-size:20px;
				font-weight: 600;
				text-transform:capitalize;
			}
			.chat-more {
				color:$smoke;
			}
			.feather-icon {
				display:block;
			}
		}
		.chat-search {
			width:100%;
			background:$light;
			padding:10px 15px;
			.input-group {
				width:100%;
				.form-control {
					padding-left: 36px;
					border-radius:50px;
					border:none;
					background:$white;
					&:focus {
						border:none;
						box-shadow: none;
						background:$white;
					}
				}
				.input-group-prepend {
					position: absolute;
					left: 10px;
					top: 0;
					z-index: 4;
					bottom: 0;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					pointer-events:none;
					.feather-icon {
						display:block;
						color:$light_60;
						>svg {
							height:18px;
							width:18px;
						}
					}
				}
			}
		}
		.chatapp-users-list {
			background:$white;
			//height: calc(100vh - 190px);
			a.media {
				padding: 10px 15px;
				@include transition(.2s ease);
				.media-img-wrap {
					position:relative;
					margin-right:15px;
					.avatar {
						height:45px;
						width:45px;
					}
					.badge-indicator {
						bottom: 7px;
						height: 7px;
						right: 4px;
						position: absolute;
						width: 7px;
					}
				}
				.media-body {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					justify-content: space-between;
					-webkit-justify-content: space-between;
					-ms-flex-pack: space-between;
					>div:First-child {
						.user-name,
						.user-last-chat {
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							max-width:270px;
						}
						.user-name {
							text-transform:capitalize;
							color:$dark;
						}
						.user-last-chat {
							font-size: 14px;
							line-height: 24px;
							color:$smoke;
						}	
					}
					>div:last-child { 
						text-align:right;
						.last-chat-time {
							font-size: 13px;
						}
					}
				}
				&:hover {
					background:$light;
				}
				&.read-chat {
					.media-body {
						>div:last-child {
							.last-chat-time {
								color:$smoke;
							}
						}	
					}	
				}
				&.active-user {
					background:$light;
				}
			}
			.chat-hr-wrap {
				>hr {
					margin:0;
				}
			}
		}
	}
	.chatapp-right {
		-ms-flex:0 0 65%;
		flex:0 0 65%;
		max-width:65%;
		header {
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: space-between;
			-webkit-justify-content: space-between;
			-ms-flex-pack: space-between;
			height:72px;
			padding: 0 15px;
			background:$white;
			border-bottom:1px solid $light_10;
			.feather-icon {
				display:block;
			}
			.back-user-list {
				display:none;
				margin-right:20px;
			}
			.media {
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				.media-img-wrap {
					position:relative;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					-webkit-box-align: center;
					-ms-flex-align: center;
					align-items: center;
					margin-right:15px;
					.avatar {
						height:50px;
						width:50px;
					}
					.badge-indicator {
						bottom: 3px;
						height: 7px;
						right: 4px;
						position: absolute;
						width: 7px;
					}
				}
				.media-body {
					.user-name {
						font-size: 16px;
						font-weight: 500;
						color:$dark;
						text-transform:capitalize;
					}
					.user-status {
						font-size: 14px;
					}
				}
			}	
			.chat-options-wrap {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				> a {
					margin-left:25px;
					display: inline-block;
				}	
			}
		}
		.chat-body {
			//height: calc(100vh - 190px);
			background:$light;
			ul.list-unstyled {
				width:80%;
				margin:0 auto;
				padding:20px 15px;
			}
			.media {
				.avatar {
					height:32px;
					width:32px;
				}
				.media-body {
					margin-left: 20px;
					.msg-box {
						>div {
							padding: 10px 15px;
							border-radius: .25rem;
							display: inline-block;
							position:relative;
							p {
								color: $dark;
							}
							.chat-time {
								display:block;
								text-transform:uppercase;
								font-size:13px;
								margin-top: 5px;
							}
							.arrow-triangle-wrap {
								position: absolute;
								top: 5px;
								.arrow-triangle {
									position: relative;
									background-color: $white;
									&:before,
									&:after {
										content: '';
										position: absolute;
										background-color: $white;
									}
								}

								.arrow-triangle,
								.arrow-triangle:before,
								.arrow-triangle:after {
									width:  12px;
									height: 12px;
									border-top-right-radius: 0.15rem;
								}

								.arrow-triangle.left {
									@include transform(rotate(-90deg) skewX(-30deg) scale(1,.866));
								}

								.arrow-triangle.right {
									@include transform(rotate(-30deg) skewX(-30deg) scale(1,.866));
								}

								.arrow-triangle:before {
									@include transform(rotate(-135deg) skewX(-45deg) scale(1.414,.707) translate(0,-50%));
								}

								.arrow-triangle:after {
									@include transform(rotate(135deg) skewY(-45deg) scale(.707,1.414) translate(50%));
								}
							}
						}
						+ .msg-box {
							margin-top:5px;
							.arrow-triangle-wrap {
								display:none;
							}
						}
					}	
				}
				&.received {
					margin-bottom:20px;
					.media-body {
						.msg-box {
							>div {
								background:$white;
								.arrow-triangle-wrap {
									left:2px;
								}
								.chat-time {
									@include color-opacity($dark,.4);
								}
							}
						}
					}		
				}
				&.sent {
					margin-bottom:20px;
					.media-body {
						margin-left:0;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						justify-content: flex-end;
						-webkit-justify-content: flex-end;
						-ms-flex-pack: flex-end;
						-ms-flex-direction: column;
						flex-direction: column;
						-webkit-box-align: flex-end;
						-ms-flex-align: flex-end;
						align-items: flex-end;						
						.msg-box {
							>div {
								background:$yellow;
								.arrow-triangle-wrap {
									right:2px;
									.arrow-triangle {
										background-color: $yellow;
										&:before,&:after {
											background-color: $yellow;
										}
									}
								}
								p {
									color:$white;
								}
								.chat-time {
									text-align:right;
									@include color-opacity($white,.4);
								}
							}
						}
					}	
				}
			}
			.day-sep {
				position: relative;
				text-align: center;
				overflow:hidden;
				font-size:14px;
				margin:30px 0;
				text-transform: capitalize;
				&:before {
					background: $light_10;
					content: "";
					height: 1px;
					right: 50%;
					margin-right: 28px;
					position: absolute;
					top: 50%;
					width: 100%;
				}
				&:after {
					background: $light_10;
					content: "";
					height: 1px;
					left: 50%;
					margin-left: 28px;
					position: absolute;
					top: 50%;
					width: 100%;
				}
			}
		}
		footer {
			padding: 10px 15px;
			background:$white;
			border-top:1px solid $light_10;
			margin-top:-1px;
			position:relative;
			.feather-icon {
				display:block;
			}
			.input-group {
				width:100%;
				.form-control {
					border-radius:50px;
					border:none;
					background:$light;
					&:focus {
						border:none;
						box-shadow: none;
						background:$light;
					}
				}
				.input-group-prepend,
				.input-group-append {
					.btn {
						border:none;
						background:transparent;
						.feather-icon {
							display:block;
							color:$light_40;
							>svg {
								height:21px;
								width:21px;
							}
						}
					}
				}
			}
		}
	}
}
.hk-wrapper {
	&.navbar-search-toggle {
		.chatapp-wrap .chatapp-left .chatapp-users-list {
			height: calc(100vh - 257px);
		}
		.chatapp-wrap .chatapp-right .chat-body {
			height: calc(100vh - 257px);
		}
	}
}
@media (min-width: 1200px) {
	.hk-wrapper {
		&.hk-horizontal-nav {
			.chatapp-wrap .chatapp-left .chatapp-users-list {
				height: calc(100vh - 257px);
			}
			.chatapp-wrap .chatapp-right .chat-body {
				height: calc(100vh - 257px);
			}
			&.navbar-search-toggle {
				.chatapp-wrap .chatapp-left .chatapp-users-list {
					height: calc(100vh - 307px);
				}
				.chatapp-wrap .chatapp-right .chat-body {
					height: calc(100vh - 307px);
				}
			}
			&.hk-nav-toggle {
				.chatapp-wrap .chatapp-left .chatapp-users-list {
					height: calc(100vh - 207px);
				}
				.chatapp-wrap .chatapp-right .chat-body {
					height: calc(100vh - 207px);
				}
				&.navbar-search-toggle {
					.chatapp-wrap .chatapp-left .chatapp-users-list {
						height: calc(100vh - 257px);
					}
					.chatapp-wrap .chatapp-right .chat-body {
						height: calc(100vh - 257px);
					}
				}
			}
		}
	}
}
@media (max-width: 1400px) {
	.chatapp-wrap .chatapp-left .chatapp-users-list a.media .media-body > div:first-child .user-name, 
	.chatapp-wrap .chatapp-left .chatapp-users-list a.media .media-body > div:first-child .user-last-chat {
		max-width:190px;
	}
}
@media (max-width: 1024px) {
.chatapp-wrap {
	.chatapp-left,
	.chatapp-right {
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		max-width: 100%;
		@include transition(left .3s ease-in-out,right .3s ease-in-out);
	}
	.chatapp-right {
		right:-100%;
		position:absolute;
		top:0;
		header {
			justify-content: start;
			-webkit-justify-content: start;
			-ms-flex-pack: start;
			.back-user-list {
				display:block;
			}
			.chat-options-wrap {
				margin-left:auto;
			}
		}
		.chat-body ul.list-unstyled {
			width:100%;
		}
	}
	&.chatapp-slide {
		.chatapp-left {
			left:-100%;
		}
		.chatapp-right {
			right:0;
		}
	}
}
}

/*Email UI*/
.emailapp-wrap {
	position:relative;	
	.emailapp-sidebar {
		position: absolute;
		width: 200px;
		background: $smoke_light_5;
		left: 0;
		z-index: 10;
		border-right: 1px solid $light_10;
		@include transition(0.3s ease-in-out);
		.emailapp-nav-wrap {
			padding:15px;
			.close-emailapp-sidebar {
				display:none;
				padding: 0 .5rem;
				>.feather-icon {
					display:block;
				}
			}
			.nav {
				&.mail-category {
					.nav-item {
						.nav-link {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							text-transform:capitalize;
							color:$smoke;
							.badge {
								margin-left:10px;
							}
						}
						&.active {
							.nav-link {
								color:$dark;
							}
						}
					}
				}
				&.mail-labels {
					.nav-item {
						.nav-link {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							color:$dark;
							text-transform:capitalize;
							.badge-indicator {
								margin-right:15px;
							}
						}
					}
				}
				&.mail-settings {
					.nav-item {
						.nav-link {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							color:$smoke;
							text-transform:capitalize;
							>i {
								margin-right:15px;
							}
						}
					}
				}
			}
			hr {
				margin-left:-20px;
				margin-right:-20px;
			}
		}
	}
	&.emailapp-sidebar-toggle {
		.emailapp-sidebar {
			left: -200px;
		}	
		.email-box {
			margin-left:0;
		}
	}
	.email-box {
		margin-left:200px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		@include transition(0.3s ease-in-out);
		.email-star {
			color:$smoke;
			>.feather-icon {
				display:block;
				> svg {
					height:16px;
					width:16px;
				}
			}
			&:hover {
				color:$green;
				>.feather-icon {
					> svg {
						fill:$green!important;
						polygon {
							fill:$green!important;
						}
					}
				}
			}
			&.starred {
				color:$green;
				>.feather-icon {
					> svg {
						fill:$green!important;
						polygon {
							fill:$green!important;
						}
					}
				}
			}
		}
		.emailapp-left {
			-ms-flex:0 0 40%;
			flex:0 0 40%;
			max-width:40%;
			border-right: 1px solid $light_10;
			position:relative;
			z-index:4;
			left:0;
			header {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				justify-content: space-between;
				-webkit-justify-content: space-between;
				-ms-flex-pack: space-between;
				height:72px;
				padding: 0 15px;
				background:$white;
				border-bottom:1px solid $light_10;
				color:$dark;
				span {
					font-size:20px;
					font-weight: 600;
					text-transform:capitalize;
				}
				.emailapp-sidebar-move {
					color:$smoke;
				}
				.feather-icon {
					display:block;
				}
			}
			.email-search {
				width:100%;
				background:$light;
				padding:10px 15px;
				.input-group {
					width:100%;
					.form-control {
						padding-left: 36px;
						border-radius:50px;
						border:none;
						background:$white;
						&:focus {
							border:none;
							box-shadow: none;
							background:$white;
						}
					}
					.input-group-prepend {
						position: absolute;
						left: 10px;
						top: 0;
						z-index: 4;
						bottom: 0;
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						pointer-events:none;
						.feather-icon {
							display:block;
							color:$light_60;
							>svg {
								height:18px;
								width:18px;
							}
						}
					}
				}
			}
			.emailapp-emails-list {
				background:$white;
				a.media {
					padding: 12px 15px;
					@include transition(.2s ease);
					.media-img-wrap {
						position:relative;
						.avatar {
							height:45px;
							width:45px;
							margin-right:15px;
						}
					}
					.media-body {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						justify-content: space-between;
						-webkit-justify-content: space-between;
						-ms-flex-pack: space-between;
						>div:First-child {
							.email-head,
							.email-subject,
							.email-text > p	{
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: nowrap;
								max-width:300px;
							}
							.email-head {
								text-transform:capitalize;
								color:$dark;
							}
							.email-subject {
								color:$sky;
							}
							.email-text > p	{
								font-size: 14px;
								color:$smoke;
							}							
						}
						>div:last-child { 
							.last-email-details {
								font-size: 13px;
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								align-items: center;
								justify-content: center;
								-webkit-justify-content: center;
								-ms-flex-pack: center;
								.badge-indicator {
									margin-right: 5px;
								}
								.email-attachment-label {
									color:$smoke;
									margin-right: 5px;
									.feather-icon {
										display:block;
										>svg {
											height:13px;
											width:13px;
										}
									}
								}
							}
							.email-star {
								display: -webkit-box;
								display: -ms-flexbox;
								display: flex;
								justify-content: flex-end;
								-webkit-justify-content: flex-end;
								-ms-flex-pack: flex-end;
								margin-top:30px;
							}
						}
					}
					&:hover {
						background:$light;
					}
					&.read-email {
						.media-body {
							>div:first-child { 
								.email-subject {
									color:$dark;
								}
							}
							>div:last-child { 
								.last-email-details {
									color:$dark;
								}
							}
						}	
					}
					&.active-email {
						background:$light;
					}
				}
				.email-hr-wrap {
					>hr {
						margin:0;
					}
				}
			}
		}
		.emailapp-right {
			-ms-flex:0 0 60%;
			flex:0 0 60%;
			max-width:60%;
			header {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				justify-content: space-between;
				-webkit-justify-content: space-between;
				-ms-flex-pack: space-between;
				height:72px;
				padding: 0 15px;
				background:$white;
				border-bottom:1px solid $light_10;
				.back-email-list {
					display:none;
					margin-right:20px;
				}
				.email-options-wrap {
					margin-left:auto;
					display:flex;
					> a {
						margin-left: 25px;
						display: inline-block;
					}	
					.feather-icon {
						display:block;
					}
				}
			}
			.email-body {
				background:$white;
				.email-subject-head {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					justify-content: space-between;
					-webkit-justify-content: space-between;
					-ms-flex-pack: space-between;
					h4,>div {
						margin-bottom:10px;
					}
					.email-star {
						display:block;
						text-align:right;
						margin-left:10px;
					}
				}
				.email-head {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					justify-content: space-between;
					-webkit-justify-content: space-between;
					-ms-flex-pack: space-between;
					.sender-email {
						font-size:14px;
					}
					.media {
						.media-img-wrap {
							margin-right:15px;
							.avatar {
								height: 45px;
								width: 45px;
							}
						}
						.media-body {
							.sender-name {
								color:$dark;
								font-weight:500;
							}
						}
					}
					.head-other-wrap {
						span {
							font-size:13px;
						}
						a.dropdown-toggle {
							color:$smoke;
						}
					}
				}
				.email-text-wrap {
					.email-end-detail {
						> span:first-child {
							color:$dark;
						}
					}
				}
				.email-attachment-wrap {
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					.email-attachment-block {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-ms-flex-wrap: wrap;
						flex-wrap: wrap;
						margin-right:auto;
						.file-name {
							font-size:15px;
							font-weight:500;
							color:$dark;
						}
						.file-size {
							color:$smoke;
						}
						>a {
							margin-right:20px;
						}
					}	
					>div.d-flex {
						>a {
							font-size:20px;
							color:$smoke;
							&:first-child{
								margin-right:15px;
							}
						}
					}
				}
				.nicescroll-bar {
					>div {
						padding:15px;
					}
				}
			}
		}
	}
}
@media (max-width: 1600px) {
.emailapp-wrap {
	.email-box {
		.emailapp-left .emailapp-emails-list a.media .media-body > div:first-child {
				.email-head,.email-subject,.email-text > p {
					max-width:200px;
				}
			} 
		}
	}
}	
@media (max-width: 1400px) {
	.emailapp-wrap {
		.emailapp-sidebar {
			left:-200px;
			.emailapp-nav-wrap {
				.close-emailapp-sidebar {
					display:block;
				}
			}
		}
		&.emailapp-sidebar-toggle {
			.emailapp-sidebar {
				left: 0;
			}	
		}
		.email-box {
			margin-left:0;
			.emailapp-left .emailapp-emails-list a.media .media-body > div:first-child {
				.email-head,.email-subject,.email-text > p {
					max-width:200px;
				}
			}
		}
	}
}
@media (max-width: 1024px) {
.emailapp-wrap {
	.email-box {
		.emailapp-left .emailapp-emails-list a.media .media-body > div:first-child {
			.email-head,.email-subject,.email-text > p {
				max-width:450px;
			}
		} 
		.emailapp-left,
		.emailapp-right {
			-ms-flex: 0 0 100%;
			flex: 0 0 100%;
			max-width: 100%;
			@include transition(left .3s ease-in-out,right .3s ease-in-out);
		}
		.emailapp-right {
			right:-100%;
			position:absolute;
			top:0;
			header {
				justify-content: start;
				-webkit-justify-content: start;
				-ms-flex-pack: start;
				.back-email-list {
					display:block;
				}
			}
		}
	}
	&.emailapp-slide {
		.email-box {
			.emailapp-left {
				left:-100%;
			}
			.emailapp-right {
				right:0;
			}
		}
	}

}
}
@media (max-width: 768px) {
.emailapp-wrap .email-box .emailapp-right .email-body {
	.email-subject-head {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		h5 {
			margin-bottom:15px;
		}
	}
	.email-head {
		-ms-flex-direction: column;
		flex-direction: column;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		.head-other-wrap {
			margin-top:10px;
		}
	}
}
}
@media (max-width: 576px) {
.emailapp-wrap {
	.email-box {
		.emailapp-left .emailapp-emails-list a.media .media-body > div:first-child {
			.email-head,.email-subject,.email-text > p {
				max-width:160px;
			}
		} 
	}
}
}	

/*File Manager*/
.fmapp-wrap {
	position:relative;	
	.fmapp-sidebar {
		position: absolute;
		width: 200px;
		background: $smoke_light_5;
		left: 0;
		z-index: 10;
		border-right: 1px solid $light_10;
		@include transition(0.3s ease-in-out);
		.fmapp-nav-wrap {
			padding:15px;
			.close-fmapp-sidebar {
				display:none;
				padding: 0 .5rem;
				>.feather-icon {
					display:block;
				}
			}
			.nav {
				&.mail-category {
					.nav-item {
						.nav-link {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							text-transform:capitalize;
							color:$smoke;
							.badge {
								margin-left:10px;
							}
						}
						&.active {
							.nav-link {
								color:$dark;
							}
						}
					}
				}
				&.mail-labels {
					.nav-item {
						.nav-link {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							color:$dark;
							text-transform:capitalize;
							.badge-indicator {
								margin-right:15px;
							}
						}
					}
				}
				&.mail-settings {
					.nav-item {
						.nav-link {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							color:$smoke;
							text-transform:capitalize;
							>i {
								margin-right:15px;
							}
						}
					}
				}
			}
			hr {
				margin-left:-20px;
				margin-right:-20px;
			}
		}
	}
	&.fmapp-sidebar-toggle {
		.fmapp-sidebar {
			left: -200px;
		}	
		.fm-box {
			margin-left:0;
		}
	}
	.fm-box {
		margin-left:200px;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		@include transition(0.3s ease-in-out);
		.fmapp-main {
			-ms-flex:0 0 100%;
			flex:0 0 100%;
			max-width:100%;
			header {
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				justify-content: space-between;
				-webkit-justify-content: space-between;
				-ms-flex-pack: space-between;
				height:72px;
				padding: 0 15px;
				background:$white;
				border-bottom:1px solid $light_10;
				>span {
				    font-size: 20px;
					font-weight: 600;
					color:$dark;
					text-transform: capitalize;
				}
				.fmapp-sidebar-move {
					color:$smoke;
					.feather-icon {
						display:block;
					}
				}
				.fm-options-wrap {
					display:flex;
					> a,>span {
						margin-left: 25px;
						display: inline-block;
						color:$smoke;
						&:first-child {
							margin-left:0;
						}
					}	
					.feather-icon {
						display:block;
						&.grid-view {
							display:none;
						}
					}
				}
			}
			.fm-body {
				background:$white;
				.fmapp-view-wrap {
					.fmapp-grid-view {
						padding:20px 15px;
						.file-preview {
							display:inline-block;
							max-width:100%;
						}
						.file-name {
							display:block;
							font-size: 14px;
							color:$dark;
							word-break: break-all;
						}
					}
				}
				.fmapp-table-view {
					display:none;
					table {
						.file-name {
							color:$dark;
						}
						img.file-preview {
							max-width:100%;
							height:45px;
						}
						.files-more-link {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							justify-content: flex-end;
							-webkit-justify-content: flex-end;
							-ms-flex-pack: flex-end;
							>a {
								margin-right:24px;
								font-size:20px;
								&:first-child {
									color:$smoke;
								}
								&:last-child {
									color:$smoke;
									margin-right:0;
								}
							}
						}
						&.table-hover tbody tr:hover {
							background-color: $sky_light_5;
						}
					}
				}
			}
			&.fmapp-view-switch {
				header { 
					.fm-options-wrap {
						.feather-icon {
							&.grid-view {
								display:block;
							}
							&.list-view {
								display:none;
							}
						}
					}
				}
				.fm-body {
					.fmapp-grid-view {
						display:none;
					}
					.fmapp-table-view {
						display:block;
					}				
				}
			}
		}
	}
}
@media (max-width: 1400px) {
	.fmapp-wrap {
		.fmapp-sidebar {
			left:-200px;
			.fmapp-nav-wrap {
				.close-fmapp-sidebar {
					display:block;
				}
			}
		}
		&.fmapp-sidebar-toggle {
			.fmapp-sidebar {
				left: 0;
			}	
		}
		.fm-box {
			margin-left:0;
		}
	}
}

/*Caledar*/
.calendarapp-wrap {
	position:relative;
	.calendarapp-sidebar {
		position: absolute;
		width: 225px;
		background: $white;
		padding:15px;
		left: 0;
		z-index: 10;
		border-right: 1px solid $light_10;
		@include transition(0.3s ease-in-out);
		.slimScrollDiv,.nicescroll-bar {
			overflow:visible!important;
		}
		.close-calendarapp-sidebar {
			display:none;
			margin: 0 0 15px;
			.feather-icon {
				display:block;
			}
		}
		.add-event-wrap {
			.calendar-event {
				padding:0.5rem 0.75rem;
				cursor: grab;
				.close { 
					font-size: 1.25rem;
					padding-top: .5rem;
				}	
			}
		}	
	}
	&.calendarapp-sidebar-toggle {
		.calendarapp-sidebar {
			left: -225px;
		}	
		.calendar-wrap {
			margin-left:0;
		}
	}
	.calendar-wrap {
		margin-left:225px;
		position:relative;
		@include transition(0.3s ease-in-out);
		.fc-event {
			position: relative;
			display: block;
			font-size: 79%;
			border: 1px solid $pink_light_4;
			border-radius: 0;
			line-height:inherit;
		}
		.fc-event,
		.fc-event-dot {
			background:$pink_light_4;
		}
		.fc {
			button {
				height:auto;
				padding:.25rem .5rem;
				font-size: .875rem;
				&.fc-calendarSidebar-button {
					color:$light_50;
					padding:0;
					background:transparent;
					border:none;
				}
			}
			&.fc-bootstrap4 {
				.fc-header-toolbar {
					height: 80px;
					display: -webkit-box;
					display: -ms-flexbox;
					display: flex;
					position:relative;
					align-items: center;
					justify-content: center;
					-webkit-justify-content: center;
					-ms-flex-pack: center;
					justify-content: space-between;
					-webkit-justify-content: space-between;
					-ms-flex-pack: space-between;
					margin-bottom: 0;
					padding: 0 15px;
					background:$white;
					border-bottom: 1px solid $light_10;
					.fc-left {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						order: 1;
					}
					.fc-right {
						order: 3;
					}
					.fc-center {
						order: 2;
					}
					>.fc-clear {
						display: none;
					}
					.fc-center {
						h2 {
							font-size: 20px;
							font-weight: 600;
							text-transform: capitalize;
							padding: 0 25px;
						}
						>div {
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
						}
					}
				}
				.fc-view-container {
					background:$white;
					.table-bordered { 
						border:0;
						thead {
							td,th {
								border-bottom: 0;
								border-top: 0;
								border-color:$light_10;
							}
						}
						td,th {
							border-left:0;
							border-bottom:0;
							border-color:$light_10;
						}
					}
				}
			}
			.fc-list-heading td {
				color:$dark;
				font-weight: normal;
			}
		}
	}	
}
@media (max-width: 1024px) {
	.calendarapp-wrap {
		.calendarapp-sidebar {
			left:-225px;
			.close-calendarapp-sidebar {
				display:block;
			}
		}
		&.calendarapp-sidebar-toggle {
			.calendarapp-sidebar {
				left: 0;
			}	
		}
		.calendar-wrap { 
			margin-left:0;
		}
	}
}	
@media (max-width: 767px) {
	.calendarapp-wrap .calendar-wrap .fc.fc-bootstrap4 .fc-header-toolbar {
		.fc-today-button,.fc-right {
			display:none;
		}
		.fc-left {
			position:absolute;
			top:0;
			bottom:0;
		}
		.fc-center {
			margin-left:auto;
			margin-right:auto;
		}
	}
}
@media (max-width: 414px) {
	.calendarapp-wrap .calendar-wrap .fc.fc-bootstrap4 .fc-header-toolbar .fc-center h2 {
		padding: 0 10px;
		font-size:16px;
	}
}
/*Auth pages*/
.hk-pg-wrapper {
	&.hk-auth-wrapper {
		padding:0;
		background:$light;
		> header {
			position: absolute;
			top: 0;
			z-index: 10;
			width: 100%;
			padding: 20px;
		}
		.auth-cover-img {
			background-size:cover;
			background-repeat:no-repeat;
			background-position:center;
			height:100vh;
			width:100%;
			.auth-cover-info {
				position:relative;
				z-index:2;
				padding:15px;
				display: -webkit-box;
				display: -ms-flexbox;
				display: flex;
				-webkit-box-align: center;
				-ms-flex-align: center;
				align-items: center;
				height:100%;
				.auth-cover-content {
					margin:0 auto;
					.play-wrap {
						display: -webkit-box;
						display: -ms-flexbox;
						display: flex;
						-webkit-box-align: center;
						-ms-flex-align: center;
						align-items: center;
						color: $white;
						margin-top: 30px;
						.play-btn {
							@include background-opacity($white,.3);
							height: 45px;
							width: 45px;
							border-radius: 50%;
							top: auto;
							display: -webkit-box;
							display: -ms-flexbox;
							display: flex;
							-webkit-box-align: center;
							-ms-flex-align: center;
							align-items: center;
							justify-content: center;
							-webkit-justify-content: center;
							-ms-flex-pack: center;	
							color: $white;
							margin-right:10px;
							&:before {
								display: inline-block;
								font-family: "Ionicons";
								padding-left: 3px;
								speak: none;
								font-style: normal;
								font-weight: normal;
								font-variant: normal;
								text-transform: none;
								text-rendering: auto;
								line-height: 1;
								-webkit-font-smoothing: antialiased;
								-moz-osx-font-smoothing: grayscale;
								content: "\f488";
							}
						}
						>span {
							font-size:14px;
							color:$white;
						}
					}
				}
			}
		}
		.auth-form-wrap {
			padding:15px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			height:100vh;
			.auth-form {
				margin:0 auto;
				.option-sep {
					    position: relative;
						text-align: center;
						overflow: hidden;
						font-size: 14px;
						margin: 30px 0;
						text-transform: uppercase;
					&:before {
						background: $light_20;
						content: "";
						height: 1px;
						right: 50%;
						margin-right: 28px;
						position: absolute;
						top: 50%;
						width: 100%;
					}
					&:after {
						background: $light_20;
						content: "";
						height: 1px;
						left: 50%;
						margin-left: 28px;
						position: absolute;
						top: 50%;
						width: 100%;
					}
				}
				.form-group {
					.form-control {
						border-top-right-radius:.25rem;
						border-bottom-right-radius:.25rem;
						&.rounded-input {
							border-top-right-radius:50px;
							border-bottom-right-radius:50px;
						}
					}
					.input-group-append {
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						z-index: 10;
						pointer-events:none;
						.input-group-text {
							background:transparent;
							border:none;
							.feather-icon {
								>svg {
									height:18px;
									width:18px;
								}
							}
						}
					}
				}
			}		
		}	
	}	
}
@media (max-width: 1199px) {
	.hk-pg-wrapper.hk-auth-wrapper {
		.auth-cover-img {
			height:auto;
		}
		.auth-form-wrap {
			height:auto;
		}
	} 
}

/*Gallery*/
.hk-gallery-wrap {
	.tab-content {
		.tab-pane {
			.row {
				&.hk-gallery {
					margin-right: -5px;
					margin-left: -5px;
					> [class*="col-"] {
						padding-right: 5px;
						padding-left: 5px;
						>a {
							position:relative;
							display: block;
							.gallery-img {
								min-height:160px;
								max-width:100%;
								background-size:cover;
								background-position:center;
								background-repeat:no-repeat;
							}
							>i {
								color:$white;
								position:absolute;
								right:10px;
								bottom:10px;
								font-size:18px;
							}
						}	
					}
				}
			}
		}
	}
}
.lg-outer .lg-video .lg-object {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
	right:0;
	margin:auto;
	width: auto !important;
    height: 100% !important;
}
/*Cropperjs*/
.img-container, .img-preview {
  background-color: $light;
  width: 100%;
  text-align: center;
}

.img-container {
  min-height: 200px;
  max-height: 469px;
  margin-bottom: 16px;
  > img {
    max-width: 100%;
  }
}

.docs-preview {
  margin-right: -16px;
}

.img-preview {
  float: left;
  margin-right: 8px;
  margin-bottom: 8px;
  overflow: hidden;
  > img {
    max-width: 100%;
  }
}

.preview-lg {
  width: 255px;
  height: 143px;
}

.preview-md {
  width: 128px;
  height: 72px;
}

.preview-sm {
  width: 72px;
  height: 41px;
}

.preview-xs {
  width: 40px;
  height: 24px;
  margin-right: 0;
}

.docs-data > .input-group {
  margin-bottom: 8px;
  > {
    label {
      min-width: 80px;
    }
  }
}

.docs-buttons > {
  .btn, .btn-group, .form-control {
    margin-right: 4px;
    margin-bottom: 8px;
  }
}

.docs-toggles > {
  .btn, .btn-group, .dropdown {
    margin-bottom: 8px;
  }
}

.docs-tooltip {
  display: block;
  margin: -8px -12px;
  padding: 8px 12px;
}

.docs-aspect-ratios, .docs-view-modes {
  width: 100%;
}

.docs-aspect-ratios > .btn {
  width: 20%;
}

.docs-view-modes > .btn {
  width: 25%;
}

.docs-options .dropdown-menu {
  width: 100%;
  > li {
    padding: 4px 16px;
    &:hover {
      background-color: #f7f7f7;
    }
    > label {
      margin-bottom: 0;
    }
  }
}

.docs-cropped .modal-body {
  text-align: center;
  > {
    img, canvas {
      max-width: 100%;
    }
  }
}

.common-credits > li {
    display: block;
    margin-bottom: 15px;
	&:last-child {
		margin-bottom:0;
	}
}
/*Landing Page*/
.hk-landing {
	.play-wrap {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-top: 30px;
		justify-content: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		> span {
			font-size: 14px;
			color:$white;
		}
		.play-btn {
			height: 60px;
			width: 60px;
			border-radius: 50%;
			@include background-opacity($white,.3);
			color:$white;
			top: auto;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			margin-right: 10px;
			&::before {
				display: inline-block;
				font-family: "Ionicons";
				padding-left: 3px;
				font-size:28px;
				speak: none;
				font-style: normal;
				font-weight: normal;
				font-variant: normal;
				text-transform: none;
				text-rendering: auto;
				line-height: 1;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				content: "\f488";
			}
		}

	}
	.owl-carousel.dots-on-item .owl-dots {
	   bottom: auto;
	}
	.hk-landing-sec {
		padding:80px 0;
	}
	.hk-preview-sec {
		padding-top:57px;
	}
	.feature-block {
		display:inline-block;
		margin:0 15px 15px 15px;
		width:100px;
		.card {
			height:100px;
			width:100px;
			margin-bottom:10px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			justify-content: center;
			-webkit-justify-content: center;
			-ms-flex-pack: center;
			img {
				width:44px;
			}
		}	
		.feature-cap {
			font-size:14px;
			color:$dark;
			font-weight:500;
		}
	}
}
@media (max-width: 1199px) {
	.hk-landing {
		.container.px-0 {
			max-width:100%;
		}
	}	
}	
@media all and (-ms-high-contrast:none)
{
*::-ms-backdrop, .media-body { flex-basis: auto !important; } /* IE11 */
}