/*__________________________________________________________________;
[Mixin]
Project:	protype
___________________________________________________________________*/;

@mixin background-opacity($color, $opacity: 0.6) {
    background: rgba($color, $opacity);
}

@mixin color-opacity($color, $opacity: 0.8) {
    color: rgba($color, $opacity);
}

@mixin border-opacity($color, $opacity: 0.8) {
    border-color: rgba($color, $opacity);
}

@mixin clear() {
	overflow:hidden;
	clear:both;
	float:none;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin bg-linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: linear-gradient($direction, $color-stops)!important;
  background: -webkit-bg-linear-gradient($direction, $color-stops)!important;
}
@mixin bdr-linear-gradient($direction, $color-stops...) {
  border-color: nth(nth($color-stops, 1), 1);
  border-color: linear-gradient($direction, $color-stops)!important;
  border-color: -webkit-linear-gradient($direction, $color-stops)!important;
}